<template>
  <div class="grid grid-cols-12 font-normal items-end text-sm gap-x-6 text-gray-700 pb-2">
    <div
      class="col-span-8 ml-2 flex items-center"
      :class="{'ml-6': isEditable, 'cursor-pointer': isEditable}"
      @click="sortByName"
    >
      <template v-if="isEditable">
        <template v-if="sortDirection === 'asc'">
          <component-icon>expand_more</component-icon>
        </template>

        <template v-else-if="sortDirection === 'desc'">
          <component-icon>expand_less</component-icon>
        </template>

        <template v-else>
          <component-icon>unfold_more</component-icon>
        </template>
      </template>

      Erkrankung / ICD-10 / Allergie / Lebensumstand
    </div>
    <div class="col-span-3">Seit (Datum; opt.)</div>
    <div class="col-span-1" />
  </div>

  <ul ref="refDraggable">
    <template v-for="disease in diseases" :key="disease.id">
      <template v-if="isLoading">
        <disease-skeleton-row />
      </template>

      <template v-else>
        <diseases-table-row
          :drag-item="disease.id"
          :is-editable="isEditable"
          :disease="disease"
          :is-dragging="isDragging"
          @update-diseases="updateDiseases"
        />
      </template>
    </template>
  </ul>
</template>

<script>
  import {computed, inject, onMounted, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce, isEqual} from "lodash";
  import Sortable from "sortablejs";

  import {sortList} from "@utils/Helpers/ListSorter.js";

  import ComponentIcon from "@components/Icons/Icon.vue";

  import DiseasesTableRow from "@pages/Records/Components/Sections/DiseaseBar/Components/Row.vue";
  import DiseaseSkeletonRow from "@pages/Records/Components/Sections/DiseaseBar/Components/SkeletonRow.vue";
  import {delayWhileEditing} from "@utils/Helpers/DelayWhileEditing.js";

  export default {
    name: "DiseasesTable",

    components: {ComponentIcon, DiseaseSkeletonRow, DiseasesTableRow},

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const setIsMassUpdateProcessing = inject("setIsMassUpdateProcessing");

      const refDraggable = ref(null);

      const isDragging = ref(false);
      const diseases = ref(cloneDeep(props.recordData.diseases));
      const sortable = ref(null);
      const sortCancelToken = ref(null);

      onMounted(() => {
        initSortable();
      });

      const initSortable = () => {
        if (sortable.value !== null) {
          return; // already initialized
        }
        if (refDraggable.value === null) {
          return; // refDraggable is not available yet
        }
        sortable.value = Sortable.create(refDraggable.value, {
          handle: "[drag-handle]",
          draggable: "[drag-item]",
          direction: "vertical",
          ghostClass: "sortable-ghost",
          dataIdAttr: "drag-item", // requires `drag-item="{{item.id}}"`
          store: {
            /**
             * Save the order of elements. Called onEnd (when the item is dropped).
             * @param {Sortable} sortable
             */
            set: (sortable) => {
              // sort medications according to new sort order
              updateOrder(sortable.toArray());
            },
          },
          onStart: (evt) => {
            isDragging.value = true;
          },
          onEnd: (evt) => {
            isDragging.value = false;
          },
        });
      };

      const sortDirection = computed(() => {
        if (diseases.value.length > 0) {
          const copyOfDiseases = cloneDeep(diseases.value);

          const diseaseIds = copyOfDiseases.map((disease) => disease.id);
          const ascSortedObject = sortList(copyOfDiseases, "disease");

          if (isEqual(diseaseIds, ascSortedObject.sortedIds)) {
            return "asc";
          }

          if (isEqual(diseaseIds, ascSortedObject.sortedIds.reverse())) {
            return "desc";
          }
        }

        return "";
      });

      const newSortDirection = computed(() => {
        if (sortDirection.value === "" || sortDirection.value === "desc") {
          return "asc";
        }

        if (sortDirection.value === "asc") {
          return "desc";
        }

        return "";
      });

      watch(
        () => props.recordData.diseases,
        () => {
          diseases.value = cloneDeep(props.recordData.diseases);
        },
        {deep: true},
      );

      watch(
        () => diseases.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = oldValue.map((medication) => medication.id);
            const newSortOrder = value.map((medication) => medication.id);

            if (!isEqual(oldSortOrder, newSortOrder)) {
              updateOrder(newSortOrder);
            }
          } else {
            initSortable();
          }
        },
      );

      const updateDiseases = () => {
        setIsMassUpdateProcessing("disease", true);
        putDiseases();
      };

      const putDiseases = debounce(
        () => {
          delayWhileEditing(refDraggable, () => {
            router.patch(
              route("diseases.mass-update", {
                patient: page.props.patient.id,
                record: page.props.record.id,
              }),
              {
                ...diseases.value,
              },
              {
                preserveScroll: true,
                only: ["record", "flash", "errors"],
                onFinish: () => {
                  setIsMassUpdateProcessing("disease", false);
                },
              },
            );
          });
        },
        500,
        {trailing: true},
      );

      const updateOrder = (newSortOrder) => {
        if (sortCancelToken.value) {
          sortCancelToken.value.cancel();
        }

        setIsMassUpdateProcessing("disease", true);

        router.post(
          route("sort.store", {
            patient: page.props.patient.id,
            record: page.props.record.id,
            relation: "diseases",
          }),
          {
            ids: newSortOrder,
          },
          {
            preserveScroll: true,
            only: ["record", "flash"],
            onFinish: () => {
              isDragging.value = false;
              setIsMassUpdateProcessing("disease", false);
            },
            onCancelToken: (cancelToken) => (sortCancelToken.value = cancelToken),
          },
        );
      };

      const sortByName = () => {
        if (!props.isEditable) return;

        const sortedObject = sortList(diseases.value, "disease", newSortDirection.value);
        diseases.value = sortedObject.sortedArray;

        updateOrder(sortedObject.sortedIds);
      };

      return {
        /** ref */
        refDraggable,

        /** const */
        diseases,
        isDragging,

        /** computed */
        sortDirection,

        /** function */
        updateDiseases,
        sortByName,
      };
    },
  };
</script>
