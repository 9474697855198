<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      Patientenanschrift{{ inline ? ":" : "" }}
    </h6>
    <div :class="{'mt-0.5 text-sm': !inline, 'flex flex-wrap space-x-1.5': inline}">
      <template v-if="patient.is_fake">
        <div class="text-xs text-gray-600 mb-2">Die Anschrift wird bei geteilten Patienten nicht übermittelt.</div>
      </template>

      <div :data-encrypted="attribute.payload?.supplement" />
      <div :data-encrypted="attribute.payload?.street" />
      <div>
        <span :data-encrypted="attribute.payload?.zip" />
        <span :data-encrypted="attribute.payload?.city" />
      </div>
      <div>
        <span v-if="attribute.payload?.email">
          <span class="text-gray-500">E-Mail:</span>
          <span :data-encrypted="attribute.payload?.email" />
        </span>
        <span v-if="attribute.payload?.telephone">
          <span class="text-gray-500">Tel:</span>
          <span :data-encrypted="attribute.payload?.telephone" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";

  export default {
    name: "ComponentPatientSidebarNursingHomeAttribute",
    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {},
  };
</script>
