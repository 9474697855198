<template>
  <div class="grid grid-cols-[repeat(13,minmax(0,1fr))] font-normal items-end text-sm gap-x-6 text-gray-700 pb-2">
    <div
      class="col-span-3 ml-2 flex items-center"
      :class="{'ml-6': isEditable, 'cursor-pointer': isEditable}"
      @click="sortByName"
    >
      <template v-if="isEditable">
        <template v-if="sortDirection === 'asc'">
          <component-icon>expand_more</component-icon>
        </template>

        <template v-else-if="sortDirection === 'desc'">
          <component-icon>expand_less</component-icon>
        </template>

        <template v-else>
          <component-icon>unfold_more</component-icon>
        </template>
      </template>

      Was
    </div>
    <div class="col-span-3">Wert (opt.)</div>
    <div class="col-span-2">Einheit (opt.)</div>
    <div class="col-span-2" :class="{'col-span-3': !isEditable}">Abweichung</div>
    <div class="col-span-2" :class="{'col-span-1': isEditable}">Von (Datum)</div>
    <div class="col-span-1" />
  </div>

  <ul ref="refDraggable">
    <template v-for="laborValue in laborValues" :key="laborValue.id">
      <template v-if="checkIsNotMain(laborValue.mc_laborvalue_key)">
        <template v-if="isLoading">
          <labor-value-skeleton-row />
        </template>

        <template v-else>
          <labor-table-row
            :drag-item="laborValue.id"
            :is-editable="isEditable"
            :laborvalue="laborValue"
            :is-dragging="isDragging"
            @update="$emit('update')"
          />
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
  import {computed, inject, onMounted, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {cloneDeep, filter, isEqual} from "lodash";
  import Sortable from "sortablejs";

  import {sortList} from "@utils/Helpers/ListSorter.js";

  import ComponentIcon from "@components/Icons/Icon.vue";

  import {mainLaborvalueList} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import LaborTableRow from "@pages/Records/Components/Sections/LaborBar/Components/Row.vue";
  import LaborValueSkeletonRow from "@pages/Records/Components/Sections/LaborBar/Components/SkeletonRow.vue";

  export default {
    name: "LaborTable",

    components: {
      ComponentIcon,
      LaborValueSkeletonRow,
      LaborTableRow,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      recordLaborValues: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    emits: ["update"],

    setup(props) {
      const page = usePage();

      const setIsMassUpdateProcessing = inject("setIsMassUpdateProcessing");

      const refDraggable = ref(null);

      const isDragging = ref(false);
      const laborValues = ref(props.recordLaborValues);
      const sortable = ref(null);
      const sortCancelToken = ref(null);

      const sortDirection = computed(() => {
        if (laborValues.value.length > 0) {
          const copyOfLaborValues = cloneDeep(laborValues.value).filter((laborValue) => laborValue.id !== null);

          const laborValueIds = copyOfLaborValues.map((laborValue) => laborValue.id);
          const ascSortedObject = sortList(copyOfLaborValues, "type");

          if (isEqual(laborValueIds, ascSortedObject.sortedIds)) {
            return "asc";
          }

          if (isEqual(laborValueIds, ascSortedObject.sortedIds.reverse())) {
            return "desc";
          }
        }

        return "";
      });

      const newSortDirection = computed(() => {
        if (sortDirection.value === "" || sortDirection.value === "desc") {
          return "asc";
        }

        if (sortDirection.value === "asc") {
          return "desc";
        }

        return "";
      });

      watch(
        () => props.recordLaborValues,
        () => {
          laborValues.value = props.recordLaborValues;
        },
        {deep: true},
      );

      watch(
        () => laborValues.value,
        (value, oldValue) => {
          if (value.length === oldValue.length) {
            const oldSortOrder = [...oldValue.map((laborValue) => laborValue.id)];

            const newSortOrder = [...value.map((laborValue) => laborValue.id)];

            if (!isEqual(oldSortOrder, newSortOrder)) {
              updateOrder(newSortOrder);
            }
          } else {
            initSortable();
          }
        },
      );

      onMounted(() => {
        initSortable();
      });

      const updateOrder = (newSortOrder) => {
        if (sortCancelToken.value) {
          sortCancelToken.value.cancel();
        }

        setIsMassUpdateProcessing("laborValue", true);

        router.post(
          route("sort.store", {
            patient: page.props.patient.id,
            record: page.props.record.id,
            relation: "laborvalues",
          }),
          {
            ids: newSortOrder,
          },
          {
            preserveScroll: true,
            only: ["record", "flash"],
            onFinish: () => {
              isDragging.value = false;
              setIsMassUpdateProcessing("laborValue", false);
            },
            onCancelToken: (cancelToken) => (sortCancelToken.value = cancelToken),
          },
        );
      };

      const sortByName = () => {
        if (!props.isEditable) return;

        const sortedObject = sortList(laborValues.value, "type", newSortDirection.value);
        const sortedIds = sortedObject.sortedIds.filter((laborValueId) => laborValueId !== null);

        laborValues.value = sortedObject.sortedArray;

        updateOrder(sortedIds);
      };

      const initSortable = () => {
        if (sortable.value !== null) {
          return; // already initialized
        }

        if (refDraggable.value === null) {
          return; // refDraggable is not available yet
        }

        sortable.value = Sortable.create(refDraggable.value, {
          handle: "[drag-handle]",
          draggable: "[drag-item]",
          direction: "vertical",
          ghostClass: "sortable-ghost",
          dataIdAttr: "drag-item", // requires `drag-item="{{item.id}}"`
          store: {
            /**
             * Save the order of elements. Called onEnd (when the item is dropped).
             * @param {Sortable} sortable
             */
            set: (sortable) => {
              // sort medications according to new sort order
              const mainLaborValues = [
                ...filter(props.recordLaborValues, (laborvalue) => {
                  return mainLaborvalueList.includes(laborvalue.mc_laborvalue_key) && laborvalue.id !== null;
                }).map((laborValue) => laborValue.id),
              ];

              updateOrder(mainLaborValues.concat(sortable.toArray()));
            },
          },
          onStart: (evt) => {
            isDragging.value = true;
          },
          onEnd: (evt) => {
            isDragging.value = false;
          },
        });
      };

      const checkIsNotMain = (laborValueKey) => {
        return mainLaborvalueList.indexOf(laborValueKey) === -1;
      };

      return {
        /** ref */
        refDraggable,

        /** const */
        laborValues,
        isDragging,

        /** computed */
        sortDirection,

        /** function */
        sortByName,
        checkIsNotMain,
      };
    },
  };
</script>
