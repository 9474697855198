<template>
  <section
    :class="{
      '[.print-doc_&]:print:hidden': !printDoc,
      '[.print-patient_&]:print:hidden': !printPatient,
      'bg-yellow-50': highlightSection,
    }"
    class="print:border-2 print:border-t-0 print:p-2 print:!-mt-1"
  >
    <h2 class="sr-only">Arzneimitteltabelle</h2>
    <div class="grid justify-end print:hidden">
      <div class="text-sm text-right" @mouseover="highlightSection = true" @mouseout="highlightSection = false">
        <div>Arzneimitteltabelle übernehmen in PDF</div>

        <div class="flex space-x-4 justify-end">
          <component-checkbox v-model="printDoc">an Arzt</component-checkbox>
          <component-checkbox v-model="printPatient">an Patient</component-checkbox>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-1 font-bold py-2 border-b border-gray-300 print:break-after-avoid-page">
      <div class="col-span-3 print:col-span-4">Arzneimittel</div>

      <div class="print:hidden">Risiko</div>

      <div>Form</div>

      <div>Menge</div>

      <div class="col-span-2">Dosierung</div>

      <div>Einheit</div>

      <div class="col-span-3">Grund</div>
    </div>

    <div
      v-for="(medication, index) in filteredMedications"
      :key="index"
      class="grid grid-cols-12 gap-1 py-2 border-b border-gray-300"
    >
      <div class="col-span-3 print:col-span-4">
        <div :class="{'line-through': medication.additionalinformation?.discontinued}">
          <div>
            <span
              v-if="medication.abdata_key_fam === null || medication.abdata_key_fam === 0"
              class="inline-block pr-1"
            >
              **
            </span>

            {{ medication.fam }}

            <span v-if="medication.tradingstatus === 'außer Vertrieb'">a.H.</span>
          </div>

          <div class="text-gray-500 text-xs print:hidden">
            {{ medication.substances.map((substance) => substance.name + " " + substance.strength).join(", ") }}
          </div>
        </div>

        <div v-if="medication.additionalinformation?.discontinued" class="flex flex-wrap">
          <template v-if="medication.additionalinformation.discontinued_by === 'doctor-patient-deviates'">
            <span class="text-gray-500 text-xs flex mr-2">
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>
              <span>abgesetzt</span>
            </span>

            <span class="text-red-500 text-xs flex">
              <component-tooltip tooltip="Patientenangabe">
                <component-icon custom="patient" />
              </component-tooltip>
              <span>weicht ab</span>
            </span>
          </template>

          <template v-else-if="medication.additionalinformation?.discontinued_by !== 'doctor'">
            <span class="text-red-500 text-xs flex">
              <!-- no icon when discontinued by "unknown" (empty selection) -->
              <component-tooltip
                v-if="medication.additionalinformation.discontinued_by === 'patient'"
                tooltip="Patientenangabe"
              >
                <component-icon custom="patient" />
              </component-tooltip>
              <span>abgesetzt</span>
            </span>
          </template>
        </div>

        <div class="text-gray-500 text-xs">
          <div v-if="medication.additionalinformation?.decreed">
            {{ additionalinformationDecreed[medication.additionalinformation.decreed] }}
          </div>

          <span v-if="medication.additionalinformation?.since">
            Anw. seit {{ formatDate(medication.additionalinformation.since) }}
          </span>

          <span v-if="medication.additionalinformation?.to">
            Anw. bis {{ formatDate(medication.additionalinformation.to) }}
          </span>

          <div v-if="medication.additionalinformation?.source && medication.additionalinformation?.source.length > 0">
            {{ medication.additionalinformation.source.map((source) => currentSource[source]).join(", ") }}
          </div>
        </div>
      </div>
      <div class="print:hidden">
        <div class="flex space-x-1 -mt-0.5">
          <component-risk-circle :medication="medication" @open:risk-score="openRiskScoreDialog(medication)" />

          <!-- fi button -->
          <div v-if="medication.redhand" class="relative">
            <component-tooltip tooltip="Rote-Hand-Brief (ggf. weitere Dokumente)">
              <button
                class="rounded-full bg-gray-200 hover:bg-gray-300 w-7 h-7 text-sm font-semibold"
                @click="openCoreInfoDialog(medication)"
              >
                FI
              </button>

              <component-icon class="absolute -top-1 -right-1 select-none text-mcred text-sm">pan_tool</component-icon>
            </component-tooltip>
          </div>

          <div v-else>
            <component-tooltip tooltip="Fachinformation (ggf. weitere Dokumente)">
              <button
                class="rounded-full bg-gray-200 hover:bg-gray-300 w-7 h-7 text-sm font-semibold"
                @click="openCoreInfoDialog(medication)"
              >
                FI
              </button>
            </component-tooltip>
          </div>
        </div>
      </div>

      <div>{{ medication.form }}</div>

      <div>{{ formatSelectedPackage(medication.selected_package) }}</div>

      <div class="col-span-2 flex">
        <span v-if="hasDosageCheckSkipped(medication)" class="pr-1">*</span>

        <div>
          <!-- Dosage first row -->
          <div class="w-full">
            <!-- Normal dosage -->
            <div
              v-if="
                hasDosage(medication) &&
                !hasDosage(medication.deviantmedication) &&
                !hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication)
              "
              class="whitespace-nowrap"
            >
              {{ medication.dosage.dosage_string_formatted }}
            </div>

            <!-- Alternative dosage from doctor -->
            <div
              v-else-if="
                hasDosage(medication) &&
                !hasDosage(medication.deviantmedication) &&
                hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication)
              "
            >
              wie folgt
            </div>

            <!-- Doctor dosage overridden by deviant alternative dosage -->
            <div
              v-else-if="!hasDosageAlternative(medication) && hasDosageAlternative(medication.deviantmedication)"
              class="text-gray-500 text-xs flex line-through"
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span class="whitespace-nowrap">{{ medication.dosage.dosage_string_formatted }}</span>
            </div>

            <!-- Doctor dosage overriden by deviant dosage -->
            <div
              v-else-if="
                hasDosage(medication) &&
                hasDosage(medication.deviantmedication) &&
                !hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication) &&
                !hasDosageInfo(medication)
              "
            >
              <span class="text-gray-500 text-xs flex line-through">
                <component-tooltip tooltip="Arztangabe">
                  <component-icon custom="doctor" />
                </component-tooltip>

                <span class="whitespace-nowrap">{{ medication.dosage.dosage_string_formatted }}</span>
              </span>

              <span class="text-red-500 text-xs flex">
                <component-tooltip tooltip="Patientenangabe">
                  <component-icon custom="patient" />
                </component-tooltip>

                <span class="whitespace-nowrap">
                  {{ medication.deviantmedication.dosage?.dosage_string_formatted }}
                </span>
              </span>
            </div>

            <!-- no doctor dosage overridden by deviant dosage -->
            <div
              v-else-if="
                !hasDosage(medication) &&
                hasDosage(medication.deviantmedication) &&
                !hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication) &&
                !hasDosageInfo(medication)
              "
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span>k. A.</span>

              <br />

              <span class="text-gray-500 text-xs flex">
                <component-tooltip tooltip="Patientenangabe">
                  <component-icon custom="patient" />
                </component-tooltip>

                <span class="whitespace-nowrap">
                  {{ medication.deviantmedication?.dosage?.dosage_string_formatted }}
                </span>
              </span>
            </div>

            <!-- doctor dosage overridden by deviant dosage -->
            <div
              v-else-if="
                hasDosage(medication) &&
                hasDosage(medication.deviantmedication) &&
                !hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication) &&
                hasDosageInfo(medication)
              "
            >
              <span class="text-gray-500 text-xs flex line-through">
                <component-tooltip tooltip="Arztangabe">
                  <component-icon custom="doctor" />
                </component-tooltip>

                <span class="whitespace-nowrap">{{ medication.dosage.dosage_string_formatted }}</span>
              </span>

              <span class="text-red-500 text-xs flex">
                <component-tooltip tooltip="Patientenangabe">
                  <component-icon custom="patient" />
                </component-tooltip>

                <span class="whitespace-nowrap">
                  {{ medication.deviantmedication?.dosage?.dosage_string_formatted }}
                </span>
              </span>
            </div>

            <!-- no doctor dosage -->
            <div v-else-if="!hasDosage(medication)">
              <span>k. A.</span>
            </div>
          </div>

          <!-- Dosage second row -->
          <div class="w-full">
            <!-- doctor alternative dosage overridden by deviant dosage -->
            <div
              v-if="
                hasDosage(medication) && hasDosage(medication.deviantmedication) && hasDosageAlternative(medication)
              "
              class="text-gray-500 text-xs flex line-through"
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span>
                {{ medication.dosage.dosagealternative.text }}
              </span>
            </div>

            <!-- doctor alternative dosage -->
            <div
              v-else-if="
                hasDosage(medication) && !hasDosage(medication.deviantmedication) && hasDosageAlternative(medication)
              "
              class="text-gray-500 text-xs flex"
            >
              {{ medication.dosage.dosagealternative.text }}
            </div>

            <!-- -- -- -- -- -- -- -->
            <!--DosageMessageString-->
            <!-- dosageInfo overridden by deviant dosageInfo -->
            <div
              v-if="hasDosageInfo(medication) && hasDosageInfo(medication.deviantmedication)"
              class="text-gray-500 text-xs flex line-through"
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span>{{ medication.dosage.info }}</span>
            </div>

            <!-- dosage info -->
            <span v-else-if="hasDosageInfo(medication)" class="text-gray-500 text-xs flex">
              <span>{{ medication.dosage.info }}</span>
            </span>

            <!-- -- -- -- -- -- -- -->
            <!--PatientDosageAlternativeString-->
            <!-- alternative doctor dosage overridden by alternative deviant dosage -->
            <!-- OR doctor dosage and doctor alternative overridden by deviant dosage and deviant alternative -->
            <div
              v-if="
                (!hasDosageAlternative(medication) && hasDosageAlternative(medication.deviantmedication)) ||
                (hasDosage(medication) &&
                  hasDosage(medication.deviantmedication) &&
                  hasDosageAlternative(medication) &&
                  hasDosageAlternative(medication.deviantmedication))
              "
              class="text-red-500 text-xs flex"
            >
              <component-tooltip tooltip="Patientenangabe">
                <component-icon custom="patient" />
              </component-tooltip>

              <span>
                {{ medication.deviantmedication?.dosage?.dosagealternative.text }}
              </span>
            </div>

            <!-- doctor dosage and doctor alternative overridden by deviant dosage -->
            <div
              v-else-if="
                hasDosage(medication) &&
                hasDosage(medication.deviantmedication) &&
                hasDosageAlternative(medication) &&
                !hasDosageAlternative(medication.deviantmedication)
              "
              class="text-red-500 text-xs flex"
            >
              <component-tooltip tooltip="Patientenangabe">
                <component-icon custom="patient" />
              </component-tooltip>

              <span class="whitespace-nowrap">{{ medication.deviantmedication.dosage?.dosage_string_formatted }}</span>
            </div>

            <!-- -- -- -- -- -- -- -->
            <!--PatientDosageMessageString-->
            <span v-if="hasDeviationDosageInfo(medication.deviantmedication)" class="text-gray-500 text-xs flex">
              <component-tooltip tooltip="Patientenangabe">
                <component-icon custom="patient" />
              </component-tooltip>

              <span>{{ medication.deviantmedication?.dosage?.info }}</span>
            </span>

            <!-- -- -- -- -- -- -- -->
            <!-- meal context -->
            <div v-if="hasDosageMeal(medication) || hasDosageMeal(medication.deviantmedication)">
              <span
                v-if="hasDosageMeal(medication)"
                class="text-gray-500 text-xs flex"
                :class="{
                  'line-through':
                    hasDosageMeal(medication.deviantmedication) &&
                    medication?.dosage?.meal !== medication?.deviantmedication?.dosage?.meal,
                }"
              >
                <component-tooltip
                  v-if="
                    hasDosageMeal(medication) &&
                    hasDosageMeal(medication.deviantmedication) &&
                    medication?.dosage?.meal !== medication?.deviantmedication?.dosage?.meal
                  "
                  tooltip="Arztangabe"
                >
                  <component-icon custom="doctor" />
                </component-tooltip>
                <span>{{ medicationMeal[medication?.dosage?.meal] }}</span>
              </span>

              <span
                v-if="
                  hasDosageMeal(medication.deviantmedication) &&
                  medication?.dosage?.meal !== medication?.deviantmedication?.dosage?.meal
                "
                class="text-xs flex"
                :class="{
                  'text-red-500':
                    hasDosageMeal(medication) &&
                    medication?.dosage?.meal !== medication?.deviantmedication?.dosage?.meal,
                  'text-gray-500': !hasDosageMeal(medication),
                }"
              >
                <component-tooltip
                  v-if="
                    hasDosageMeal(medication) &&
                    hasDosageMeal(medication.deviantmedication) &&
                    medication?.dosage?.meal !== medication?.deviantmedication?.dosage?.meal
                  "
                  tooltip="Patientenangabe"
                >
                  <component-icon custom="patient" />
                </component-tooltip>
                <span>{{ medicationMeal[medication?.deviantmedication?.dosage?.meal] }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="break-all">
        {{ medication.deviantmedication?.dosage?.selected_unit || medication.dosage.selected_unit }}
      </div>

      <div class="col-span-3">
        <div>
          <!--IndicationString-->
          <span>
            <span
              v-if="medication.indications.length > 0 && medication.deviantmedication?.indications.length > 0"
              class="text-gray-500 text-xs flex line-through"
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span>
                {{ medication.indications.map((indication) => indication.name || indication.name_icdc).join(", ") }}
              </span>
            </span>

            <span
              v-else-if="medication.deviantmedication?.indications.length > 0 && medication.indications.length === 0"
              class="flex"
            >
              <component-tooltip tooltip="Arztangabe">
                <component-icon custom="doctor" />
              </component-tooltip>

              <span>k. A.</span>
            </span>

            <span v-else>
              {{ medication.indications.map((indication) => indication.name || indication.name_icdc).join(", ") }}
            </span>
          </span>
        </div>
        <div>
          <!--PatientIndicationString-->
          <span v-if="medication.deviantmedication?.indications.length > 0" class="text-red-500 text-xs flex">
            <component-tooltip tooltip="Patientenangabe">
              <component-icon custom="patient" />
            </component-tooltip>

            <span>
              {{
                medication.deviantmedication?.indications
                  .map((indication) => indication.name || indication.name_icdc)
                  .join(", ")
              }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-1 py-2 print:break-before-avoid-page">
      <div class="col-span-3 self-center">
        <div class="print:hidden">Arzneimittelrisiken (gesamt):</div>
      </div>

      <div class="col-span-5 self-center">
        <div class="-ml-2 print:hidden">
          <component-risk-donut-chart :medications="filteredMedications" />
        </div>
      </div>

      <dl class="col-span-4 self-center">
        <div v-if="showAsteriskForSkippedDosageCheck" class="flex">
          <dt class="w-5">*</dt>
          <dd>keine Dosisprüfung möglich</dd>
        </div>

        <div v-if="showAsteriskForNonAnalysable" class="flex">
          <dt class="w-5">**</dt>
          <dd>nicht analysierbar</dd>
        </div>
      </dl>
    </div>

    <div v-if="hasThirdParty('p4u')" class="mt-4 flex flex-row-reverse print:hidden">
      <medication-component-plan-print />
    </div>
  </section>

  <component-risk-score-dialog ref="refRiskScoreDialog" />
  <component-core-info-dialog ref="refCoreInfoDialog" />
</template>

<script>
  import {computed, inject, ref} from "vue";

  import {medicationMeal} from "@pages/Records/Components/Sections/MedicationBar/enums.js";
  import {additionalinformationDecreed, currentSource} from "@pages/Records/Components/Result/Enums.js";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentCoreInfoDialog from "@components/Dialogs/CoreInfoDialog/CoreInfoDialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentRiskCircle from "@components/Risks/RiskCircle.vue";
  import ComponentRiskDonutChart from "@components/Risks/RiskDonutChart.vue";
  import ComponentRiskScoreDialog from "@components/Dialogs/RiskScoreDialog.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import MedicationComponentPlanPrint from "@pages/Records/Components/Sections/MedicationBar/Components/PlanPrint.vue";

  export default {
    name: "PagesRecordResultMedicationTable",

    components: {
      ComponentCheckbox,
      ComponentCoreInfoDialog,
      ComponentIcon,
      ComponentRiskCircle,
      ComponentRiskDonutChart,
      ComponentRiskScoreDialog,
      ComponentTooltip,
      MedicationComponentPlanPrint,
    },

    props: {
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");

      const refRiskScoreDialog = ref(null);
      const refCoreInfoDialog = ref(null);

      const printDoc = ref(true);
      const printPatient = ref(true);
      const highlightSection = ref(false);

      const filteredMedications = computed(() => {
        return props.record.medications.filter(
          (medication) => medication?.additionalinformation?.discontinued_by !== "doctor",
        );
      });

      function formatDate(value) {
        let date = new Date(value);
        return date.toLocaleDateString("de-DE", {dateStyle: "medium"});
      }

      function formatSelectedPackage(value) {
        return typeof value === "string"
          ? // N1, N2 and N3, which are stored within the selected_package string in
            // the database, should be hidden from this table... so we search
            // and replace them with nothing
            value.replace(" (N1)", "").replace(" (N2)", "").replace(" (N3)", "")
          : "";
      }

      function openRiskScoreDialog(medication) {
        refRiskScoreDialog.value.open(medication);
      }

      function openCoreInfoDialog(medication) {
        refCoreInfoDialog.value.open(medication);
      }

      function hasDosage(medication) {
        if (!medication || !medication.dosage) {
          return false;
        }

        if (medication.dosage.dosagealternative) {
          return true;
        }

        return (
          medication.dosage.morning > 0 ||
          medication.dosage.noon > 0 ||
          medication.dosage.evening > 0 ||
          medication.dosage.night > 0
        );
      }

      function hasDosageAlternative(medication) {
        return hasDosage(medication) && medication.dosage.dosagealternative !== null;
      }

      function hasDosageInfo(medication) {
        return hasDosage(medication) && medication.dosage.info !== null && medication.dosage.info.length > 0;
      }

      function hasDeviationDosageInfo(deviantMedication) {
        return (
          deviantMedication &&
          deviantMedication.dosage &&
          deviantMedication.dosage.info !== null &&
          deviantMedication.dosage.info.length > 0
        );
      }

      function hasDosageMeal(medication) {
        return medication && medication.dosage && medication.dosage?.meal !== null;
      }

      function hasDosageCheckSkipped(medication) {
        return (
          (medication.statistic && medication.statistic.skipped && medication.statistic.skipped.length) ||
          hasDosageAlternative(medication)
        );
      }

      function showAsteriskForSkippedDosageCheck() {
        return filteredMedications.value.some((medication) => hasDosageCheckSkipped(medication));
      }

      function showAsteriskForNonAnalysable() {
        return filteredMedications.value.some(
          (medication) => medication.abdata_key_fam === null || medication.abdata_key_fam === 0,
        );
      }

      return {
        /** enum */
        additionalinformationDecreed,
        currentSource,
        medicationMeal,

        /** inject */
        hasThirdParty,

        /** ref */
        refRiskScoreDialog,
        refCoreInfoDialog,

        /** const */
        printDoc,
        printPatient,
        highlightSection,

        /** computed */
        filteredMedications,

        /** function */
        formatDate,
        formatSelectedPackage,
        openRiskScoreDialog,
        openCoreInfoDialog,
        hasDosage,
        hasDosageAlternative,
        hasDosageInfo,
        hasDeviationDosageInfo,
        hasDosageMeal,
        hasDosageCheckSkipped,
        showAsteriskForSkippedDosageCheck,
        showAsteriskForNonAnalysable,
      };
    },
  };
</script>
