<template>
  <div class="w-8">
    <template v-if="!readonly">
      <component-input
        :id="id"
        ref="dosageInput"
        :model-value="currentValue"
        :name="name"
        maxlength="4"
        :show-maxlength-count="false"
        :dosage-field="true"
        :disabled="disabled"
        :validation="isValid || disabled ? null : 'true'"
        hide-clear
        @input="inputChangedFunction"
      >
        <template v-if="!disabled" #validation>
          <component-icon v-if="!isValid" class="text-mcred text-lg cursor-pointer z-10" @click="showDosageErrorDialog">
            warning
          </component-icon>
        </template>
      </component-input>
    </template>

    <template v-else>
      <div :id="id">
        <p class="pt-2">{{ currentValue }}</p>
      </div>
    </template>
  </div>

  <component-dosage-dialog ref="refDosageDialog" @use-recommended-value="handleUseRecommendedValue" />
</template>

<script>
  import {computed, nextTick, onBeforeMount, onMounted, ref, watch} from "vue";

  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentDosageDialog from "@components/Dialogs/DosageDialog.vue";

  import dosageCalculatorFraction from "@components/Inputs/Utils/dosageCalculatorFraction.js";

  export default {
    name: "ComponentDosageInput",

    components: {
      ComponentDosageDialog,
      ComponentIcon,
      ComponentInput,
    },

    props: {
      id: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        required: true,
      },
      dataHashId: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number],
        default: 0,
      },
      asFraction: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["isInvalid", "change", "input"],

    setup(props, {emit}) {
      const refDosageDialog = ref(null);

      const dosageInput = ref(null);
      const currentValue = ref(null);
      const eventId = ref(null);

      const errorCode = computed(() => getErrorCode(currentValue.value));
      const isValid = computed(() => {
        const isValid = !errorCode.value;

        if (!isValid) emit("isInvalid", props.id);

        return isValid;
      });

      const recommendedValue = computed(() => {
        if (isValid.value) {
          if (!props.asFraction && dosageCalculatorFraction.hasFraction(currentValue)) {
            return dosageCalculatorFraction.getFractionChar(currentValue);
          }
        }

        let float, formatted, newFraction;

        switch (errorCode.value) {
          case "valueNotSet":
            return "0";
          case "fractionError":
            float = dosageCalculatorFraction.calculateFloatFromFraction(currentValue.value);
            newFraction = dosageCalculatorFraction.getFractionChar(float);

            if (newFraction) {
              return newFraction;
            }

            formatted = dosageCalculatorFraction.formatToGermanDecimalOrNaN(float);
            return formatted.length <= 4 ? formatted : null;
          case "valueToLong":
            const convertToFraction = dosageCalculatorFraction.getFractionChar(currentValue.value);
            if (convertToFraction) {
              return convertToFraction;
            }
            float = dosageCalculatorFraction.getDecimalValueOrNaN(currentValue.value);
            formatted = dosageCalculatorFraction.formatToGermanDecimal(currentValue.value);
            return formatted.length <= 4 && !isNaN(float) ? (formatted ?? null) : null;
          case "invalidChars":
            float = dosageCalculatorFraction.getDecimalValueOrNaN(currentValue.value);
            if (isNaN(float)) {
              return null;
            }
            formatted = dosageCalculatorFraction.formatToGermanDecimal(currentValue.value);
            return formatted.length <= 4 ? (formatted ?? null) : null;
          default:
            return null;
        }
      });

      watch(
        () => props.value,
        (newValue) => {
          initFunction(newValue);
        },
      );

      watch(
        () => currentValue.value,
        (newValue, oldValue) => {
          if (!isValid.value) {
            showDosageErrorDialog();
          }

          const newValueAsFraction =
            dosageCalculatorFraction.hasFraction(newValue) &&
            newValue === dosageCalculatorFraction.getFractionChar(newValue);

          if (isValid.value && oldValue !== null) {
            emit("change", {
              value: dosageCalculatorFraction.getDecimalValue(newValue),
              as_fraction: newValueAsFraction,
              id: props.id,
            });
            emit("input", {
              value: dosageCalculatorFraction.getDecimalValue(newValue),
              as_fraction: newValueAsFraction,
              id: props.id,
            });
          }
        },
      );

      onBeforeMount(() => {
        initFunction(props.value);
      });

      onMounted(() => {
        eventId.value = `${props.id}${Math.floor(Math.random() * 10000)}${Date.now()}`;
      });

      const initFunction = (newValue) => {
        if (props.asFraction && dosageCalculatorFraction.hasFraction(newValue)) {
          currentValue.value = dosageCalculatorFraction.getFractionChar(newValue);
        } else {
          currentValue.value = new Intl.NumberFormat("de-DE", {maximumFractionDigits: 20, useGrouping: false}).format(
            newValue,
          );
        }
      };

      const inputChangedFunction = (value) => {
        if (value === null) {
          value = "";
        }

        value = value.toString();

        if (value.length === 0) {
          if (props.readonly) {
            currentValue.value = "";
          } else {
            currentValue.value = 0;
          }

          return;
        } else if (value.endsWith(",") || value.endsWith("/")) {
          return;
        } else if (!getValidity(value)) {
          showDosageErrorDialog(value);
          return;
        }

        if (value.includes("/") && dosageCalculatorFraction.hasFraction(value)) {
          currentValue.value = dosageCalculatorFraction.getFractionChar(value);
          return;
        }

        currentValue.value = value;
      };

      const getErrorCode = (value) => {
        if (value !== 0 && !value) {
          return "valueNotSet";
        }

        value = value.toString();

        if (value.includes("/") && !dosageCalculatorFraction.hasFraction(value)) {
          return "fractionError";
        } else if (value.length > 4) {
          return "valueToLong";
        } else if (
          !!value.match(/[^0-9|^\/|^,]/) &&
          !dosageCalculatorFraction.hasFraction(value.replaceAll(" ", "X"))
        ) {
          return "invalidChars";
        } else if (
          !dosageCalculatorFraction.hasFraction(value) &&
          isNaN(dosageCalculatorFraction.getDecimalValueOrNaN(value))
        ) {
          return "error";
        }

        return null;
      };

      const getValidity = (value) => {
        return !getErrorCode(value);
      };

      const showDosageErrorDialog = (setToCurrentValue = null) => {
        if (setToCurrentValue) {
          currentValue.value = setToCurrentValue;
        }

        if (refDosageDialog.value) {
          refDosageDialog.value.open({
            id: eventId.value,
            type: "error",
            errorCode: errorCode.value,
            currentValue: currentValue.value,
            recommendedValue: recommendedValue.value,
          });
        }
      };

      const handleUseRecommendedValue = ({id, value}) => {
        if (eventId.value === id) {
          currentValue.value = value;
          if (isValid.value === false) {
            // reopen if still invalid
            nextTick(() => {
              refDosageDialog.value.open({
                id: eventId.value,
                type: "error",
                errorCode: errorCode.value,
                currentValue: value,
                recommendedValue: recommendedValue.value,
              });
            });
          } else {
            inputChangedFunction(value);
          }
        }
      };

      return {
        /** ref */
        refDosageDialog,

        /** const */
        dosageInput,
        currentValue,
        eventId,
        errorCode,
        isValid,

        /** computed */
        recommendedValue,

        /** function */
        inputChangedFunction,
        showDosageErrorDialog,
        handleUseRecommendedValue,
      };
    },
  };
</script>
