<template>
  <component-input
    v-model="internalValue"
    :label="label"
    type="date"
    :disabled="disabled"
    :hide-clear="false"
    :helper-text="helperText"
    :validation="dateValidationMessage ?? externalValidationMessage"
    min="1900-01-01"
    max="2100-12-31"
    @input="
      ($event) => {
        $emit('update:modelValue', $event);

        // should not validate and emit 'input' here, but every parent is
        // expecting this...
        // event instead of watching the v-model value. this causes
        // issues with the internal validation, therefore the
        // withDateValidation prop has been introduces
        if (withDateValidation) {
          validateDate($event);
        }
        $emit('input', $event);
      }
    "
    @focus="$emit('focus', $event)"
    @blur="
      ($event) => {
        if (typeof $event !== 'undefined') {
          if (withDateValidation) {
            validateDate($event.target.value);
          }
          $emit('blur', $event);
        }
      }
    "
    @keyup="$emit('keyup', $event)"
    @keydown="$emit('keydown', $event)"
  />
</template>

<script>
  import {ref} from "vue";
  import ComponentInput from "@components/Inputs/Input.vue";

  export default {
    name: "ComponentFullDateInput",
    components: {ComponentInput},

    props: {
      modelValue: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: "",
      },
      /**
       * external validation string (e.g. from server side validation)
       */
      validation: {
        type: String,
        default: null,
      },
      withDateValidation: {
        type: Boolean,
        default: false,
      },
      hideClear: {
        type: Boolean,
        default: false,
      },
      helperText: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["blur", "update:modelValue", "focus", "keyup", "keydown", "input"],

    setup(props, {emit}) {
      const internalValue = ref(props.modelValue);
      const externalValidationMessage = ref(props.validation);

      //  is initialized with "false" on create
      // and with "true" on update (because we assume, that
      // a valid date has already been stored)
      const validDate = ref(false);
      const dateValidationMessage = ref("");

      function validateDate(value) {
        if (!value) {
          validDate.value = false;
          dateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        let year = null;
        let month = null;
        let day = null;

        if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
          // YYYY-MM-DD
          year = value.split("-")[0] ?? null;
          month = value.split("-")[1] ?? null;
          day = value.split("-")[2] ?? null;
        } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
          year = value.split(".")[2] ?? null;
          month = value.split(".")[1] ?? null;
          day = value.split(".")[0] ?? null;
        } else {
          // invalid like "11980-04-30"
          validDate.value = false;
          dateValidationMessage.value = "Bitte geben Sie ein sinnvolles Datum ein";
          return;
        }

        // date is not set at all
        if (!year) {
          validDate.value = false;
          dateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        // do not allow date < 1900
        if (parseInt(year) < 1900) {
          validDate.value = false;
          dateValidationMessage.value = "Bitte geben Sie ein sinnvolles Datum ein";
          return;
        }

        const inputDate = new Date(year, month - 1, day); // month is based on zero
        const now = new Date();
        inputDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // reset external validation message if internal validation is true
        externalValidationMessage.value = null;
        validDate.value = true;
        dateValidationMessage.value = null;
      }

      return {
        /** const */
        internalValue,
        externalValidationMessage,
        dateValidationMessage,

        /** function */
        validateDate,
      };
    },
  };
</script>
