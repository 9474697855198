<template>
  <template v-if="showSymptomSelect">
    <record-component-min-c-search
      :custom-style="true"
      :value="currentValue"
      filter="symptoms"
      @input="selectedSymptom"
      @cleared="clearedSymptom"
    >
      Problem / Symptom
    </record-component-min-c-search>
  </template>

  <template v-else>
    <component-input ref="refSatisfactionInput" v-model="currentValue" @input="updateSatisfactionValue" />
  </template>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";

  import ComponentInput from "@components/Inputs/Input.vue";

  import RecordComponentMinCSearch from "@pages/Records/Components/MinCSearch.vue";

  export default {
    name: "MedicationComponentDetailDialogSatisfactionField",

    components: {ComponentInput, RecordComponentMinCSearch},

    props: {
      additionalInformationForm: {
        type: Object,
        required: true,
        default: () => {},
      },
      keyFam: {
        type: [Number, null],
        required: true,
        default: 0,
      },
    },

    emits: ["updateSatisfactionValue", "addSymptom"],

    setup(props, {emit}) {
      const currentValue = ref(null);

      const showSymptomSelect = computed(() => props.additionalInformationForm.satisfaction === "uaw");

      watch(
        () => props.additionalInformationForm.satisfactioncomment,
        (newValue) => {
          currentValue.value = newValue;
        },
      );

      onBeforeMount(() => {
        currentValue.value = props.additionalInformationForm.satisfactioncomment ?? null;
      });

      const updateSatisfactionValue = () => {
        emit("updateSatisfactionValue", currentValue.value);
      };

      const clearData = () => {
        currentValue.value = null;
        updateSatisfactionValue();
      };

      const selectedSymptom = (symptomData) => {
        currentValue.value = symptomData.Name;
        emit("updateSatisfactionValue", currentValue.value);

        const symptomObject = {
          abdata_key_miv: symptomData.Key_MIV,
          symptom: symptomData.Name,
          since: null,
          mc_symptom_id: null,
        };

        // ONLY add the symptomcontexts array when
        // props.keyFam is not null (this is
        // the case, if a medication row
        // does not contain a key_fam)
        if (props.keyFam !== null) {
          symptomObject.symptomcontexts = [
            {
              type: "fam",
              since: null,
              mc_context_id: props.keyFam,
            },
          ];
        }

        emit("addSymptom", symptomObject);
      };

      const clearedSymptom = () => {
        clearData();
        emit("addSymptom", {});
      };

      return {currentValue, showSymptomSelect, updateSatisfactionValue, clearData, selectedSymptom, clearedSymptom};
    },
  };
</script>
