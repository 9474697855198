import {nextTick, reactive, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import Crypt from "../crypt";
import {cloneDeep} from "lodash";
import axios from "axios";

export default class PrivacyVault {
  constructor(props) {
    const {client} = props;

    this.state = reactive({
      page: usePage(),

      status: {
        /** general status */
        SERVER_ERROR: "server_error",
        PENDING: "pending",

        /** Onboarding status */
        ENCRYPTION_NOT_SET: "encryption_not_set",
        ENCRYPTION_PENDING: "encryption_pending",
        ENCRYPTION_SET: "encryption_set",
        ENCRYPTION_INVALID: "encryption_invalid",
        ENCRYPTION_UPDATE: "encryption_update",

        /** default status */
        VERIFICATION_PENDING: "verification_pending",
        VERIFICATION_INVALID: "verification_invalid",
        VERIFICATION_VALID: "verification_valid",

        /** recovery status */
        RECOVERY: "recovery",
        RECOVERY_PENDING: "recovery_pending",
        RECOVERY_VERIFICATION_INVALID: "recovery_verification_invalid",
        RECOVERY_VALID: "recovery_valid",
      },

      /** @type {boolean} */
      isLoading: false,

      /** @type {string} */
      localStorageUserIdLabel: "userId",

      /** @type {string} */
      localStorageEncryptionLabel: "userEncryption",

      /** @type {string} */
      localStorageExpireDateLabel: "userEncryptionExpiresAt",

      /** @type {int} */
      passwordMinLength: 8,

      /** @type {int} */
      recoveryCodeLength: 64,

      /** @type {Crypt|null} */
      cryptObject: null,

      /** @type {string|null} */
      errorMessage: null,

      /** @type {string|null} */
      hashedPassword: null,

      /** @type{string} */
      currentStatus: "pending",

      /** @type {string|null} */
      key: "",

      /** @type {boolean} */
      recoveryCodeHasBeenPrinted: false,

      /** @type {Object|null} */
      client: client ?? null,

      /** @type {Object|null} */
      currentUser: null,

      /** @type {array} */
      users: client?.users ?? [],

      /** @type {array} */
      thirdParties: client?.third_parties ?? [],

      /** @type {string} */
      clientEncryptionChecksum: client?.clientencryptionchecksum ?? "",

      /** @type {array<function>} */
      whenCryptReady: [],

      decryptStaticContentInterval: null,
    });
  }

  // --- Getter ------------------------------------------------------------------
  /** @return {string} */
  getLocalStorageUserIdLabel() {
    return this.state.localStorageUserIdLabel;
  }

  /** @return {string} */
  getLocalStorageEncryptionLabel() {
    return this.state.localStorageEncryptionLabel;
  }

  /** @return {string} */
  getLocalStorageExpireDateLabel() {
    return this.state.localStorageExpireDateLabel;
  }

  /** @return {int} */
  getPasswordMinLength() {
    return this.state.passwordMinLength;
  }

  /** @return {int} */
  getRecoveryCodeLength() {
    return this.state.recoveryCodeLength;
  }

  /** @return {Crypt|null} */
  getCryptObject() {
    return this.state.cryptObject;
  }

  /** @return {string|null} */
  getErrorMessage() {
    return this.state.errorMessage;
  }

  /** @return {string} */
  getHashedPassword() {
    return this.state.hashedPassword;
  }

  /** @return {string|null} */
  getCurrentStatus() {
    return this.state.currentStatus;
  }

  /** @return {string|null} */
  getKey() {
    return this.state.key;
  }

  /** @return {boolean} */
  getRecoveryCodeHasBeenPrinted() {
    return this.state.recoveryCodeHasBeenPrinted;
  }

  /** @return {Object|null} */
  getClient() {
    return this.state.client;
  }

  /** @return {Object|null} */
  getCurrentUser() {
    return this.state.currentUser;
  }

  /** @return {array} */
  getUsers() {
    return this.state.users;
  }

  /** @return {array} */
  getThirdParties() {
    return this.state.thirdParties;
  }

  /** @return {string} */
  getClientEncryptionChecksum() {
    return this.state.clientEncryptionChecksum;
  }

  /** @return {string} */
  getRecoveryCode() {
    return this.state.key ? this.state.key.match(/.{1,4}/g).join(" ") : "";
  }

  /** @return {boolean} */
  getIsLoading() {
    return this.state.isLoading;
  }

  /** @return {Promise<string>} */
  getDecryptLocalStorage(waitUntilValidLocalStorage = true) {
    return new Promise(async (resolve, reject) => {
      if (!this.hasValidLocalStorage() && waitUntilValidLocalStorage) {
        // wait until valid local storage to prevent errors on sub-pages
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (this.hasValidLocalStorage()) {
              clearInterval(interval);
              resolve();
            }
          }, 5);
        });
      }

      if (!this.hasValidLocalStorage()) {
        this.purgeLocalStorage();
        reject(new Error("Local storage is invalid - date is expired or any item was not set"));
      } else {
        const currentUser = this.state.page.props.client.users.find((user) => {
          return user.id === localStorage.getItem(this.getLocalStorageUserIdLabel());
        });

        if (!currentUser) {
          // TODO delete storage because if you cant restore user means that something is wrong or not?
          reject(new Error("Current user cannot be restored from storage."));
        } else {
          // note: this has to be nested in "else", because the reject()
          // call from above does not end the method
          this.setCurrentUser(currentUser);
          const tmpCrypt = new Crypt(currentUser.userencryptionchecksum);
          resolve(tmpCrypt.decrypt(localStorage.getItem(this.getLocalStorageEncryptionLabel())));
        }
      }
    });
  }

  /**
   * @param {String} configurationsName
   * @return {Object|null}
   * */
  getConfiguration(configurationsName) {
    return (
      this.state?.currentUser?.configurations?.find((configuration) => configuration.name === configurationsName) ||
      null
    );
  }

  // --- Setter ------------------------------------------------------------------
  /**
   * @param {Crypt} newCrypt
   * @return {Crypt}
   */
  setCryptObject(newCrypt) {
    return (this.state.cryptObject = newCrypt);
  }

  /**
   * @param {string|null} newErrorMessage
   * @return {string|null}
   */
  setErrorMessage(newErrorMessage) {
    return (this.state.errorMessage = newErrorMessage);
  }

  /**
   * @param {string} newHashedPassword
   * @return {string}
   */
  setHashedPassword(newHashedPassword) {
    return (this.state.hashedPassword = newHashedPassword);
  }

  /**
   * @param {string} statusKey
   * @return {string}
   */
  setCurrentStatus(statusKey) {
    return (this.state.currentStatus = this.state.status[statusKey]);
  }

  /**
   * @param {string} newKey
   * @return {string}
   */
  setKey(newKey) {
    return (this.state.key = newKey);
  }

  /**
   * @param {boolean} newValue
   * @return {boolean}
   */
  setRecoveryCodeHasBeenPrinted(newValue) {
    return (this.state.recoveryCodeHasBeenPrinted = newValue);
  }

  /**
   * @param {Object} newClient
   * @return {Object}
   */
  setClient(newClient) {
    return (this.state.client = newClient);
  }

  /**
   * @param {Object} newCurrentUser
   * @return {Object}
   */
  setCurrentUser(newCurrentUser) {
    return (this.state.currentUser = newCurrentUser);
  }

  /**
   * @param {array} newUsers
   * @return {array}
   */
  setUsers(newUsers) {
    return (this.state.users = newUsers);
  }

  /**
   * @param {array} newThirdParties
   * @return {array}
   */
  setThirdParties(newThirdParties) {
    return (this.state.thirdParties = newThirdParties);
  }

  /**
   * @param {string} newClientEncryptionChecksum
   * @return {string}
   */
  setClientEncryptionChecksum(newClientEncryptionChecksum) {
    return (this.state.clientEncryptionChecksum = newClientEncryptionChecksum);
  }

  /**
   * @param {boolean} newBoolean
   * @return {boolean}
   */
  setIsLoading(newBoolean) {
    return (this.state.isLoading = newBoolean);
  }

  /**
   * @param {boolean|null} keyStorageDuration
   * @return {void}
   */
  setEncryptLocalStorage(keyStorageDuration = null) {
    if (localStorage) {
      localStorage.setItem(this.getLocalStorageUserIdLabel(), this.getCurrentUser().id);

      const expiresAt = ref(new Date());

      if (keyStorageDuration) {
        expiresAt.value.setHours(expiresAt.value.getHours() + 8);
      } else {
        expiresAt.value.setHours(expiresAt.value.getHours() + 3);
      }

      localStorage.setItem(this.getLocalStorageExpireDateLabel(), expiresAt.value);

      const tmpCrypt = new Crypt(this.getCurrentUser().userencryptionchecksum);
      tmpCrypt.encrypt(this.getKey()).then((encryptedKey) => {
        localStorage.setItem(this.getLocalStorageEncryptionLabel(), encryptedKey);
      });
    }
  }

  whenCryptReady(callback) {
    if (this.state.currentStatus === this.state.status["VERIFICATION_VALID"]) {
      callback();
    } else {
      this.state.whenCryptReady.push(callback);
    }
  }

  // --- Checks ------------------------------------------------------------------
  /**
   * @param {string} statusKey
   * @return {boolean}
   */
  hasCryptStatus(statusKey) {
    return this.state.currentStatus === this.state.status[statusKey];
  }

  /**
   * @param {Object} statusKeys
   * @return {boolean}
   */
  hasOneOfCryptStatus(statusKeys) {
    return statusKeys.some((key) => {
      return this.state.currentStatus === this.state.status[key];
    });
  }

  /**
   * @param {string} thirdPartyType
   * @return {boolean}
   */
  hasThirdPartyType(thirdPartyType) {
    return (
      typeof this.state.thirdParties !== "undefined" &&
      this.state.thirdParties.length &&
      this.state.thirdParties.filter((tp) => tp.type === thirdPartyType).length
    );
  }

  /** @return {boolean} */
  hasAccountEducationStatus() {
    return this.state.page.props.can?.is_education ?? false;
  }

  /** @return {boolean} */
  hasValidLocalStorage() {
    const now = new Date().getTime();
    const expirationTime = new Date(localStorage.getItem(this.getLocalStorageExpireDateLabel())).getTime();

    return (
      localStorage &&
      localStorage.getItem(this.getLocalStorageExpireDateLabel()) &&
      localStorage.getItem(this.getLocalStorageUserIdLabel()) &&
      localStorage.getItem(this.getLocalStorageEncryptionLabel()) &&
      now < expirationTime
    );
  }

  /**
   * @param {function} callback
   * @param {function} errorCallback
   * @param {string} recoveryCodePlaintext
   * @return {Promise}
   */
  verifyRecoveryCode(callback, errorCallback, recoveryCodePlaintext) {
    this.setErrorMessage(null);

    return this.verifyRecoveryCodeWithClientEncryptionChecksum(recoveryCodePlaintext)
      .then(() => {
        if (typeof callback === "function") callback();
      })
      .catch(() => {
        if (typeof errorCallback === "function") errorCallback();
      });
  }

  // --- Actions ------------------------------------------------------------------
  /**
   * @param {string} passwordPlain
   * @return {Promise<string>}
   */
  hashPlaintextPassword(passwordPlain) {
    return new Crypt("").sha256(passwordPlain);
  }

  /**
   * @param {string} hashedPassword
   * @return {void}
   */
  plaintextRecoveryCode(hashedPassword) {
    if (this.getErrorMessage()) this.setErrorMessage(null);
    this.setKey(hashedPassword.split(" ").join(""));
  }

  /** @return {void} */
  purgeLocalStorage() {
    localStorage.removeItem("user");
    localStorage.removeItem(this.getLocalStorageUserIdLabel());
    localStorage.removeItem(this.getLocalStorageEncryptionLabel());
    localStorage.removeItem(this.getLocalStorageExpireDateLabel());
  }

  /**
   * @param {string} clientEncryptionChecksum
   */
  createClient(clientEncryptionChecksum) {
    return new Promise((resolve, reject) => {
      axios
        .post(route("api.clients.store"), {clientencryptionchecksum: clientEncryptionChecksum})
        .then((response) => {
          if (response.data) {
            this.setClientEncryptionChecksum(response.data.clientencryptionchecksum);
            this.setThirdParties(response.data.third_parties);
            this.setClient(response.data);

            resolve(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  createUser() {
    return new Promise((resolve, reject) => {
      const tmpCrypt = new Crypt(this.getHashedPassword());

      Promise.all([tmpCrypt.encrypt("VERIFY-CLIENT-ENCRYPTION"), tmpCrypt.encrypt(this.getKey())]).then(
        ([userEncryptionChecksum, userEncryption]) => {
          axios
            .post(route("api.users.store"), {
              userencryption: userEncryption,
              userencryptionchecksum: userEncryptionChecksum,
            })
            .then((response) => {
              if (response.data) {
                this.setCurrentUser(response.data);
                resolve(response.data);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        },
      );
    });
  }

  setPassword() {
    if (this.getErrorMessage()) this.setErrorMessage(null);

    this.setIsLoading(true);
    this.initCrypt("", "ENCRYPTION_PENDING");

    this.getCryptObject()
      .encrypt("VERIFY-CLIENT-ENCRYPTION")
      .then((clientEncryptionChecksum) => {
        this.createClient(clientEncryptionChecksum).then(() => {
          this.createUser().then(() => {
            this.setEncryptLocalStorage();

            if (this.hasAccountEducationStatus()) {
              this.setCurrentStatus("VERIFICATION_VALID");
            } else {
              this.setCurrentStatus("ENCRYPTION_SET");
            }

            this.setIsLoading(false);
          });
        });
      });
  }

  /**
   * @param {string} plaintextPassword
   * @return {void}
   */
  plaintextPassword(plaintextPassword) {
    if (this.getErrorMessage()) this.setErrorMessage(null);
    if (this.state.recoveryCodeHasBeenPrinted) this.state.recoveryCodeHasBeenPrinted = false;

    const tmpCrypt = new Crypt("");
    tmpCrypt.sha256(plaintextPassword).then((hash) => {
      this.setHashedPassword(hash);

      const currentStatus = this.getCurrentStatus();
      if (
        currentStatus === this.state.status.ENCRYPTION_NOT_SET ||
        currentStatus === this.state.status.ENCRYPTION_INVALID
      ) {
        this.setKey(
          this.getHashedPassword()
            .split("")
            .sort(() => 0.5 - Math.random())
            .join(""),
        );
      }
    });
  }

  /**
   * @param {function} callback
   * @param {function} errorCallback
   * @return {void}
   */
  verifyPassword(callback, errorCallback) {
    if (this.getErrorMessage()) this.setErrorMessage(null);

    this.verifyPasswordWithUserEncryptionChecksum()
      .then((currentUser) => {
        this.setCurrentUser(currentUser);

        if (currentUser.userencryption) {
          const tmpCrypt = new Crypt(this.getHashedPassword());

          tmpCrypt
            .decrypt(currentUser.userencryption)
            .then((decryptedValue) => {
              this.setKey(decryptedValue);

              if (typeof callback === "function") callback();
            })
            .catch(() => {
              if (typeof errorCallback === "function") errorCallback();
            });
        }
      })
      .catch(() => {
        if (typeof errorCallback === "function") errorCallback();
      });
  }

  /**
   * @param {string|null} hashedPassword
   * @return {Promise}
   */
  verifyPasswordWithUserEncryptionChecksum(hashedPassword = null) {
    const tmpCrypt = new Crypt(hashedPassword || this.getHashedPassword());

    return Promise.any(
      this.getUsers().map((user) => {
        return new Promise((resolve, reject) => {
          tmpCrypt
            .decrypt(user.userencryptionchecksum)
            .then((decrypted) => {
              if (decrypted === "VERIFY-CLIENT-ENCRYPTION") {
                resolve(user);
              } else {
                reject(
                  new Error(
                    "Password could not be verified with user encryption checksum, perhaps not the correct user.",
                  ),
                );
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }),
    );
  }

  dateToHuman(value) {
    let d = new Date(value);
    return isNaN(d.getTime()) ? "" : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
  }

  datetimeToHuman(value) {
    let d = new Date(value);
    return isNaN(d.getTime())
      ? ""
      : d.toLocaleDateString("de-DE", {dateStyle: "medium"}) +
          ", " +
          d.getHours().toString().padStart(2, "0") +
          ":" +
          d.getMinutes().toString().padStart(2, "0");
  }

  yearToHuman(value) {
    let d = new Date(value);
    return isNaN(d.getTime()) ? "" : d.getFullYear();
  }

  dateToAge(value) {
    let d = new Date(value);

    // early exit
    if (isNaN(d.getTime())) return "";

    const today = new Date();

    // calculate age
    let age = today.getFullYear() - d.getFullYear();
    const monthDiff = today.getMonth() - d.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < d.getDate())) {
      age--;
    }

    return age + " Jahre";
  }

  /**
   * @param {ref|undefined} ref
   * @return {void}
   */
  decryptStaticContent(ref = undefined) {
    if (this.state.currentStatus !== this.state.status["VERIFICATION_VALID"]) {
      // stop recurring decryption
      if (this.state.decryptStaticContentInterval !== null) {
        clearInterval(this.state.decryptStaticContentInterval);
        this.state.decryptStaticContentInterval = null;
      }
      // early exit when currentStatus is not ready
      return;
    } else {
      // start recurring decryption of static content
      if (this.state.decryptStaticContentInterval === null) {
        this.state.decryptStaticContentInterval = setInterval(() => {
          this.decryptStaticContent();
        }, 2000);
      }
    }

    let elements =
      typeof ref !== "undefined" && ref !== null
        ? ref.querySelectorAll("[data-encrypted]")
        : document.querySelectorAll("[data-encrypted]");

    let promises = Array.from(elements).map((elm) => {
      return this.getCryptObject()
        .decrypt(elm.getAttribute("data-encrypted"))
        .then((plaintext) => ({elm, plaintext}))
        .catch((error) => {
          console.error("catch", error);
          let value = elm.getAttribute("data-encrypted");
          return {elm, value};
        });
    });

    Promise.all(promises).then((results) => {
      // results is an array of objects with {elm, plaintext}
      results.forEach(({elm, plaintext}) => {
        const format = elm.getAttribute("data-format");

        if (format) {
          switch (format) {
            case "date":
              plaintext = this.dateToHuman(plaintext);
              break;
            case "datetime":
              plaintext = this.datetimeToHuman(plaintext);
              break;
            case "year":
              plaintext = this.yearToHuman(plaintext);
              break;
            case "datefake":
              plaintext = "XX.XX." + this.yearToHuman(plaintext);
              break;
            case "age":
              plaintext = this.dateToAge(plaintext);
              break;
            case "age-brackets":
              plaintext = this.dateToAge(plaintext) !== "" ? "(" + this.dateToAge(plaintext) + ")" : "";
              break;
          }
        }

        if (elm.tagName === "INPUT" && elm.type === "text") {
          elm.value = plaintext;
        } else {
          if (format === "html") {
            elm.innerHTML = plaintext;
          } else {
            elm.innerText = plaintext;
          }
        }
      });
    });
  }

  removePlaintextContent() {
    document.querySelectorAll("[data-encrypted]").forEach((elm) => {
      elm.innerText = "";
    });
  }

  /**
   * @param {string|number} value
   * @return {Promise<string>}
   */
  encryptValue(value) {
    return new Promise((resolve, reject) => {
      this.getDecryptLocalStorage()
        .then((key) => {
          const tmpCrypt = new Crypt(key);
          this.setKey(key);
          this.initCrypt();

          /** encryptData - encrypt objects depends on key values */
          tmpCrypt
            .encrypt(value)
            .then((result) => {
              resolve(result);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  /**
   * @param {string} value
   * @return {Promise<string>}
   */
  decryptValue(value) {
    return new Promise((resolve, reject) => {
      this.getDecryptLocalStorage()
        .then((key) => {
          const tmpCrypt = new Crypt(key);
          this.setKey(key);
          this.initCrypt();

          /** encryptData - encrypt objects depends on key values */
          tmpCrypt
            .decrypt(value)
            .then((plaintext) => {
              resolve(plaintext);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  /**
   * @param {Object} patient
   * @return {Promise<Object>}
   */
  async encryptPatient(patient) {
    const encrypt = async (value) => {
      if (value) {
        return await this.encryptValue(value);
      } else {
        return null;
      }
    };

    const clip = (field, value) => {
      switch (field) {
        case "birthdate":
          if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
            // YYYY-MM-DD
            return value.split("-")[0] ?? null;
          } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
            return value.split(".")[2] ?? null;
          } else {
            return null;
          }
        case "firstname":
        case "suffix":
        case "prefix":
        case "lastname":
        case "insurancenumber":
          return (value ?? "").substring(0, 3) || null;
      }
    };

    const _patient = cloneDeep(patient);

    _patient.firstname = await encrypt(_patient.firstname);
    _patient.firstname_clipped = clip("firstname", patient.firstname);
    _patient.suffix = await encrypt(_patient.suffix);
    _patient.suffix_clipped = clip("suffix", patient.suffix);
    _patient.prefix = await encrypt(_patient.prefix);
    _patient.prefix_clipped = clip("prefix", patient.prefix);
    _patient.lastname = await encrypt(_patient.lastname);
    _patient.lastname_clipped = clip("lastname", patient.lastname);
    _patient.birthdate = await encrypt(_patient.birthdate);
    _patient.birthdate_clipped = clip("birthdate", patient.birthdate);
    _patient.insurancenumber = await encrypt(_patient.insurancenumber);
    _patient.insurancenumber_clipped = clip("insurancenumber", patient.insurancenumber);
    _patient.note = await encrypt(_patient.note);

    return _patient;
  }

  /**
   * @param {Object} record
   * @return {Promise<Object>}
   */
  async encryptRecord(record) {
    const _record = cloneDeep(record);

    const encrypt = async (value) => {
      return value ? await this.encryptValue(value) : null;
    };

    _record.info = await encrypt(_record?.info);
    _record.doctoraddress = await encrypt(_record?.doctoraddress);
    _record.patientaddress = await encrypt(_record?.patientaddress);
    _record.useraddress = await encrypt(_record?.useraddress);

    return _record;
  }

  /**
   * @param {Object} patient
   * @return {Promise<Object>}
   */
  async decryptPatient(patient) {
    const _patient = cloneDeep(patient);

    _patient.firstname = await this.decryptValue(_patient.firstname);
    _patient.suffix = await this.decryptValue(_patient.suffix);
    _patient.prefix = await this.decryptValue(_patient.prefix);
    _patient.lastname = await this.decryptValue(_patient.lastname);
    _patient.birthdate = await this.decryptValue(_patient.birthdate);

    // when we receive a birthdate in dd.mm.yyyy (from external partners)
    // we have to convert it to yyyy-mm-dd
    if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(_patient.birthdate)) {
      let [day, month, year] = _patient.birthdate.split(".");
      if (day.length === 1) day = "0" + day;
      if (month.length === 1) month = "0" + month;
      _patient.birthdate = `${year}-${month}-${day}`;
    }

    _patient.birthdate_formatted = _patient.is_fake
      ? "XX.XX." + this.yearToHuman(_patient.birthdate)
      : this.dateToHuman(_patient.birthdate);
    _patient.insurancenumber = await this.decryptValue(_patient.insurancenumber);
    _patient.note = await this.decryptValue(_patient.note);

    return _patient;
  }

  /**
   * @param {Object} record
   * @return {Promise<Object>}
   */
  async decryptRecord(record) {
    const _record = cloneDeep(record);

    _record.info = await this.decryptValue(_record.info);
    _record.doctoraddress = await this.decryptValue(_record.doctoraddress);
    _record.patientaddress = await this.decryptValue(_record.patientaddress);
    _record.useraddress = await this.decryptValue(_record.useraddress);

    return _record;
  }

  /**
   * @param {string|null} key
   * @return {Promise}
   */
  verifyRecoveryCodeWithClientEncryptionChecksum(key) {
    return new Promise((resolve, reject) => {
      const tmpCrypt = new Crypt(key || this.getKey());

      tmpCrypt
        .decrypt(this.getClientEncryptionChecksum())
        .then((decryptedValue) => {
          if (decryptedValue === "VERIFY-CLIENT-ENCRYPTION") {
            resolve(true);
          } else {
            reject(new Error("Recovery code (key) could not be verified with client encryption checksum."));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @param {function} callback
   * @param {function} errorCallback
   * @param {string} hashedPassword
   * @param {bool} avoidStatusUpdate
   * @return {void}
   */
  patchCurrentUserEncryption(callback, errorCallback, hashedPassword, avoidStatusUpdate) {
    if (typeof avoidStatusUpdate === "undefined" || avoidStatusUpdate === false) {
      this.setCurrentStatus("ENCRYPTION_UPDATE");
    }
    const tmpCrypt = new Crypt(hashedPassword);

    Promise.all([tmpCrypt.encrypt("VERIFY-CLIENT-ENCRYPTION"), tmpCrypt.encrypt(this.getKey())]).then(
      ([userEncryptionChecksum, userEncryption]) => {
        router.patch(
          route("privacyPassword.update", {user: this.getCurrentUser().id}),
          {
            userencryption: userEncryption,
            userencryptionchecksum: userEncryptionChecksum,
          },
          {
            preserveState: true,

            onError: (error) => {
              console.error(error);
              if (typeof errorCallback === "function") errorCallback();
            },
            onSuccess: (response) => {
              const user = response.props.client.users.filter(
                (user) => user.userencryptionchecksum === userEncryptionChecksum,
              );

              this.setCurrentUser(user[0]);
              this.setUsers(response.props.client.users);
            },
            onFinish: () => {
              if (typeof callback === "function") callback();
            },
          },
        );
      },
    );
  }

  /**
   * @param {string|null} key
   * @param {string} status
   * @return {void}
   */
  initCrypt(key = "", status = "VERIFICATION_VALID") {
    this.setCryptObject(new Crypt(key || this.getKey()));
    this.setCurrentStatus(status);
  }

  /**
   * @param {function} successCallback
   * @param {function} errorCallback
   * @return {void}
   */
  checkPrivacyVaultPasswordValidity(successCallback, errorCallback) {
    if (this.state.page.props && this.state.page.props.client) {
      this.setClientEncryptionChecksum(this.state.page.props.client.clientencryptionchecksum);
      this.setClient(this.state.page.props.client);
      this.setUsers(this.state.page.props.client.users);
      this.setThirdParties(this.state.page.props.client.third_parties);

      this.getDecryptLocalStorage(false)
        .then((key) => {
          const tmpCrypt = new Crypt(key);

          tmpCrypt
            .decrypt(this.getClientEncryptionChecksum())
            .then((decryptedValue) => {
              if (decryptedValue === "VERIFY-CLIENT-ENCRYPTION") {
                this.setKey(key);
                this.initCrypt();
                nextTick(() => {
                  this.decryptStaticContent();
                });

                successCallback();

                let callback;
                while (typeof (callback = this.state.whenCryptReady.pop()) === "function") {
                  callback();
                }
              } else {
                this.setCurrentStatus("VERIFICATION_PENDING");
                this.removePlaintextContent();
                if (typeof errorCallback === "function") errorCallback();
              }
            })
            .catch(() => {
              this.setCurrentStatus("VERIFICATION_PENDING");
              this.removePlaintextContent();
              if (typeof errorCallback === "function") errorCallback();
            });
        })
        .catch(() => {
          if (this.getUsers().length === 0) {
            this.setCurrentStatus("ENCRYPTION_NOT_SET");
          } else {
            this.setCurrentStatus("VERIFICATION_PENDING");
          }

          this.removePlaintextContent();
          if (typeof errorCallback === "function") errorCallback();
        });
    } else {
      this.setCurrentStatus("ENCRYPTION_NOT_SET");
      if (typeof errorCallback === "function") errorCallback();
    }
  }
}
