<template>
  <component-card
    title="Patientenakte löschen"
    :subtitle="
      patient.from_wawi
        ? 'Sie können den Patienten nicht löschen, da dieser aus der Warenwirtschaft übertragen wurde.'
        : 'Sie können die Patientenakte inkl. aller enthaltenen Daten löschen.'
    "
    class="rounded-lg border-2 border-gray-200"
    :test-id="testId + '-patient-delete'"
  >
    <template v-if="!patient.from_wawi" #actions>
      <component-button
        label="Löschen"
        :disabled="form.processing"
        :test-id="testId + '-patient-delete-card-delete'"
        @click="confirmRemove()"
      />
    </template>

    <component-confirmation-dialog
      ref="refConfirmationDialog"
      :title="patient.is_fake ? 'Patient endgültig löschen?' : 'Patient löschen?'"
      :content="
        patient.is_fake
          ? 'Die Patientenakte wird mit allen enthaltenen Daten endgültig und unwiederbringlich gelöscht.'
          : 'Die Patientenakte wird mit allen zugehörigen Medikationsanalysen in „Gelöschte Patienten“ verschoben.'
      "
      :test-id="testId + '-patient-delete-card'"
      @confirmed="remove()"
    />
  </component-card>
</template>

<script>
  import {ref} from "vue";
  import {router, useForm} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";

  export default {
    name: "PatientsPatientDelete",

    components: {
      ComponentConfirmationDialog,
      ComponentCard,
      ComponentButton,
    },

    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
    },

    setup(props) {
      const refConfirmationDialog = ref(null);

      const form = useForm({});

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        if (props.patient.is_fake) {
          form.delete(route("patients.destroy", {patient: props.patient.id, action: "forceDelete"}));
        } else {
          form.delete(route("patients.destroy", {patient: props.patient.id}));
        }
      }

      return {
        /** ref */
        refConfirmationDialog,

        /** const */
        form,

        /** function */
        confirmRemove,
        remove,
      };
    },
  };
</script>
