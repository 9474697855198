<template>
  <li class="grid grid-cols-12 gap-x-6 py-2">
    <!-- col 1 - Symptom -->
    <div class="col-span-4">
      <div class="flex">
        <template v-if="isEditable">
          <template v-if="symptomForm.symptom">
            <div class="more icon-wrap flex items-center justify-center">
              <component-tooltip
                placement="right-end"
                tooltip="Zeile verschieben"
                custom-class-label="flex items-center"
                :force-hidden="isDragging"
              >
                <component-icon class="text-gray-900 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
              </component-tooltip>
            </div>
          </template>

          <template v-if="!symptomForm.symptom">
            <div class="more icon-wrap flex items-center justify-center">
              <component-icon class="text-gray-400 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
            </div>
          </template>
        </template>

        <component-fake-input class="w-full" :value="symptomForm.symptom" :disabled="!isEditable" />
      </div>
    </div>

    <!-- col 2 - Seit(Datum) -->
    <div class="col-span-2">
      <component-full-date-input v-model="symptomForm.since" :disabled="!isEditable" @input="changeSince" />
    </div>

    <div class="col-span-2">
      <component-select
        ref="context"
        v-model="symptomForm.context"
        :options="symptomaticContexts"
        :disabled="!isEditable"
        :tabindex="3"
        :nullable="true"
        placeholder-text=""
        @change="changeContext"
      />
    </div>

    <!-- col 4 - Warum/Was -->
    <div class="col-span-3">
      <template v-if="isEditable">
        <template v-if="symptomForm.context?.length > 0">
          <div class="cursor-pointer h-9 border-b-1 border-gray-300 p-2" @click="openContextDialog">
            <p class="text-sm truncate">
              <component-tooltip :tooltip="contextTooltip">
                {{ contextLabel }}
              </component-tooltip>
            </p>
          </div>
        </template>

        <template v-else>
          <div class="h-9 border-b-1 border-gray-300 p-2">
            <component-tooltip tooltip="Bitte wählen Sie zuerst eine Vermutung aus.">
              <div class="text-left h-9">-</div>
            </component-tooltip>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="h-9 border-b-1 border-gray-300 p-2 border-dashed">
          <template v-if="contextTooltip">
            <component-tooltip :tooltip="contextTooltip">
              {{ contextLabel }}
            </component-tooltip>
          </template>
        </div>
      </template>
    </div>

    <div class="col-span-1 flex m-auto">
      <template v-if="isEditable">
        <component-tooltip placement="top" tooltip="Zeile löschen">
          <component-icon-button
            class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
            icon="delete"
            @click="showDeleteConfirmDialog"
          />
        </component-tooltip>
      </template>
    </div>
  </li>
</template>

<script>
  import {inject, onBeforeMount, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentFakeInput from "@components/Inputs/FakeInput.vue";

  export default {
    name: "SymptomTableRow",

    components: {
      ComponentFakeInput,
      ComponentFullDateInput,
      ComponentIcon,
      ComponentIconButton,
      ComponentSelect,
      ComponentTooltip,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      symptom: {
        type: Object,
        required: true,
      },
      isDragging: {
        type: Boolean,
        required: true,
      },
    },

    emits: ["updateSymptoms"],

    setup(props, {emit}) {
      const page = usePage();

      const openDialog = inject("symptomOpenDialog");
      const deleteDialog = inject("recordEditDeleteRowDialog");

      const refContextDialog = ref(null);

      const contextLabel = ref(null);
      const contextTooltip = ref(null);
      const isLoading = ref(false);
      const symptomForm = ref(props.symptom);

      const symptomaticContexts = {
        fam: "Medikation",
        diagnosis: "Erkrankung",
        laborvalue: "Laborwert",
      };

      watch(
        () => symptomForm.value?.context || symptomForm.value?.symptomcontexts.length,
        () => {
          buildContextLabel();
        },
      );

      onBeforeMount(() => {
        buildContextLabel();
      });

      const buildContextLabel = () => {
        const message = [];

        if (symptomForm.value.context && symptomForm.value.contextvalue.length > 0) {
          symptomForm.value.contextvalue.map((contextId) => {
            if (symptomForm.value.context === "fam") {
              const medication = page.props.record.medications.find(
                (medication) => medication.abdata_key_fam === contextId,
              );

              message.push(medication.fam);
            } else if (symptomForm.value.context === "diagnosis") {
              const disease = page.props.record.diseases.find((disease) => disease.abdata_key_miv === contextId);

              message.push(disease.disease);
            } else if (symptomForm.value.context === "laborvalue") {
              const laborValue = page.props.record.laborvalues.find(
                (laborValue) => laborValue.mc_laborvalue_id === contextId,
              );

              message.push(laborValue.type);
            }
          });
        }

        contextTooltip.value = message.map((item) => `- ${item}`).join("<br/>");

        contextLabel.value = message.join(", ").substring(0, 30);
        contextLabel.value += message.join(", ").length > 30 ? "..." : "";
      };

      const changeSince = (newValue) => {
        symptomForm.value.since = newValue;
        emit("updateSymptoms");
      };

      const changeContext = (newValue) => {
        symptomForm.value.contextvalue = [];
        symptomForm.value.symptomcontexts = {};

        symptomForm.value.context = newValue;
      };

      const openContextDialog = () => {
        if (!symptomForm.value.context) return;

        openDialog("refSymptomContextDialog", symptomForm.value);
      };

      const changeForm = ({context, isChecked}) => {
        let list = Array.isArray(symptomForm.value.symptomcontexts) ? cloneDeep(symptomForm.value.symptomcontexts) : [];

        if (isChecked) {
          list.push(context);
        } else {
          list = list.filter((symptomContext) => symptomContext.mc_context_id !== context.mc_context_id);
        }

        symptomForm.value.symptomcontexts = list;
      };

      const close = () => {
        if (refContextDialog.value) refContextDialog.value.close();
      };

      const closeAndResetDialog = () => {
        const rollbackValues = page.props.record.symptoms.find((symptom) => symptom.id === symptomForm.value.id);

        symptomForm.value.symptomcontexts = rollbackValues.symptomcontexts;
        close();
      };

      const showDeleteConfirmDialog = () => {
        deleteDialog({routeName: "symptoms", payload: {symptom: symptomForm.value.id}});
      };

      return {
        /** ref */
        refContextDialog,

        /** const */
        contextLabel,
        contextTooltip,
        isLoading,
        symptomForm,
        symptomaticContexts,

        /** function */
        changeSince,
        changeContext,
        openContextDialog,
        changeForm,
        closeAndResetDialog,
        showDeleteConfirmDialog,
      };
    },
  };
</script>
