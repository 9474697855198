<template>
  <component-dialog
    ref="refDialog"
    dialog-title="Import aus Warenwirtschaft"
    dialog-width="large"
    :test-id="testId + '-transfer-import'"
  >
    <template #content>
      <section v-if="validPatient && dataHasBeenCompleted">
        <div v-if="decryptedPatient">
          Bereits vorhandene Patienten werden durchsucht nach

          <strong class="font-semibold after:content-[',_'] last:after:content-['']">
            {{ decryptedPatient.firstname }} {{ decryptedPatient.lastname }}
          </strong>

          <span
            v-if="decryptedPatient.birthdate_formatted"
            class="after:content-[',_'] last:after:content-['']"
            v-text="decryptedPatient.birthdate_formatted"
          />

          <span v-if="decryptedPatient.insurancenumber" v-text="decryptedPatient.insurancenumber" />
        </div>

        <component-existing-patient-select
          v-if="existingPatients.length > 1"
          class="mt-4"
          :existing-patients="existingPatients"
          :current-patient="decryptedPatient"
          :test-id="testId + '-transfer-import-dialog'"
          @existing-patient-selected="addToExistingPatient"
        >
          <span>
            Bitte wählen Sie einen bereits existierenden Patienten aus, wenn es sich um die gleiche Person handelt. Für
            diesen Patienten wird anschließend eine neue Analyse erstellt. Alternativ können Sie die Daten für einen
            neuen Patienten importieren.
          </span>
        </component-existing-patient-select>

        <div v-else class="h-40 flex justify-center items-center">
          <component-spinner class="w-10 h-10" :test-id="testId + '-transfer-import-dialog'" />
        </div>
      </section>

      <section v-else>
        <component-alert :test-id="testId + '-transfer-import-dialog'">
          <strong class="font-semibold">Aus Ihrer Warenwirtschaft wurden nicht alle Pflichtangaben übertragen.</strong>
          <p class="max-w-3xl">
            Das kann der Fall sein, wenn Sie in der Warenwirtschaft eine unvollständige Patientenakte hinterlegt haben,
            bei der nicht mindestens Vorname, Nachname und Geburtsdatum des Patienten gesetzt sind.
          </p>
        </component-alert>

        <p>Sie können folgende fehlende Angabe(n) ergänzen, um mit dem Import fortzufahren.</p>

        <div class="mt-8 grid grid-cols-2 gap-8">
          <component-input
            v-model="decryptedPatient.firstname"
            label="Vorname"
            :test-id="testId + '-transfer-import-dialog-firstname'"
          />

          <component-input
            v-model="decryptedPatient.lastname"
            label="Nachname"
            :test-id="testId + '-transfer-import-dialog-lastname'"
          />

          <component-input
            v-model="decryptedPatient.birthdate"
            label="Geburtsdatum"
            type="date"
            :hide-clear="true"
            :test-id="testId + '-transfer-import-dialog-birthdate'"
          />

          <component-select
            v-model="decryptedPatient.gender"
            label="Geschlecht"
            :options="patientGender"
            :test-id="testId + '-transfer-import-dialog-gender'"
          />
        </div>

        <p class="mt-4 max-w-2xl">
          Bitte beachten Sie, dass diese ergänzten Daten
          <em class="font-semibold italic">nicht</em>
          zurück in Ihre Warenwirtschaft übertragen werden. Der bessere Weg ist, wenn Sie die fehlenden Daten statt
          dessen direkt in Ihrer Warenwirtschaft ergänzen und erneut die Medikationsanalyse starten.
        </p>
      </section>
    </template>

    <template #actions>
      <component-button
        v-if="!dataHasBeenCompleted"
        class="p4umc-primary"
        :disabled="!validPatient"
        :test-id="testId + '-transfer-import-dialog-search'"
        @click="search()"
      >
        Weiter
      </component-button>

      <component-button
        v-if="existingPatients.length > 1"
        class="p4umc-primary"
        :test-id="testId + '-transfer-import-dialog-save'"
        @click="save()"
      >
        Als neuen Patienten importieren
      </component-button>

      <component-button :test-id="testId + '-transfer-import-dialog-close'" @click="close()">
        Abbrechen
      </component-button>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, ref, onMounted} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import {patientGender} from "@pages/Patients/Enums/Enums.js";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentExistingPatientSelect from "@components/ExistingPatientSelect/ExistingPatientSelect.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  export default {
    name: "ComponentTransferImportDialog",

    components: {
      ComponentSelect,
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentExistingPatientSelect,
      ComponentInput,
      ComponentSpinner,
    },

    props: {
      transfer: {
        type: Object,
        required: true,
      },
    },

    setup(props, {}) {
      const privacy = inject("$privacy");
      const axios = inject("$axios");

      const refDialog = ref(null);

      const existingPatients = ref([]);
      const dataHasBeenCompleted = ref(false);

      const form = useForm({
        patient: null,
      });

      const decryptedPatient = ref({
        firstname: null,
        lastname: null,
        birthdate: null,
        insurancenumber: null,
        gender: null,
      });

      const validPatient = computed(() => {
        return (
          typeof decryptedPatient.value.firstname !== "undefined" &&
          decryptedPatient.value.firstname !== "" &&
          decryptedPatient.value.firstname !== null &&
          typeof decryptedPatient.value.lastname !== "undefined" &&
          decryptedPatient.value.lastname !== "" &&
          decryptedPatient.value.lastname !== null &&
          typeof decryptedPatient.value.birthdate !== "undefined" &&
          decryptedPatient.value.birthdate !== "" &&
          decryptedPatient.value.birthdate !== null &&
          typeof decryptedPatient.value.gender !== "undefined" &&
          decryptedPatient.value.gender !== "" &&
          decryptedPatient.value.gender !== null
        );
      });

      onMounted(() => {
        refDialog.value.open();
        privacy.decryptPatient(props.transfer.data.patient).then((decryptedObject) => {
          decryptedPatient.value = decryptedObject;

          // replace a empty string insurancenumber with null (this field is not required)
          if (decryptedPatient.value?.insurancenumber === "") {
            decryptedPatient.value.insurancenumber = null;
          }

          // wawis SHOULD always send firstname, lastname and birthdate, but
          // sometimes they do not (and just send an encrypted empty string)
          if (validPatient.value) {
            // data is complete (should be the default)
            dataHasBeenCompleted.value = true;
            search();
          }
        });
      });

      const search = () => {
        if (dataHasBeenCompleted.value === false) {
          if (decryptedPatient.value.birthdate_formatted === "") {
            let tmp = decryptedPatient.value.birthdate.split("-");
            decryptedPatient.value.birthdate_formatted = tmp[2] + "." + tmp[1] + "." + tmp[0];
          }

          dataHasBeenCompleted.value = true;
        }
        searchExistingPatients().then(() => {
          if (existingPatients.value.length === 0) {
            // auto-import, if no matching patient has been found...
            save();
          } else if (existingPatients.value.length === 1) {
            // ...or if exactly 1 matching patient has been found
            save(existingPatients.value[0].id);
          }
        });
      };

      const searchExistingPatients = async () => {
        const search = async () => {
          existingPatients.value = filterExistingPatients(
            decryptedPatient.value,
            await Promise.all(
              (
                await axios.post(route("api.patients.search"), await privacy.encryptPatient(decryptedPatient.value))
              ).data.data.map(async (patient) => await privacy.decryptPatient(patient)),
            ),
            ["firstname", "lastname", "birthdate"],
          );
        };

        try {
          await search();
        } catch (e) {
          // If privacy password is expired
          await new Promise((resolve) => {
            const interval = setInterval(async () => {
              if (privacy.hasValidLocalStorage()) {
                await search();
                clearInterval(interval);
                resolve();
              }
            }, 1000);
          });
        }
      };

      function close() {
        refDialog.value.close();
        decryptedPatient.value = null;
        existingPatients.value = [];
      }

      const save = (patientId) => {
        privacy.encryptPatient(decryptedPatient.value).then((encryptedPatient) => {
          form
            .transform(() => {
              // we only have to send the re-encrypted patient (with clipped values),
              // all the transfer data already exists in data service
              return {patient: encryptedPatient};
            })
            .post(route("init-from-transfer", {transfer: props.transfer.id, patient: patientId ?? null}), {
              preserveScroll: true,
              onError: (error) => {
                console.error(error);
              },
            });
        });
      };

      const addToExistingPatient = (selectedPatient) => {
        save(selectedPatient.id);
      };

      return {
        /** ref */
        refDialog,

        /** const */
        decryptedPatient,
        existingPatients,
        dataHasBeenCompleted,

        /** enum */
        patientGender,

        /** computed */
        validPatient,

        /** function */
        close,
        save,
        addToExistingPatient,
        search,
      };
    },
  };
</script>
