<template>
  <template v-if="hasPatientName">
    <inertia-head :title="title" />
  </template>

  <nav :class="$attrs.class" class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-2">
      <li>
        <inertia-link
          :href="$route('dashboard.index')"
          class="group flex items-center text-gray-500 hover:text-gray-900 hover:no-underline"
          data-test="breadcrumb-dashboard-index-item"
        >
          <component-icon>space_dashboard</component-icon>

          <span class="ml-2 font-semibold text-sm hidden group-hover:inline-block group-hover:underline">
            <div class="leading-4">Startseite</div>
          </span>
        </inertia-link>
      </li>

      <li v-for="(link, index) in processedLinks" :key="'breadcrumb-' + index">
        <div class="flex items-center">
          <component-icon class="text-gray-400">chevron_right</component-icon>

          <div class="ml-2 font-semibold text-sm relative">
            <component
              :is="link.url ? 'inertia-link' : 'span'"
              :href="link.url"
              :class="{
                'text-gray-500 hover:text-gray-900 hover:underline': link.url,
                'text-gray-900': !link.url,
              }"
              class="block"
              :data-test="'breadcrumb-' + link.testId + '-item'"
            >
              <div v-if="link.label" class="leading-4">
                <span v-text="link.label" />
              </div>

              <div v-else-if="link.encryptedLabel" class="leading-4 max-w-80 line-clamp-3">
                <template v-for="(label, i) in link.encryptedLabel" :key="'label-' + i">
                  <span :data-encrypted="label" />
                </template>
              </div>
            </component>

            <div
              v-if="link.subLabel !== null && typeof link.subLabel === 'string'"
              class="text-xs font-normal absolute text-gray-500 whitespace-nowrap"
            >
              {{ link.subLabel }}
            </div>

            <div
              v-else-if="link.subLabel !== null && typeof link.subLabel === 'object'"
              class="text-base font-normal absolute"
            >
              <component :is="link.subLabel.component" v-bind="link.subLabel.props" />
            </div>
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
  import {computed, inject, onBeforeMount, ref} from "vue";
  import {Link as InertiaLink, Head as InertiaHead, usePage} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentRecordLabel from "@pages/Records/Components/RecordLabel.vue";

  export default {
    name: "ComponentBreadcrumb",

    components: {InertiaLink, InertiaHead, ComponentIcon, ComponentRecordLabel},

    props: {
      links: {
        type: Object,
        default: () => {},
      },
    },

    setup(props) {
      const page = usePage();
      const privacy = inject("$privacy");
      const title = ref("");

      const processedLinks = computed(() => {
        return props.links
          .map((link) => {
            if (typeof link === "function") {
              return link();
            }

            return link;
          })
          .filter((link) => link !== null);
      });

      const hasPatientName = computed(() => props.links.some((link) => link?.encryptedLabel));

      const hasInputLabel = computed(() => props.links.some((link) => link.label === "Eingabe"));

      const hasAnalyseLabel = computed(() => props.links.some((link) => link.label === "Analyse"));

      onBeforeMount(() => {
        if (hasPatientName.value) {
          privacy.decryptPatient(page.props.patient).then((decryptPatient) => {
            if (decryptPatient.firstname) {
              title.value += decryptPatient.firstname;
            }

            if (decryptPatient.lastname) {
              title.value += " " + decryptPatient.lastname;
            }

            if (hasInputLabel.value && !hasAnalyseLabel.value) {
              title.value += ", Eingabe";
            } else if (hasInputLabel.value && hasAnalyseLabel.value) {
              title.value += ", Analyse";
            }
          });
        }
      });

      return {
        /** const */
        title,

        /** computed */
        processedLinks,
        hasPatientName,
      };
    },
  };
</script>
