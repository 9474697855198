<template>
  <div
    v-for="check in checks"
    :id="anchorPrefix + '-' + check.mc_check_id"
    :key="check.id"
    :class="[$attrs.class, 'border-1']"
  >
    <div class="bg-gray-100 border-b-1 p-2 flex items-center space-x-2 print:break-after-avoid-page">
      <h4
        :class="{
          'font-bold': checksWithCommunicatedClaims.includes(check.id),
          'font-semibold': !checksWithCommunicatedClaims.includes(check.id),
        }"
      >
        {{ check.checkname }}
      </h4>

      <component-info-icon class="print:hidden">
        <template #content>
          <p v-if="check?.claims[0]?.description" class="max-w-sm">
            <span class="font-semibold">Check Information:</span>
            <span class="font-normal" v-html="check.claims[0].description" />
          </p>
          <p v-else class="max-w-sm">
            <span>Keine Check Information hinterlegt.</span>
          </p>
        </template>
      </component-info-icon>
    </div>

    <!-- claims -->
    <pages-record-result-list-claims
      :patient-id="patientId"
      :record-id="recordId"
      :mc-check-id="check.mc_check_id"
      :claims="check.claims"
      :area="area"
      @open:share-dialog="$emit('open:shareDialog')"
    />
  </div>
</template>

<script>
  import {computed} from "vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import PagesRecordResultListClaims from "@pages/Records/Components/Result/ResultList/ListClaims.vue";
  import {area} from "@pages/Records/Components/Result/Enums.js";

  export default {
    name: "PagesRecordResultListChecks",
    components: {PagesRecordResultListClaims, ComponentInfoIcon},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      anchorPrefix: {
        type: String,
        required: true,
      },
      checks: {
        type: Array,
        required: true,
      },
      area: {
        type: String,
        default: area.prognostic,
      },
    },
    emits: ["open:shareDialog"],
    setup(props) {
      /**
       * Returns an array of check.id from checks, which are communicated
       */
      const checksWithCommunicatedClaims = computed(() => {
        return props.checks
          .map((check) => {
            if (check.claims.some((claim) => claim.communicationdoc || claim.communicationpatient)) {
              return check.id;
            }
            return null;
          })
          .filter((claimId) => claimId !== null);
      });

      return {checksWithCommunicatedClaims};
    },
  };
</script>
