let delayWhileEditingTimeout = null;

export const delayWhileEditing = (refParent, callback) => {
  // check if any input element has currently
  // the focus and if the focussed input
  // element is within refParent
  const activeElement = document.activeElement;
  if (["INPUT", "SELECT", "TEXTAREA"].includes(activeElement.tagName)) {
    if (refParent.value && refParent.value.contains(activeElement)) {
      if (delayWhileEditingTimeout) {
        clearTimeout(delayWhileEditingTimeout);
      }

      delayWhileEditingTimeout = setTimeout(() => {
        delayWhileEditing(refParent, callback);
      }, 1000);

      return;
    }
  }

  // otherwise continue and call callback
  callback();
};
