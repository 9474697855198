<template>
  <div>
    <template v-if="label">
      <span class="duration-300 -z-1 origin-0 truncate cursor-default text-gray-500 text-sm">
        {{ label }}
      </span>
    </template>

    <div
      class="p-2 border-0 border-b-1 border-gray-300 h-9 text-sm truncate"
      :class="{'border-dashed': disabled, 'text-gray-500': disabledText}"
    >
      <template v-if="!value && withPlaceholder">
        {{ placeholder }}
      </template>

      <template v-else>
        <component-tooltip :tooltip="value" placement="top" :delay="2">
          {{ value }}
        </component-tooltip>
      </template>
    </div>

    <template v-if="helperText">
      <div class="flex justify-between cursor-default mt-1 space-x-2">
        <span class="text-xs text-gray-500">{{ helperText }}</span>
      </div>
    </template>
  </div>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentFakeInput",
    components: {ComponentTooltip},

    props: {
      value: {
        type: [String, Number],
        required: true,
      },
      label: {
        type: String,
        default: "",
      },
      helperText: {
        type: String,
        default: "",
      },
      withPlaceholder: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: "-",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledText: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
