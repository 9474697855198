<template>
  <li class="grid grid-cols-[repeat(13,minmax(0,1fr))] gap-x-6 p-2">
    <div class="col-span-3">
      <component-input
        label="Größe in m"
        :model-value="sizeValue"
        :error="sizeError"
        :disabled="fieldsDisabled"
        :validation="sizeError"
        @input="updateSize"
        @clear="updateSize"
      />
    </div>

    <div class="col-span-3">
      <component-input
        label="Gewicht in kg"
        :model-value="weightValue"
        :error="weightError"
        :disabled="fieldsDisabled"
        @input="updateWeight"
        @clear="updateWeight"
      />
    </div>

    <div class="col-span-2">
      <component-input
        v-model="form.bmi.value"
        label="BMI in kg/m²"
        :helper-text="bmiReferenceText"
        :disabled="fieldsDisabled"
        @clear="clearBmi"
      />
    </div>

    <div class="col-span-3">
      <component-select
        :id="'deviation' + form.bmi.id"
        :name="'deviation' + form.bmi.id"
        :model-value="form.bmi.deviation"
        :options="laborbardeviationValues"
        key-name="name"
        value-name="value"
        label="Abweichung:"
        :disabled="fieldsDisabled"
        @change="updateBmiDeviation"
      />
    </div>

    <div class="col-span-2">
      <component-full-date-input
        ref="date"
        label="Von (Datum):"
        :model-value="form.bmi.date"
        :disabled="fieldsDisabled"
        @input="updateBmiDate"
      />
    </div>
  </li>
</template>

<script>
  import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import NumberHelper from "@utils/Helpers/NumberHelper.js";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  import {laborbardeviationValues} from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {
    getReferenceValue,
    getReferenceText,
    getDeviationFromValue,
  } from "@pages/Records/Components/Sections/LaborBar/Utils/laborvalue.js";

  export default {
    name: "MainLaborValuesBmiRow",

    components: {ComponentFullDateInput, ComponentSelect, ComponentInput},

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      size: {
        type: Object,
        required: true,
      },
      weight: {
        type: Object,
        required: true,
      },
      bmi: {
        type: Object,
        required: true,
      },
    },

    emits: ["update"],

    setup(props, {emit}) {
      const page = usePage();

      const weightError = ref("");
      const sizeValue = ref("");

      const form = ref({
        size: props.size ?? {},
        weight: props.weight ?? {},
        bmi: props.bmi ?? {},
      });

      const fieldsDisabled = computed(() => !props.isEditable);

      const sizeError = computed(() => (sizeValue.value[0] >= 3 ? "Keine sinvolle Größe" : ""));

      const weightValue = computed(() => {
        return form.value.weight.value
          ? NumberHelper.formatFloatAsGermanNumberString(NumberHelper.formatStringAsFloat(form.value.weight.value))
          : "";
      });

      const bmiReferenceValue = computed(() => {
        return getReferenceValue(form.value.bmi, page.props.patient.gender);
      });

      const bmiReferenceText = computed(() => {
        if (!bmiReferenceValue.value || !form.value.bmi.value || form.value.bmi.value === "") {
          return null;
        }

        return getReferenceText(bmiReferenceValue.value);
      });

      watch(
        () => form.value.bmi.value,
        (newValue) => {
          if (newValue !== null && form.value.size.value && form.value.weight.value) {
            if (
              newValue !==
              Math.round(
                parseFloat(form.value.weight.value.replace(",", ".")) /
                  Math.pow(parseFloat(form.value.size.value.replace(",", ".")), 2),
              ).toString()
            ) {
              form.value.weight.value = null;
            }
          }

          form.value.bmi.deviation = getDeviationFromValue(
            form.value.bmi.value,
            bmiReferenceValue.value.low,
            bmiReferenceValue.value.high,
          );

          emitUpdate();
        },
      );

      onBeforeMount(() => {
        if (!form.value.bmi || !form.value.bmi.mc_unit_id) {
          form.value.bmi.mc_unit_id = form.value.bmi.core.unitList[0].id;
          form.value.bmi.unit = form.value.bmi.core.unitList[0].unit;
        }
      });

      onMounted(() => {
        sizeValue.value = form.value.size.value
          ? NumberHelper.formatFloatAsGermanNumberString(NumberHelper.formatStringAsFloat(form.value.size.value))
          : "";

        if (form.value.bmi.value === null && form.value.bmi.id === null && form.value.bmi.deviation === "normal") {
          // try to calculate bmi once to force correct bmi/deviation after importing a record
          // (bmi does not exist at this moment, so its .id is null)
          tryToCalculateBmi();

          if (form.value.bmi.value !== null) {
            // save, if bmi has been changed after calling tryToCalculateBmi();
            emitUpdate();
          }
        }
      });

      const tryToCalculateBmi = () => {
        if (form.value.size.value && form.value.weight.value) {
          const size = NumberHelper.formatStringAsFloat(form.value.size.value);
          const weight = NumberHelper.formatStringAsFloat(form.value.weight.value);

          form.value.bmi.value = Math.round(weight / Math.pow(size, 2)).toString();
          form.value.bmi.unit = form.value.bmi.core.unitList[0].unit;
          form.value.bmi.mc_unit_id = form.value.bmi.core.unitList[0].id;
          form.value.bmi.deviation = getDeviationFromValue(
            NumberHelper.formatStringAsFloat(form.value.bmi.value),
            bmiReferenceValue.value.low,
            bmiReferenceValue.value.high,
          );
        }
      };

      const updateSize = (newValue) => {
        if (newValue && newValue.length === 2 && newValue[1] !== ",") {
          sizeValue.value = newValue[0] + "," + newValue[1];
        } else {
          sizeValue.value = newValue ?? "";
        }

        if (sizeError.value.length > 0) {
          return;
        }

        if (newValue) {
          form.value.size.value = newValue.toString().replace("m", "").replace(".", ",").trim();
        } else {
          form.value.size.value = null;
        }

        form.value.size.unit = form.value.size.core.unitList[0].unit;
        form.value.size.mc_unit_id = form.value.size.core.unitList[0].id;

        tryToCalculateBmi();

        emitUpdate();
      };

      const updateWeight = (newValue) => {
        if (sizeError.value.length > 0) {
          return;
        }

        if (newValue >= 400) {
          weightError.value = "Bitte ein sinnvolles Gewicht eingeben.";
          return;
        } else {
          weightError.value = null;
        }

        if (newValue) {
          form.value.weight.value = newValue.toString().replace("kg", "").replace(",", ".").trim();
        } else {
          form.value.weight.value = null;
        }

        form.value.weight.unit = form.value.weight.core.unitList[0].unit;
        form.value.weight.mc_unit_id = form.value.weight.core.unitList[0].id;

        tryToCalculateBmi();

        emitUpdate();
      };

      const clearBmi = () => {
        form.value.bmi.value = null;

        form.value.bmi.unit = form.value.bmi.core.unitList[0].unit;
        form.value.bmi.mc_unit_id = form.value.bmi.core.unitList[0].id;

        form.value.bmi.deviation = getDeviationFromValue(
          form.value.bmi.value,
          bmiReferenceValue.value.low,
          bmiReferenceValue.value.high,
        );

        emitUpdate();
      };

      const updateBmiDeviation = (newBmiDevation) => {
        form.value.bmi.deviation = newBmiDevation;
        form.value.bmi.value = null;
        emitUpdate();
      };

      const updateBmiDate = (newBmiDate) => {
        form.value.bmi.date = newBmiDate;
        emitUpdate();
      };

      const emitUpdate = () => {
        emit("update");
      };

      return {
        /** enums */
        laborbardeviationValues,

        /** const */
        weightError,
        form,
        sizeValue,

        /** computed */
        sizeError,
        fieldsDisabled,
        weightValue,
        bmiReferenceText,

        /** function */
        updateWeight,
        updateSize,
        clearBmi,
        updateBmiDeviation,
        updateBmiDate,
      };
    },
  };
</script>
