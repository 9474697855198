<template>
  <component-input
    v-model="internalValue"
    :label="label"
    type="date"
    :hide-clear="true"
    :encrypted="true"
    :helper-text="age"
    :validation="birthdateValidationMessage || externalValidationMessage"
    :test-id="testId"
    @decrypted="
      $emit('decrypted', $event);
      decryptedOnce($event);
    "
    @input="$emit('update:modelValue', $event)"
    @focus="$emit('focus', $event)"
    @blur="
      validateBirthdate($event.target.value);
      calculateAge($event.target.value);
      $emit('blur', $event);
    "
    @keyup="$emit('keyup', $event)"
    @keydown="$emit('keydown', $event)"
  />
</template>

<script>
  import ComponentInput from "@components/Inputs/Input.vue";
  import {ref, watch} from "vue";

  export default {
    name: "ComponentBirthdate",
    components: {ComponentInput},

    props: {
      modelValue: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: "Geburtsdatum*",
      },
      /**
       * external validation string (e.g. from server side validation)
       */
      validation: {
        type: String,
        default: null,
      },
      dataTest: {
        type: String,
        default: "component-birthdateInput",
      },
    },
    emits: ["blur", "decrypted", "update:modelValue", "focus", "keyup", "keydown"],

    setup(props, {emit}) {
      const internalValue = ref(props.modelValue);
      const externalValidationMessage = ref(props.validation);

      // TODO: watcher is required to always validBirthdate
      //  is initialized with "false" on create
      // and with "true" on update (because we assume, that
      // a valid birthdate has already been stored)
      const validBirthdate = ref(false);
      const birthdateValidationMessage = ref("");
      const age = ref(null);

      const decryptedHasEmitted = ref(false);

      watch(
        () => props.validation,
        (newValue) => {
          externalValidationMessage.value = newValue;
        },
      );

      const decryptedOnce = (value) => {
        if (decryptedHasEmitted.value === false) {
          decryptedHasEmitted.value = true;
          if (value !== null) {
            calculateAge(value);
          }
        }
      };

      const calculateAge = (value) => {
        // calculate the age...
        let d = new Date(value);
        // early exit
        if (isNaN(d.getTime())) return "";

        const today = new Date();

        // calculate age
        let calcAge = today.getFullYear() - d.getFullYear();
        const monthDiff = today.getMonth() - d.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < d.getDate())) {
          calcAge--;
        }
        age.value = calcAge + " Jahre";
      };

      function validateBirthdate(value) {
        if (!value) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          return;
        }

        let year = null;
        let month = null;
        let day = null;

        if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
          // YYYY-MM-DD
          year = value.split("-")[0] ?? null;
          month = value.split("-")[1] ?? null;
          day = value.split("-")[2] ?? null;
        } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
          year = value.split(".")[2] ?? null;
          month = value.split(".")[1] ?? null;
          day = value.split(".")[0] ?? null;
        } else {
          // invalid like "11980-04-30"
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          // do not store an invalid birthdate (forced to null)
          emit("decrypted", null);
          emit("update:modelValue", null);
          return;
        }

        // date is not set at all
        if (!year) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = null; // not set should not show validation message
          // do not store an invalid birthdate (forced to null)
          emit("decrypted", null);
          emit("update:modelValue", null);
          return;
        }

        // do not allow birthdate < 1900
        if (parseInt(year) < 1900) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Bitte geben Sie ein sinnvolles Geburtsdatum ein";
          // do not store an invalid birthdate (forced to null)
          emit("decrypted", null);
          emit("update:modelValue", null);
          return;
        }

        const inputDate = new Date(year, month - 1, day); // month is based on zero
        const now = new Date();
        inputDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // do not allow future dates
        if (inputDate > now) {
          validBirthdate.value = false;
          birthdateValidationMessage.value = "Das Geburtsdatum darf nicht in der Zukunft liegen";
          // do not store an invalid birthdate (forced to null)
          emit("decrypted", null);
          emit("update:modelValue", null);
          return;
        }

        // reset external validation message if internal validation is true
        externalValidationMessage.value = null;
        validBirthdate.value = true;
        birthdateValidationMessage.value = null;
      }

      return {
        /** const */
        internalValue,
        externalValidationMessage,
        birthdateValidationMessage,
        age,

        /** function */
        calculateAge,
        decryptedOnce,
        validateBirthdate,
      };
    },
  };
</script>
