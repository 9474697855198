<template>
  <div :class="{'flex flex-wrap space-x-1.5': inline}">
    <h6 class="text-gray-600" :class="{'font-semibold': inline, 'text-xs uppercase': !inline}">
      Heimpatient{{ inline ? ":" : "" }}
    </h6>
    <div :class="{'mt-0.5 text-sm': !inline, 'flex flex-wrap space-x-1.5': inline}">
      <template v-if="patient.is_fake">
        <div class="text-xs text-gray-600 mb-2">
          Details zur Einrichtung werden bei geteilten Patienten nicht übermittelt.
        </div>
      </template>
      <template
        v-if="
          attribute.payload?.nursing_home_name ||
          attribute.payload?.nursing_home_ward ||
          attribute.payload?.nursing_home_room ||
          attribute.payload?.nursing_home_carer
        "
      >
        <span v-if="attribute.payload?.nursing_home_name">
          <span class="text-gray-500">Name der Pflegeeinrichtung:</span>
          <span :data-encrypted="attribute.payload?.nursing_home_name" />
          <br />
        </span>
        <span v-if="attribute.payload?.nursing_home_ward">
          <span class="text-gray-500">Station:</span>
          <span :data-encrypted="attribute.payload?.nursing_home_ward" />
          &nbsp;
        </span>
        <span v-if="attribute.payload?.nursing_home_room">
          <span class="text-gray-500">Zimmer:</span>
          <span :data-encrypted="attribute.payload?.nursing_home_room" />
        </span>
        <span v-if="attribute.payload?.nursing_home_ward || attribute.payload?.nursing_home_room"><br /></span>
        <span v-if="attribute.payload?.nursing_home_carer">
          <span class="text-gray-500">Zuständige Pflegekraft:</span>
          <span :data-encrypted="attribute.payload?.nursing_home_carer" />
        </span>
      </template>
    </div>
  </div>
</template>

<script>
  import {computed} from "vue";

  export default {
    name: "ComponentPatientSidebarNursingHomeAttribute",
    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
      inline: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {},
  };
</script>
