<template>
  <li class="grid grid-cols-12 gap-x-6 py-2">
    <!-- col 1 -  Erkrankung / ICD-10 / Allergie / Lebensumstand -->
    <div class="col-span-8 items-center w-full h-9">
      <div class="flex">
        <template v-if="isEditable">
          <template v-if="diseaseForm.disease">
            <div class="more icon-wrap flex items-center justify-center">
              <component-tooltip
                placement="right-end"
                tooltip="Zeile verschieben"
                custom-class-label="flex items-center"
                :force-hidden="isDragging"
              >
                <component-icon class="text-gray-900 text-xl mt-0.5 cursor-grab" drag-handle>
                  drag_handle
                </component-icon>
              </component-tooltip>
            </div>
          </template>

          <template v-if="!diseaseForm.disease">
            <div class="flex items-center">
              <component-icon class="text-gray-400 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
            </div>
          </template>
        </template>

        <component-fake-input class="w-full" :value="diseaseForm.disease" :disabled="!isEditable" />
      </div>
    </div>

    <!-- col 2 - Seit(Datum) -->
    <div class="col-span-3">
      <component-full-date-input v-model="diseaseForm.since" :disabled="!isEditable" @input="changeSince" />
    </div>

    <div class="col-span-1 flex m-auto">
      <template v-if="isEditable">
        <component-tooltip placement="left-start" tooltip="Zeile löschen">
          <component-icon-button
            class="text-white bg-gray-500 hover:bg-gray-400 border-gray-500 hover:border-gray-400"
            icon="delete"
            @click="showDeleteConfirmDialog"
          />
        </component-tooltip>
      </template>
    </div>
  </li>
</template>

<script>
  import {inject, ref} from "vue";

  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentFakeInput from "@components/Inputs/FakeInput.vue";

  export default {
    name: "DiseasesTableRow",

    components: {
      ComponentFakeInput,
      ComponentFullDateInput,
      ComponentIcon,
      ComponentIconButton,
      ComponentTooltip,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      disease: {
        type: Object,
        required: true,
      },
      isDragging: {
        type: Boolean,
        required: true,
      },
    },

    emits: ["updateDiseases"],

    setup(props, {emit}) {
      const deleteDialog = inject("recordEditDeleteRowDialog");
      const diseaseForm = ref(props.disease);

      const changeSince = (newValue) => {
        diseaseForm.value.since = newValue;
        emit("updateDiseases");
      };

      const showDeleteConfirmDialog = () => {
        deleteDialog({routeName: "diseases", payload: {disease: diseaseForm.value.id}});
      };

      return {
        /** const */
        diseaseForm,

        /** function */
        changeSince,
        showDeleteConfirmDialog,
      };
    },
  };
</script>
