<template>
  <component-dialog ref="refContextDialog" dialog-title="Warum? / Was?" :is-loading="isLoading">
    <template #content>
      <template v-if="optionList.length === 0">Es wurden keine passenden Optionen gefunden.</template>

      <template v-else>
        <template v-for="(item, index) in optionList" :key="index">
          <div class="flex first:pt-1 pt-5">
            <component-checkbox
              v-model="item.checkbox"
              @change="changeForm({context: item.context, isChecked: $event})"
            >
              {{ item.name }}
            </component-checkbox>
          </div>
        </template>
      </template>
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="Speichern" :disabled="isLoading" @click="patchSymptom" />

      <component-button label="Abbrechen" :disabled="isLoading" @click="closeAndResetDialog" />
    </template>
  </component-dialog>
</template>

<script>
  import {ref} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep} from "lodash";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  export default {
    name: "SymptomContextDialog",

    components: {ComponentButton, ComponentCheckbox, ComponentDialog},

    setup: function () {
      const page = usePage();

      const refContextDialog = ref(null);

      const isLoading = ref(false);
      const optionList = ref(null);
      const symptomForm = useForm({
        value: null,
      });

      const open = (symptom) => {
        symptomForm.value = symptom;

        refContextDialog.value.open();

        setupDialog();
      };

      const close = () => {
        if (refContextDialog.value) {
          refContextDialog.value.close();
        }
      };

      const setupDialog = () => {
        const list = [];

        if (symptomForm.value.context === "fam") {
          page.props.record.medications.map((medication) => {
            if (medication.abdata_key_fam) {
              list.push({
                name: medication.fam,
                checkbox: symptomForm.value.contextvalue.includes(medication.abdata_key_fam),
                context: {
                  type: "fam",
                  mc_context_id: medication.abdata_key_fam,
                },
              });
            }
          });
        } else if (symptomForm.value.context === "diagnosis") {
          page.props.record.diseases.map((disease) => {
            list.push({
              name: disease.disease,
              checkbox: symptomForm.value.contextvalue.includes(disease.abdata_key_miv),
              context: {
                type: "diagnosis",
                mc_context_id: disease.abdata_key_miv,
              },
            });
          });
        } else if (symptomForm.value.context === "laborvalue") {
          page.props.record.laborvalues.map((laborValue) => {
            // 182 = body height - 183 = body weight
            if (
              laborValue.mc_laborvalue_key !== 182 &&
              laborValue.mc_laborvalue_key !== 183 &&
              laborValue.deviation !== "normal"
            ) {
              list.push({
                name: laborValue.type,
                checkbox: symptomForm.value.contextvalue.includes(laborValue.mc_laborvalue_id),
                context: {
                  type: "laborvalue",
                  mc_context_id: laborValue.mc_laborvalue_id,
                },
              });
            }
          });
        }

        optionList.value = list;
      };

      const closeAndResetDialog = () => {
        const rollbackValues = page.props.record.symptoms.find((symptom) => symptom.id === symptomForm.value.id);

        symptomForm.value.symptomcontexts = rollbackValues.symptomcontexts;
        close();
      };

      const patchSymptom = () => {
        symptomForm
          .transform((data) => {
            return data.value;
          })
          .put(
            route("symptoms.update", {
              patient: page.props.patient.id,
              record: page.props.record.id,
              symptom: symptomForm.value.id,
            }),
            {
              preserveScroll: true,
              only: ["record", "flash", "errors"],
              onStart: () => {
                isLoading.value = true;
              },
              onFinish: () => {
                isLoading.value = false;
                close();
              },
            },
          );
      };

      const changeForm = ({context, isChecked}) => {
        let list = Array.isArray(symptomForm.value.symptomcontexts) ? cloneDeep(symptomForm.value.symptomcontexts) : [];

        if (isChecked) {
          list.push(context);
        } else {
          list = list.filter((symptomContext) => symptomContext.mc_context_id !== context.mc_context_id);
        }

        symptomForm.value.symptomcontexts = list;
      };

      return {
        /** ref */
        refContextDialog,

        /** const */
        isLoading,
        optionList,

        /** function */
        open,
        closeAndResetDialog,
        patchSymptom,
        changeForm,
      };
    },
  };
</script>
