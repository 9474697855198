import {router} from "@inertiajs/vue3";

export const addDisease = (patientId, recordId, disease, preserve) => {
  router.post(
    route("diseases.store", {
      patient: patientId,
      record: recordId,
    }),
    {
      abdata_key_miv: disease.Key_MIV,
      disease: disease.Name,
      preserve: typeof preserve === "undefined" ? null : preserve,
    },
    {preserveScroll: true},
  );
};
