<template>
  <div class="border-1 border-mcred text-mcred-700 p-2">
    <div class="cursor-default flex items-center space-x-2 bg-mcred-100 py-3 px-2 -mx-2 -mt-2 border-b-1 border-mcred">
      <component-icon class="text-xl">error_outline</component-icon>
      <h3 class="inline font-semibold">{{ title }}</h3>
      <span class="font-light">(Details s. u.):</span>
    </div>

    <ul class="mt-2 space-y-2 print:break-before-avoid-page">
      <li v-for="check in checks" :key="check.id" class="flex items-start justify-between space-x-2">
        <div class="flex items-start space-x-2">
          <component-icon class="mt-0.5 text-xl">arrow_right</component-icon>
          <component-tooltip :tooltip="check.claims[0].description">
            <a
              :href="getAnchor(check)"
              :data-test="'overviewRedlights-link-claim-check' + check.mc_check_id"
              @click="toggleCheckOpen(check)"
            >
              {{ check.claims[0].claim }}
            </a>
          </component-tooltip>
        </div>
        <div class="flex items-center space-x-1 print:hidden">
          <component-tooltip v-if="check.comment_count > 0" tooltip="Kommentare zwischen Partnern der Fallfreigabe">
            <a
              :href="getAnchor(check)"
              :data-test="'overviewRedlights-link-comment-check' + check.mc_check_id"
              @click="toggleCheckOpen(check)"
            >
              <component-icon :clickable="true">mode_comment</component-icon>
            </a>
          </component-tooltip>

          <component-tooltip v-if="check.confidential_comment_count > 0" tooltip="Rückmeldung des Arztes">
            <a
              :href="getAnchor(check)"
              :data-test="'overviewRedlights-link-commentDoc-check' + check.mc_check_id"
              @click="toggleCheckOpen(check)"
            >
              <component-icon :clickable="true" custom="doctor" />
            </a>
          </component-tooltip>
        </div>
      </li>
      <li v-if="checks.length === 0">Es wurden keine Risiken oder Probleme ermittelt.</li>
    </ul>
  </div>
</template>

<script>
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "PagesRecordResultOverviewRedlights",
    components: {ComponentTooltip, ComponentIcon},
    props: {
      checks: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      anchorPrefix: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      function toggleCheckOpen(check) {
        let element = document.querySelector(getAnchor(check) + " ol li:first-child [role=button]");

        if (element) {
          element.click();
        }
      }

      function getAnchor(check) {
        return "#" + props.anchorPrefix + "-" + check.mc_check_id;
      }

      return {toggleCheckOpen, getAnchor};
    },
  };
</script>
