<template>
  <div
    v-if="showDialog"
    class="backdrop-filter backdrop-blur-lg fixed w-full h-screen z-40 print:hidden"
    :data-test="testId + '-crypt-backdrop'"
  />

  <template v-if="privacy.hasCryptStatus('SERVER_ERROR')">
    <crypt-dialogs-error
      ref="refCryptDialog"
      :privacy="privacy"
      :is-loading="isLoading"
      :test-id="testId + '-crypt-error'"
    />
  </template>

  <template v-if="privacy.hasAccountEducationStatus()">
    <crypt-dialogs-education
      ref="refCryptDialog"
      :privacy="privacy"
      :is-loading="isLoading"
      :logout="logout"
      :test-id="testId + '-crypt-education'"
    />
  </template>

  <template v-else>
    <template
      v-if="
        privacy.hasOneOfCryptStatus([
          'ENCRYPTION_NOT_SET',
          'ENCRYPTION_PENDING',
          'ENCRYPTION_SET',
          'ENCRYPTION_INVALID',
        ])
      "
    >
      <template v-if="posIntegration.alreadyUsingPos === null">
        <crypt-dialogs-is-pos-user
          ref="refCryptDialog"
          :is-loading="isLoading"
          :pos-integration="posIntegration"
          :test-id="testId + '-crypt-is-pos-user'"
        />
      </template>

      <template v-else-if="posIntegration.alreadyUsingPos === false">
        <crypt-dialogs-onboarding
          ref="refCryptDialog"
          :privacy="privacy"
          :is-loading="isLoading"
          :pos-integration="posIntegration"
          :test-id="testId + '-crypt-onboarding'"
        />
      </template>

      <template v-else-if="posIntegration.alreadyUsingPos === true">
        <crypt-dialogs-pos
          ref="refCryptDialog"
          :privacy="privacy"
          :is-loading="isLoading"
          :pos-integration="posIntegration"
          :logout="logout"
          :test-id="testId + '-crypt-pos'"
        />
      </template>
    </template>

    <template v-if="privacy.hasOneOfCryptStatus(['RECOVERY', 'RECOVERY_VERIFICATION_INVALID'])">
      <crypt-dialogs-recovery
        ref="refCryptDialog"
        :privacy="privacy"
        :is-loading="isLoading"
        :logout="logout"
        :test-id="testId + '-crypt-recovery'"
      />
    </template>

    <template v-if="privacy.hasOneOfCryptStatus(['VERIFICATION_PENDING', 'VERIFICATION_INVALID'])">
      <crypt-dialogs-default
        ref="refCryptDialog"
        :privacy="privacy"
        :is-loading="isLoading"
        :logout="logout"
        :test-id="testId + '-crypt-default'"
      />
    </template>
  </template>
</template>

<script>
  import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";

  import CryptDialogsError from "@components/Layout/Components/CryptDialogs/Error.vue";
  import CryptDialogsEducation from "@components/Layout/Components/CryptDialogs/Education.vue";
  import CryptDialogsDefault from "@components/Layout/Components/CryptDialogs/Default.vue";
  import CryptDialogsOnboarding from "@components/Layout/Components/CryptDialogs/Onboarding.vue";
  import CryptDialogsPos from "@components/Layout/Components/CryptDialogs/Pos.vue";
  import CryptDialogsIsPosUser from "@components/Layout/Components/CryptDialogs/IsPosUser.vue";
  import CryptDialogsRecovery from "@components/Layout/Components/CryptDialogs/Recovery.vue";

  export default {
    name: "LayoutDialogCrypt",

    components: {
      CryptDialogsRecovery,
      CryptDialogsIsPosUser,
      CryptDialogsPos,
      CryptDialogsOnboarding,
      CryptDialogsDefault,
      CryptDialogsEducation,
      CryptDialogsError,
    },

    setup() {
      const page = usePage();

      const privacy = inject("$privacy");

      const refCryptDialog = ref(null);

      const showDialog = ref(false);
      const posIntegration = ref({
        alreadyUsingPos: null,
        mergeAccountUid: null,
        validateMergeAccountUid: null,
        baseUrl: import.meta.env.MC_API_DATA_BASE_URL + "/merge-account/",
      });

      const isLoading = computed(() => privacy.getIsLoading());

      watch(
        () => refCryptDialog.value,
        (newValue, oldValue) => {
          if (privacy.hasOneOfCryptStatus(["VERIFICATION_VALID", "RECOVERY_VALID"])) {
            showDialog.value = false;
            if (oldValue) oldValue.close();

            router.reload({only: ["client", "isEditable"]});
          } else {
            if (newValue) newValue.open();
          }
        },
      );

      watch(
        () => privacy.getCurrentStatus(),
        () => {
          if (privacy.hasOneOfCryptStatus(["VERIFICATION_VALID", "RECOVERY_VALID"])) {
            router.reload({only: ["client", "isEditable"]});
            closeDialog();
          }
        },
      );

      onMounted(() => {
        window.addEventListener("focus", checkCrypt);
        window.addEventListener("visibilitychange", () => {
          if (!document.hidden) checkCrypt();
        });

        setInterval(() => {
          checkCrypt();
        }, 30000);
      });

      router.on("navigate", () => {
        checkCrypt();
      });

      onUnmounted(() => {
        if (showDialog.value) {
          closeDialog();
        }

        window.removeEventListener("focus", checkCrypt);
        window.removeEventListener("visibilitychange", () => {
          if (!document.hidden) checkCrypt();
        });
      });

      const checkCrypt = () => {
        // Redirect if your recovery code is correct and you want to set a new privacy password.
        if (privacy.hasCryptStatus("RECOVERY_VALID")) {
          if (!route().current("privacyPassword.show")) {
            router.get(route("privacyPassword.show", {user: page.props.client?.users[0].id}));
          }
          return;
        }

        if (!showDialog.value) {
          privacy.whenCryptReady(() => {
            closeDialog();
          });

          privacy.checkPrivacyVaultPasswordValidity(
            () => {
              closeDialog();
            },
            () => {
              openDialog();
            },
          );
        }
      };

      const logout = () => {
        router.post(
          route("auth.logout"),
          {},
          {
            onSuccess: () => {
              privacy.purgeLocalStorage();
              closeDialog();
            },
          },
        );
      };

      const closeDialog = () => {
        if (refCryptDialog.value) refCryptDialog.value.close();
        showDialog.value = false;
      };

      const openDialog = () => {
        if (refCryptDialog.value) refCryptDialog.value.open();
        showDialog.value = true;
      };

      return {
        /** inject */
        privacy,

        /** ref */
        refCryptDialog,

        /** const */
        showDialog,
        posIntegration,

        /** computed */
        isLoading,

        /** function */
        logout,
      };
    },
  };
</script>
