<template>
  <button
    v-if="iconButton"
    class="p4umc-btn overflow-hidden p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-gray-200"
    :class="{'min-w-24': minWidth, 'whitespace-nowrap': !wrap}"
    role="button"
    :disabled="disabled"
    :data-test="testId + '-button'"
    @click="click"
  >
    <div class="flex items-center">
      <div :class="{'mr-2': hasLabel}">
        <slot />
      </div>

      <span v-if="hasLabel" class="font-medium" :class="{uppercase: isUpperCase}" :data-test="testId + '-button-label'">
        {{ label }}
      </span>
    </div>
  </button>

  <button
    v-else
    class="p4umc-btn p-1 rounded border-2 focus-visible:ring ring-red-500 outline-0 disabled:text-gray-400 disabled:border-gray-200 disabled:bg-gray-200"
    :class="{'min-w-24': minWidth, 'whitespace-nowrap': !wrap}"
    role="button"
    :disabled="disabled"
    :data-test="testId + '-button'"
    @click="click"
  >
    <div :class="{'flex items-center': hasSlot && hasLabel, uppercase: isUpperCase}">
      <span v-if="hasLabel" class="font-medium" :data-test="testId + '-button-label'">{{ label }}</span>
      <slot />
    </div>
  </button>
</template>

<script>
  import {computed} from "vue";

  export default {
    name: "ComponentButton",

    props: {
      label: {
        type: String,
        required: "",
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      iconButton: {
        type: Boolean,
        default: false,
      },
      isUpperCase: {
        type: Boolean,
        default: true,
      },
      minWidth: {
        type: Boolean,
        default: true,
      },
      wrap: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["click"],

    setup(props, {emit, slots}) {
      const hasSlot = computed(() => !!slots.default);
      const hasLabel = computed(() => props.label && props.label.length > 0);

      function click() {
        emit("click");
      }

      return {
        /** computed */
        hasLabel,
        hasSlot,

        /** function */
        click,
      };
    },
  };
</script>
