import {tubePatientTubeModel, tubePatientTubePosition} from "@pages/Patients/Components/PatientAttributes/Enums.js";

// diagnoses enum in git history

export const symptoms = [
  {
    title: "Allgemeines Wohlbefinden",
    items: [
      {
        labelPatient: "Ich fühle mich geschwächt",
        labelCarer: "Der Patient leidet an Schwäche",
        miv: 4796,
      },
      {
        labelPatient: "Ich leide an Müdigkeit",
        labelCarer: "Der Patient leidet an Müdigkeit",
        miv: 3774,
      },
      {
        labelPatient: "Ich leide an Schlafstörungen",
        labelCarer: "Der Patient leidet an Schlafstörungen",
        miv: 2359,
      },
      {
        labelPatient: "Ich habe Probleme mit meinem Gedächtnis",
        labelCarer: "Der Patient weißt Gedächtnisstörungen auf",
        miv: 5151,
      },
      {
        labelPatient: "Ich leide an Angstzuständen",
        labelCarer: "Der Patient leidet an Angstzuständen",
        miv: 4842,
      },
      {
        labelPatient: "Ich habe eine innere Unruhe",
        labelCarer: "Der Patient leidet an innerer Unruhe",
        miv: 1434,
      },
      {
        labelPatient: "Ich nehme bei mir eine gedrückte Stimmung wahr",
        labelCarer: "Der Patient weißt gedrückte Stimmung auf",
        miv: 4857,
      },
    ],
  },
  {
    title: "Kopf/Hals",
    items: [
      {
        labelPatient: "Ich habe trockene Augen",
        labelCarer: "Der Patient leidet an trockenen Augen",
        miv: 1591,
      },
      {
        labelPatient: "Ich habe Ohrgeräusche",
        labelCarer: "Der Patient leidet an Ohrgeräuschen",
        miv: 4279,
      },
      {
        labelPatient: "Ich habe einen trockenen Mund",
        labelCarer: "Der Patient leidet an Mundtrockenheit",
        miv: 4480,
      },
      {
        labelPatient: "Ich habe Schluckbeschwerden",
        labelCarer: "Der Patient hat Schluckbeschwerden",
        miv: 1635,
      },
      {
        labelPatient: "Ich habe häufig Nasenbluten",
        labelCarer: "Der Patient hat regelmäßig/gehäuft Nasenbluten",
        miv: 4440,
      },
    ],
  },
  {
    title: "Herz/Lunge",
    items: [
      {
        labelPatient: "Ich leide an Atemnot",
        labelCarer: "Der Patient leidet an Atemnot",
        miv: 4499,
      },
      {
        labelPatient: "Ich leide an Husten (nicht erkältungsbedingt)",
        labelCarer: "Der Patient leidet an chronischem Husten",
        miv: 4854,
      },
      {
        labelPatient: "Ich leide an Herzbeschwerden",
        labelCarer: "Der Patient leidet an Herzbeschwerden",
        miv: 5723,
      },
    ],
  },
  {
    title: "Haut",
    items: [
      {
        labelPatient: "Ich leide an Juckreiz",
        labelCarer: "Der Patient leidet an Juckreiz",
        miv: 2509,
      },
      {
        labelPatient: "Ich habe eine Neigung zu blauen Flecken",
        labelCarer: "Der Patient hat eine Neigung zu blauen Flecken",
        miv: 5696,
      },
    ],
  },
  {
    title: "Muskulatur/Schwellung/Sturz",
    items: [
      {
        labelPatient: "Ich habe geschwollene Knöchel",
        labelCarer: "Der Patient hat geschwollene Knöchel",
        miv: 5714,
      },
      {
        labelPatient: "Mein Körper / Körperteile zittern",
        labelCarer: "Der Patient zittert",
        miv: 2286,
      },
      {
        labelPatient: "Ich leide an Muskelkrämpfen",
        labelCarer: "Der Patient leidet an Muskelkrämpfen",
        miv: 8408,
      },
      {
        labelPatient: "Ich leide an Schwindel",
        labelCarer: "Der Patient leidet an Schwindel",
        miv: 2358,
      },
      {
        labelPatient: "Ich fühle mich unsicher im Gang",
        labelCarer: "Der Patient hat eine Gangunsicherheit",
        miv: 4777,
      },
      {
        labelPatient: "Ich bin mehrfach gestürzt / ich habe Angst zu stürzen",
        labelCarer: "Der Patient hat eine Sturzneigung",
        miv: 4863,
      },
    ],
  },
  {
    title: "Magen/Darm",
    items: [
      {
        labelPatient: "Ich habe keinen Appetit",
        labelCarer: "Der Patient leidet an Appetitlosgikeit",
        miv: 4751,
      },
      {
        labelPatient: "Ich leide an Übelkeit (nicht infektbedingt)",
        labelCarer: "Der Patient leidet an Übelkeit",
        miv: 2169,
      },
      {
        labelPatient: "Ich habe Magenbeschwerden",
        labelCarer: "Der Patient leidet an Magenbeschwerden",
        miv: 4845,
      },
      {
        labelPatient: "Ich leide an Sodbrennen",
        labelCarer: "Der Patient leidet an Sodbrennen",
        miv: 1402, // war [2145, 1402]
      },
      {
        labelPatient: "Ich leide an Verstopfung",
        labelCarer: "Der Patient leidet an Verstopfung",
        miv: 1575,
      },
      {
        labelPatient: "Ich leide an Durchfall (nicht infektbedingt)",
        labelCarer: "Der Patient leidet an Durchfall",
        miv: 2622, // war [10457, 2622]
      },
    ],
  },
  {
    title: "Unterleib",
    items: [
      {
        labelPatient: "Ich leide an ungewolltem Harnverlust",
        labelCarer: "Der Patient leidet an ungewolltem Harnverlust",
        miv: 2719,
      },
      {
        labelPatient: "Ich habe häufigen Harndrang",
        labelCarer: "Der Patient leidet an häufigem Harndrang",
        miv: 2227,
      },
      {
        labelPatient: "Ich habe sexuelle Probleme",
        labelCarer: "Der Patient leidet an sexuellen Problemen",
        miv: 4773,
      },
    ],
  },
];

export const circumstancesPatient = [
  {
    label: "Ich rauche",
    miv: 530,
    testId: "smoke",
  },
  {
    label: "Ich stille",
    miv: 532,
    gender: "female",
    testId: "breastfeed",
  },
  {
    label: "Ich bin schwanger",
    miv: 531,
    gender: "female",
    testId: "pregnant",
  },
  {
    label: "Ich bekomme eine Dialyse",
    miv: 535,
    testId: "dialysis",
  },
  {
    label: "Ich befinde mich in Palliativversorgung",
    key: "palliativePatient",
    testId: "palliative-patient",
  },
  {
    label: "Ich habe eine Sonde",
    key: "tubePatient",
    attributeOptions: [
      // {attribute: "tube_model", type: "select", label: "Sondenart", options: tubePatientTubeModel},
      {attribute: "tube_position", type: "select", label: "Endlage", options: tubePatientTubePosition},
      // {attribute: "tube_diameter", type: "number", label: "Außendurchmesser der Sonde in Charrière"},
    ],
    testId: "tube-patient",
  },
  {
    label: "Ich habe einen Port",
    key: "port",
    testId: "port",
  },
  {
    label: "Ich habe eine Insulinpumpe",
    key: "insulinPump",
    testId: "insulin-pump",
  },
  {
    label: "Ich habe einen Herzschrittmacher",
    miv: 534,
    testId: "pacemaker",
  },
];

export const circumstancesCarer = [
  {
    label: "Der Patient zerbeißt alle Arzneimittel",
    key: "bitesMedicines",
    testId: "bites-medicines",
  },
  {
    label: "Der Patient verweigert die Einnahme der Arzneimittel",
    key: "refusesMedications",
    testId: "refuses-medications",
  },
  {
    label: "Der Patient hat Schluckstörungen",
    key: "swallowingDifficulties",
    testId: "swallowing-difficulties",
  },
  {
    label: "Der Patient hat eine Sonde",
    key: "tubePatient",
    attributeOptions: [
      // {
      //   attribute: "tube_model",
      //   type: "select",
      //   label: "Sondenart",
      //   options: tubePatientTubeModel,
      // },
      {attribute: "tube_position", type: "select", label: "Endlage", options: tubePatientTubePosition},
    ],
    testId: "tube-patient",
  },
  {
    label: "Der Patient hat ein Stoma",
    key: "stoma",
    testId: "stoma",
  },
  {
    label: "Der Patient hat eine Insulinpumpe",
    key: "insulinPump",
    testId: "insulin-pump",
  },
  {
    label: "Der Patient hat einen Herzschrittmacher",
    miv: 534,
    testId: "pacemaker",
  },
  {
    label: "Der Patient bekommt eine Dialyse",
    miv: 535,
    testId: "dialysis",
  },
];

export const mobility = [
  {
    titleCarer: "Stock/Gehilfe",
    key: "stick",
  },
  {
    titleCarer: "Rollator",
    key: "rollator",
  },
  {
    titleCarer: "Rollstuhl",
    key: "wheelchair",
  },
  {
    titleCarer: "bettlägrig",
    key: "bedridden",
  },
];

export const wishesAims = {
  compliantImprovement: "Eine Verbesserung von Beschwerden.",
  performanceIncrease: "Eine Steigerung meiner Leistungsfähigkeit (z. B. beim Treppensteigen oder Sport).",
  medicationRelief: "Seltenere Anwendung von Medikamenten.",
  lessMedications: "Die Anzahl der Medikamente verringern.",
  saferFeeling: "Mich sicherer fühlen durch die Überprüfung der Medikamente.",
  medicationKnowledge: "Besser wissen, wofür ich meine Medikamente einnehme.",
  usageKnowledge: "Besser wissen, wie ich meine Medikamente korrekt einnehme/anwende.",
  illnessKnowledge: "Besser informiert sein über meine Erkrankungen.",
};
