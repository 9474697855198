<template>
  <span>
    <span
      ref="refTriggerElement"
      :class="[{'w-full inline-block': !forceInline}, customClassLabel]"
      :aria-describedby="`tooltip-${id}`"
      @mouseenter="mouseEnter()"
      @mouseleave="mouseLeave()"
    >
      <slot />
    </span>

    <span
      v-if="!internalForceHidden"
      :id="`tooltip-${id}`"
      ref="refFloatingElement"
      class="max-w-sm rounded-md shadow-sm w-max absolute top-0 left-0 m-0 p-0 overflow-visible"
      role="tooltip"
      popover
    >
      <span
        class="relative block rounded-md z-10 py-1 px-2 font-medium text-white bg-gray-500 whitespace-normal max-h-96 overflow-y-auto"
        :class="customClass"
        :data-test="testId + '-tooltip'"
        @mouseover="mouseOver()"
        @mouseleave="mouseLeave()"
      >
        <slot name="tooltip">
          <div v-html="tooltip" />
        </slot>
      </span>

      <span ref="refArrowElement" class="z-0 absolute bg-gray-500 w-2 h-2 rotate-45" :class="customClass" />
    </span>
  </span>
</template>

<script>
  import {nextTick, onBeforeMount, ref, watch} from "vue";
  import {computePosition, arrow, offset, flip, shift} from "@floating-ui/vue";

  export default {
    name: "ComponentTooltip",

    props: {
      placement: {
        type: String,
        default: "top",
        // top, top-start, top-end
        // right, right-start, right-end
        // bottom, bottom-start, bottom-end
        // left, left-start, left-end
      },
      tooltip: {
        type: String,
        default: null,
      },
      /**
       * do not show tooltip at all
       */
      forceHidden: {
        type: Boolean,
        default: false,
      },
      forceInline: {
        type: Boolean,
        default: true,
      },
      customClass: {
        type: String,
        default: null,
      },
      customClassLabel: {
        type: String,
        default: "",
      },
      holdOnMouseover: {
        type: Boolean,
        default: false,
      },
      delay: {
        type: Number,
        default: 1,
      },
    },

    setup(props) {
      const refArrowElement = ref(null);
      const refFloatingElement = ref(null);
      const refTriggerElement = ref(null);

      const id = ref(null);
      const timeout = ref(null);
      const internalForceHidden = ref(props.forceHidden);

      watch(
        () => props.forceHidden,
        (newValue) => {
          internalForceHidden.value = newValue;
        },
      );

      const calculate = () => {
        computePosition(refTriggerElement.value, refFloatingElement.value, {
          placement: props.placement, // << preferred placement
          middleware: [offset(12), flip({}), shift({padding: 10}), arrow({element: refArrowElement})],
        }).then(({x, y, middlewareData, placement}) => {
          Object.assign(refFloatingElement.value.style, {
            left: `${x}px`,
            top: `${y}px`,
          });

          // "placement" is the actual placement regarding flip
          const side = placement.split("-")[0];

          const staticSide = {
            top: "bottom",
            right: "left",
            bottom: "top",
            left: "right",
          }[side];

          if (middlewareData.arrow) {
            Object.assign(refArrowElement.value.style, {
              left: middlewareData?.arrow?.x != null ? `${middlewareData?.arrow.x}px` : "",
              top: middlewareData?.arrow?.y != null ? `${middlewareData?.arrow.y}px` : "",
              [staticSide]: `${-refArrowElement.value.offsetWidth / 2}px`,
            });
          }
        });
      };

      onBeforeMount(() => {
        id.value = Math.random().toString(36).slice(4);
      });

      const mouseEnter = () => {
        if (timeout.value) {
          clearTimeout(timeout.value);
        }

        if (internalForceHidden.value) {
          return;
        }

        timeout.value = setTimeout(() => {
          if (refFloatingElement.value) {
            refFloatingElement.value.showPopover();
            nextTick(() => {
              calculate();
            });
          }
        }, 400 * props.delay);
      };

      const mouseLeave = () => {
        if (timeout.value) {
          clearTimeout(timeout.value);
        }

        if (internalForceHidden.value) {
          return;
        }

        timeout.value = setTimeout(() => {
          refFloatingElement.value?.hidePopover();
        }, 400);
      };

      const mouseOver = () => {
        if (props.holdOnMouseover && timeout.value) {
          clearTimeout(timeout.value);
        }
      };

      return {
        /** ref */
        refArrowElement,
        refFloatingElement,
        refTriggerElement,

        /** const */
        id,
        internalForceHidden,

        /** function */
        mouseEnter,
        mouseLeave,
        mouseOver,
      };
    },
  };
</script>
