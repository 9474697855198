<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium" :test-id="testId">
    <template #title>
      <span class="hidden print:inline">MediCheck</span>
      Datenschutzpasswort
    </template>

    <template #content>
      <div class="print:hidden">
        <template v-if="isLoading">
          <div class="text-center">
            <component-spinner class="h-12 w-12 mt-8" :test-id="testId + '-dialog'" />
          </div>
        </template>

        <template v-else-if="privacy.hasCryptStatus('ENCRYPTION_SET')">
          <p class="flex items-center space-x-3">
            <component-icon>check</component-icon>
            <span>Ihr Datenschutzpasswort wurde festgelegt</span>
          </p>

          <p class="mt-4">
            Bitte
            <strong>notieren Sie sich jetzt Ihr Passwort</strong>
            und hinterlegen Sie es mit dem ausgedruckten Recovery-Code an einem sicheren Ort.
          </p>

          <p class="mt-4">
            Bei Verlust Ihres Datenschutzpassworts sind die personenbezogenen Daten Ihrer Patienten irreversibel
            verloren. Auch Mitarbeiter von pharma4u (MediCheck) haben keine technische Möglichkeit, Ihre lokal
            verschlüsselten Daten zu lesen oder wiederherzustellen.
          </p>
        </template>

        <template v-else>
          <p>
            Um personenbezogene Daten Ihrer Patienten verschlüsselt an MediCheck zu übertragen, legen Sie nun bitte
            <strong>einmalig</strong>
            Ihr Datenschutzpasswort fest.
          </p>

          <form autocomplete="off" @submit.prevent="confirmPassword" @keydown.enter.prevent="confirmPassword">
            <component-input
              v-model="passwordPlaintext"
              class="mt-4"
              type="password"
              label="Passwort"
              autocomplete="off"
              :validation="privacy.getErrorMessage()"
              autofocus
              :maxlength="64"
              :show-maxlength-count="false"
              :show-max-length-message="'Die maximal mögliche Zeichenanzahl beträgt 64 Zeichen.'"
              :test-id="testId + '-dialog-password-plaintext'"
              @blur="checkOnMinLength"
            />

            <div class="text-xs text-gray-500 mb-2">
              Mind. 8 Zeichen.
              <br />
              Mind. Passwortstärke mittel (orange)
            </div>

            <component-password-strength
              v-show="strengthCheckerLoaded && passwordPlaintext.length > 0"
              :password="passwordPlaintext"
              @loaded="strengthCheckerLoaded = true"
              @score-changed="passwordStrength = $event"
            />

            <template v-if="isPasswordStrongEnough">
              <component-input
                v-model="passwordConfirmation"
                class="mt-8"
                type="password"
                label="Passwort wiederholen"
                autocomplete="off"
                :validation="passwordConfirmationErrorMsg"
                :maxlength="64"
                :show-maxlength-count="false"
                :show-max-length-message="'Die maximal mögliche Zeichenanzahl beträgt 64 Zeichen.'"
                :test-id="testId + '-dialog-password-confirmation'"
              />
            </template>
          </form>

          <p class="mt-8">
            <!-- TODO: remove line for multi account feature -->
            Beachten Sie, dass dieses Passwort allen Personen bekannt sein muss, die mit MediCheck arbeiten.
          </p>

          <p class="mt-4">
            Notieren Sie sich das Passwort und
            <strong>drucken Sie den Recovery-Code aus</strong>
            . Hinterlegen Sie beides an einem sicheren Ort.
            <br />
            Bei einem Verlust Ihres Datenschutzpassworts sind die personenbezogenen Daten Ihrer Patienten irreversibel
            verloren. Auch Mitarbeiter von pharma4u (MediCheck) haben keine technische Möglichkeit, Ihre lokal
            verschlüsselten Daten zu lesen oder wiederherzustellen.
          </p>
        </template>
      </div>

      <layout-print-recovery-code :recovery-code="privacy.getRecoveryCode()" :test-id="testId + '-dialog'" />
    </template>

    <template #actions>
      <template v-if="privacy.hasCryptStatus('ENCRYPTION_SET')">
        <component-button
          :disabled="!valid"
          class="p4umc-primary"
          label="OK, verstanden"
          :icon-button="true"
          :test-id="testId + '-dialog-close-success-dialog'"
          @click="closeSuccessDialog()"
        >
          <component-icon class="text-xl">lock</component-icon>
        </component-button>
      </template>

      <template v-if="privacy.hasOneOfCryptStatus(['ENCRYPTION_NOT_SET', 'ENCRYPTION_INVALID'])">
        <component-button
          :disabled="!valid || !validPasswordConfirmation || !privacy.getRecoveryCodeHasBeenPrinted()"
          :class="{'p4umc-primary': privacy.getRecoveryCodeHasBeenPrinted()}"
          label="Passwort festlegen"
          :icon-button="true"
          :test-id="testId + '-dialog-confirm-password'"
          @click="confirmPassword()"
        >
          <component-icon class="text-xl">lock_open</component-icon>
        </component-button>

        <component-button
          :disabled="!valid || !validPasswordConfirmation"
          :class="{'p4umc-primary': !privacy.getRecoveryCodeHasBeenPrinted()}"
          label="Drucken"
          :icon-button="true"
          :test-id="testId + '-dialog-print-recovery-code'"
          @click="printRecoveryCode()"
        >
          <component-icon v-if="!privacy.getRecoveryCodeHasBeenPrinted()" class="text-xl">print</component-icon>
          <component-icon v-if="privacy.getRecoveryCodeHasBeenPrinted()" class="text-xl">check</component-icon>
        </component-button>

        <!-- has to be positioned absolute because we do not have offset cancel buttons by default -->
        <component-button
          class="p4umc-flat absolute left-4"
          label="zurück"
          :test-id="testId + '-dialog-back'"
          @click="posIntegration.alreadyUsingPos = null"
        />
      </template>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref, watch} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentPasswordStrength from "@components/PasswordStrength/PasswordStrength.vue";

  import LayoutPrintRecoveryCode from "@components/Layout/Components/PrintRecoveryCode.vue";
  import {router} from "@inertiajs/core";

  export default {
    name: "CryptDialogsOnboarding",

    components: {
      ComponentPasswordStrength,
      ComponentSpinner,
      ComponentIcon,
      ComponentInput,
      ComponentButton,
      ComponentDialog,
      LayoutPrintRecoveryCode,
    },

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
      isLoading: {
        type: Boolean,
        required: true,
        default: false,
      },
      posIntegration: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    setup(props) {
      const refCryptDialog = ref(null);

      const passwordPlaintext = ref("");
      const passwordConfirmation = ref("");
      const passwordConfirmationErrorMsg = ref("");

      const strengthCheckerLoaded = ref(false);
      const passwordStrength = ref(0);

      const valid = computed(() => validPasswordMinLength.value && !props.privacy.getErrorMessage() !== null);

      const validPasswordConfirmation = computed(() => passwordConfirmationErrorMsg.value === null);

      const validPasswordMinLength = computed(
        () => passwordPlaintext.value.trim().length >= props.privacy.getPasswordMinLength(),
      );

      const isPasswordStrongEnough = computed(() => passwordStrength.value >= import.meta.env.VITE_PASSWORD_STRENGTH);

      watch(
        () => passwordPlaintext.value,
        (newValue) => {
          comparePasswords();
          props.privacy.plaintextPassword(newValue);
        },
      );

      watch(
        () => passwordConfirmation.value,
        () => {
          comparePasswords();
        },
      );

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        if (refCryptDialog.value) refCryptDialog.value.close();
      };

      const closeSuccessDialog = () => {
        props.privacy.setCurrentStatus("VERIFICATION_VALID");
        router.visit(route("dashboard.index"));
        close();
      };

      const checkOnMinLength = () => {
        if (validPasswordMinLength.value === false) {
          props.privacy.setErrorMessage("Ihr Passwort muss mindestens 8 Zeichen lang sein.");
          props.privacy.setCurrentStatus("ENCRYPTION_INVALID");
        }
      };

      const confirmPassword = () => {
        if (!valid.value) {
          props.privacy.setErrorMessage("Ihr Passwort muss mindestens 8 Zeichen lang sein.");
          props.privacy.setCurrentStatus("ENCRYPTION_INVALID");
        } else if (validPasswordConfirmation.value && props.privacy.getRecoveryCodeHasBeenPrinted()) {
          // double check: only set password, if confirmation
          // is set and recovery code has been printed
          props.privacy.setPassword();
        }
      };

      const comparePasswords = () => {
        if (validPasswordMinLength.value && passwordConfirmation.value === "") {
          passwordConfirmationErrorMsg.value = "Bitte wiederholen Sie Ihre Eingabe.";
        } else if (validPasswordMinLength.value && passwordPlaintext.value !== passwordConfirmation.value) {
          passwordConfirmationErrorMsg.value = "Passwort stimmt nicht überein.";
        } else {
          passwordConfirmationErrorMsg.value = null;
        }
      };

      const printRecoveryCode = () => {
        window.print();
        props.privacy.setRecoveryCodeHasBeenPrinted(true);
      };

      return {
        /** ref */
        refCryptDialog,

        /** const */
        passwordPlaintext,
        passwordConfirmation,
        passwordConfirmationErrorMsg,
        strengthCheckerLoaded,
        passwordStrength,

        /** computed */
        valid,
        validPasswordConfirmation,
        isPasswordStrongEnough,

        /** function */
        open,
        close,
        closeSuccessDialog,
        checkOnMinLength,
        confirmPassword,
        printRecoveryCode,
      };
    },
  };
</script>
