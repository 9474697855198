<template>
  <component-breadcrumb
    class="mt-2"
    :links="[
      {
        label: 'Patienten',
        url: patient.is_fake ? $route('patients.index', {filter: 'shared-with-me'}) : $route('patients.index'),
        testId: 'patient-index',
      },
      {encryptedLabel: [patient.firstname, patient.lastname]},
    ]"
  />

  <section class="relative mt-6 flex items-start space-x-6">
    <div class="sticky top-20 z-10">
      <component-patient-sidebar :open="true" :patient="patient" :test-id="$page.props.testId" />
    </div>

    <article
      class="flex-1 flex items-start flex-col space-y-6 2xl:items-start 2xl:flex-row 2xl:space-y-0 2xl:space-x-6"
    >
      <!-- article::main -->
      <div class="w-full p-4 bg-white shadow-sm">
        <div class="flex items-center justify-between">
          <h2 v-if="patient.is_fake" class="text-2xl font-semibold">Geteilte Medikationsanalyse</h2>
          <h2 v-else class="text-2xl font-semibold">Medikationsanalysen</h2>

          <component-dropdown
            v-if="$page.props.can.create_records && !patient.is_fake"
            class="bg-gray-100 p-px rounded"
            :test-id="$page.props.testId"
          >
            <template #button>
              <span
                class="flex items-center p4umc-btn p4umc-primary uppercase p-1 font-semibold rounded border-2 whitespace-nowrap"
              >
                <component-icon class="text-2xl mr-2 font-normal">add</component-icon>
                <span>Neue Analyse</span>
                <component-icon class="ml-1">expand_more</component-icon>
              </span>
            </template>

            <template #dropdown>
              <ul class="flex-row space-y-px">
                <li>
                  <component-button
                    class="p4umc-primary p4umc-flat w-full rounded-none"
                    label="Medikationsanalyse manuell eingeben"
                    :icon-button="true"
                    :is-upper-case="false"
                    :test-id="$page.props.testId + '-create-poly-medication'"
                    @click="createPolymedication()"
                  >
                    <component-icon>list_alt</component-icon>
                  </component-button>
                </li>

                <li>
                  <component-button
                    class="p4umc-primary p4umc-flat w-full rounded-none"
                    label="Medikationsanalyse aus BMP importieren"
                    :icon-button="true"
                    :is-upper-case="false"
                    :test-id="$page.props.testId + '-open-bmp-import-dialog'"
                    @click="openBmpImportDialog()"
                  >
                    <component-icon>barcode_reader</component-icon>
                  </component-button>
                </li>

                <li v-if="$page.props.can.json_import">
                  <component-button
                    class="p4umc-primary p4umc-flat w-full rounded-none"
                    label="Medikationsanalyse aus JSON-Datei importieren"
                    :icon-button="true"
                    :is-upper-case="false"
                    :test-id="$page.props.testId + '-open-json-import-dialog'"
                    @click="openJsonImportDialog()"
                  >
                    <component-icon>description</component-icon>
                  </component-button>
                </li>
              </ul>
            </template>
          </component-dropdown>
        </div>

        <!-- filter :: start -->
        <form
          v-if="!patient.is_fake && (records.data.length > 0 || form.flags.length > 0)"
          @submit.prevent="submitFilter"
        >
          <div class="mt-4 flex justify-end items-baseline">
            <component-filter-flags
              v-model="form.flags"
              :available-flags="availableFlags"
              :test-id="$page.props.testId"
            />
          </div>
        </form>
        <!-- filter :: end -->

        <!-- table listing :: start -->
        <div class="mt-4 pt-4">
          <table v-if="records.data.length" class="table-auto w-full">
            <thead>
              <patients-document-head v-model="form.order" :test-id="$page.props.testId" />
            </thead>

            <tbody>
              <template v-if="form.processing">
                <tr>
                  <td colspan="5">
                    <div class="w-full grid place-content-center min-h-32">
                      <component-spinner class="h-8 w-8" :test-id="$page.props.testId" />
                    </div>
                  </td>
                </tr>
              </template>

              <template v-else>
                <!-- record rows -->
                <patients-document-record-row
                  v-for="(record, index) in records.data"
                  :key="'record-' + record.id"
                  :patient="patient"
                  :record="record"
                  :test-id="$page.props.testId + '-' + index"
                  @open:share-record="openShareRecord(record)"
                  @open:share-status="openShareStatus(record)"
                  @confirm:delete-item="confirmDeleteItem(record)"
                  @open:edit-record-label="openEditRecordLabel(record)"
                />
              </template>
            </tbody>
          </table>

          <template v-else>
            <component-empty-state v-if="form.flags.length" :test-id="$page.props.testId">
              Für den aktuellen Filter wurden keine Medikationsanalysen gefunden.
            </component-empty-state>

            <component-empty-state v-else class="mr-10 max-w-[38rem]" :test-id="$page.props.testId">
              <div class="flex items-start">
                <div class="space-y-4">
                  <p>
                    Legen Sie über den Button
                    <span class="font-semibold">„Neue Analyse“</span>
                    eine erste Medikationsanalyse für diesen Patienten an.
                  </p>
                  <p>
                    Sie können eine neue Medikationsanalyse
                    <a href="#" @click.prevent="createPolymedication()">manuell eingeben</a>
                    oder einen vorliegenden
                    <a href="#" @click.prevent="openBmpImportDialog()">BMP importieren.</a>
                  </p>
                </div>
                <div class="relative -top-10 fill-blue-900">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xml:space="preserve"
                    viewBox="0 0 100 100"
                    class="rotate-90 size-32"
                  >
                    <path
                      d="M5.78 13.88c3.89-.42 7.89.35 11.76.77l11.91 1.32c1.31.14 2.82 3.76 1.07 3.78-.29 0-.57 0-.86.01-.2 0-.4-.06-.6-.16l-10.27-1.14c-2.27-.25-4.58-.62-6.89-.79 26.97 9.44 53.72 22.63 71.31 45.91 4.78 6.33 8.75 13.24 11.63 20.64.26.68.34 1.88-.6 2.03-.93.15-1.76-1.07-2.04-1.78-10.34-26.5-34.56-44.05-59.69-55.27-6.59-2.94-13.34-5.51-20.16-7.89 1.72 1.81 3.43 3.62 5.15 5.42.55.58 1.08 1.93.52 2.65-.61.8-1.59 0-2.06-.49-2.16-2.28-4.32-4.55-6.48-6.83-1.87-1.97-3.96-3.87-4.45-6.67-.1-.57.01-1.43.75-1.51z"
                    />
                  </svg>
                </div>
              </div>
            </component-empty-state>
          </template>
          <!-- Paginator -->
          <component-pagination
            v-if="!form.processing && records.meta"
            :links="records.meta.links"
            :test-id="$page.props.testId"
          />
        </div>
        <!-- table listing :: end -->
      </div>

      <!-- article::aside -->
      <div class="w-full grid gap-6 grid-cols-1 md:grid-cols-2 2xl:w-96 2xl:grid-cols-1">
        <section v-if="$page.props.can.view_todos" class="bg-white shadow-sm p-4">
          <component-todo-list :patient="patient" :test-id="$page.props.testId" />
        </section>
      </div>
    </article>
  </section>

  <component-record-share-dialog ref="refShareRecordDialog" :patient="patient" :test-id="$page.props.testId" />

  <component-record-share-status-dialog ref="refShareStatusDialog" :patient="patient" :test-id="$page.props.testId" />

  <component-confirmation-dialog
    ref="refConfirmDeleteItem"
    :title="confirmDeleteTitle"
    :content="confirmDeleteContent"
    :test-id="$page.props.testId"
    @confirmed="(record) => remove(record)"
  />

  <component-bmp-import-dialog ref="refBmpImportDialog" :test-id="$page.props.testId" />

  <component-json-import-dialog
    ref="refJsonImportDialog"
    title="JSON-Datei importieren"
    :test-id="$page.props.testId"
  />

  <component-record-edit-label-dialog ref="refEditRecordLabelDialog" :test-id="$page.props.testId" />
</template>

<script>
  import {inject, onBeforeUnmount, onMounted, ref, watch} from "vue";
  import {debounce} from "lodash";
  import {router, useForm, usePage} from "@inertiajs/vue3";

  import ComponentBmpImportDialog from "@components/Dialogs/BmpImportDialog/BmpImportDialog.vue";
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentDropdown from "@components/Dropdown/Dropdown.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentJsonImportDialog from "@components/Dialogs/JsonImportDialog.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import ComponentPatientSidebar from "@components/Sidebars/PatientSidebar.vue";
  import ComponentRecordEditLabelDialog from "@components/Dialogs/RecordEditLabelDialog.vue";
  import ComponentRecordShareDialog from "@components/Dialogs/RecordShareDialog.vue";
  import ComponentRecordShareStatusDialog from "@components/Dialogs/RecordShareStatusDialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTodoList from "@components/Lists/TodoList.vue";

  import PatientsDocumentHead from "@pages/Patients/Components/DocumentHead.vue";
  import PatientsDocumentRecordRow from "@pages/Patients/Components/DocumentRecordRow.vue";

  export default {
    name: "PagesPatientShow",

    components: {
      ComponentBmpImportDialog,
      ComponentBreadcrumb,
      ComponentButton,
      ComponentConfirmationDialog,
      ComponentDropdown,
      ComponentEmptyState,
      ComponentFilterFlags,
      ComponentIcon,
      ComponentJsonImportDialog,
      ComponentPagination,
      ComponentPatientSidebar,
      ComponentRecordEditLabelDialog,
      ComponentRecordShareDialog,
      ComponentRecordShareStatusDialog,
      ComponentSpinner,
      ComponentTodoList,
      PatientsDocumentHead,
      PatientsDocumentRecordRow,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      records: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");

      const page = usePage();

      const refBmpImportDialog = ref(null);
      const refConfirmDeleteItem = ref(null);
      const refEditRecordLabelDialog = ref(null);
      const refJsonImportDialog = ref(null);
      const refShareRecordDialog = ref(null);
      const refShareStatusDialog = ref(null);

      const confirmDeleteTitle = ref("");
      const confirmDeleteContent = ref("");

      let form = useForm({
        order: page.props?.filters?.order || "-updated_at",
        flags: page.props?.filters?.flags || [],
      });

      const availableFlags = {
        progress: {
          label: "Fortschritt",
          nullable: false,
          options: {
            formFilled: "Eingabe in Arbeit",
            resultCreated: "Analyse durchgeführt",
            resultSent: "Dokumentation vorhanden",
          },
        },
        status: {
          label: "Status",
          nullable: false,
          options: {
            default: "offen",
            inProgress: "in Bearbeitung",
            completed: "abgeschlossen",
            discarded: "irrelevant",
          },
        },
        createdWithinMonth: "innerhalb eines Monats erstellt",
        createdWithinYear: "innerhalb eines Jahres erstellt",
        createdOverAYear: "vor über einem Jahr erstellt",
      };

      watch(
        () => form.flags,
        debounce(function () {
          submitFilter();
        }, 1000),
      );

      watch(
        () => form.order,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      onMounted(() => {
        broadcast.onMessage(handleBroadcastMessage);
      });

      onBeforeUnmount(() => {
        broadcast.offMessage(handleBroadcastMessage);
      });

      function createPolymedication() {
        router.post(route("records.store", {patient: props.patient.id}));
      }

      async function openBmpImportDialog() {
        refBmpImportDialog.value.open(await privacy.decryptPatient(props.patient));
      }

      async function openJsonImportDialog() {
        refJsonImportDialog.value.open(await privacy.decryptPatient(props.patient));
      }

      const handleBroadcastMessage = (data) => {
        if (data.action === "reload.records") {
          router.reload();
        }
      };

      function submitFilter() {
        form.get(route("patients.show", {patient: props.patient.id}), {
          preserveState: true,
          preserveScroll: true,
          replace: true,
        });
      }

      function confirmDeleteItem(record) {
        confirmDeleteTitle.value = "Medikationsanalyse löschen?";
        confirmDeleteContent.value = "Die Medikationsanalyse wird mit ggf. vorhandener Analyse gelöscht.";
        refConfirmDeleteItem.value.open(record);
      }

      function remove(record) {
        // delete record
        router.delete(route("records.destroy", {patient: props.patient.id, record: record.id}), {
          preserveScroll: true,
          onSuccess: () => {
            // broadcast specific delete event
            broadcast.record.postMessage(record.id, {
              action: "delete",
            });
            // reload list of records
            broadcast.postMessage({action: "reload.records"});
          },
        });
      }

      function openShareRecord(record) {
        privacy.whenCryptReady(() => {
          return privacy
            .getCryptObject()
            .decrypt(record.info)
            .then((decrypted) => {
              record.info = decrypted;
              refShareRecordDialog.value.open(record);
            });
        });
      }

      function openShareStatus(record) {
        privacy.whenCryptReady(() => {
          return privacy
            .getCryptObject()
            .decrypt(record.info)
            .then((decrypted) => {
              record.info = decrypted;
              refShareStatusDialog.value.open(record);
            });
        });
      }

      function openEditRecordLabel(record) {
        refEditRecordLabelDialog.value.open(record);
      }

      return {
        /** ref */
        refBmpImportDialog,
        refConfirmDeleteItem,
        refEditRecordLabelDialog,
        refJsonImportDialog,
        refShareRecordDialog,
        refShareStatusDialog,

        /** const */
        confirmDeleteTitle,
        confirmDeleteContent,
        form,
        availableFlags,

        /** function */
        confirmDeleteItem,
        remove,
        createPolymedication,
        submitFilter,
        openBmpImportDialog,
        openJsonImportDialog,
        openShareRecord,
        openShareStatus,
        openEditRecordLabel,
      };
    },
  };
</script>
