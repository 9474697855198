import {createApp, h, ref, nextTick} from "vue";
import {createInertiaApp} from "@inertiajs/vue3";
import {router} from "@inertiajs/core";
import {ZiggyVue} from "ziggy-js";
import Axios from "axios";
import {debounce} from "lodash";

import {startActivityTracking, restartActivityTracking} from "@pharma4u/medicheck-activity-tracker";

import Layout from "@components/Layout/Layout.vue";
import hasThirdParty from "@utils/Helpers/HasThirdParty";
import Broadcast from "@utils/Helpers/Broadcast";
import PrivacyVault from "@utils/PrivacyVault";
import ClickOutsideDirective from "@utils/Directives/ClickOutside.js";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/vue";

createInertiaApp({
  /** TODO: add progressbar */
  progress: {
    color: "#b71c1c",
  },

  resolve: async (name) => {
    const pages = await import.meta.glob("./Pages/**/*.vue", {eager: true});
    let page = pages[`./Pages/${name}.vue`];

    if (name === "Error/Show") {
      return page;
    }

    page.default.layout = page.default.layout || Layout;

    restartActivityTracking();

    return page;
  },

  title: (title) =>
    title ? `${title} - MediCheck | AMTS schnell und effizient` : "MediCheck | AMTS schnell und effizient",

  setup({el, App, props, plugin}) {
    if (
      typeof props.initialPage.props.environmentConfig !== "undefined" &&
      props.initialPage.props.environmentConfig.environment === "local"
    ) {
      import("clockwork-browser/toolbar");
    }

    const privacy = new PrivacyVault(props.initialPage.props);

    const axios = Axios.create({
      withCredentials: true,
      withXSRFToken: true,
    });
    axios.interceptors.request.use(function (config) {
      const token = Cookies.get("mc_token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    });

    const app = createApp({
      render: () => h(App, props),
    }).use(plugin, ZiggyVue);

    app.config.globalProperties.$route = route;
    app.provide("$hasThirdParty", hasThirdParty);
    app.provide("$privacy", privacy);
    app.provide("$axios", axios);

    if (props.initialPage.props.client) {
      app.provide("$broadcast", new Broadcast(props.initialPage.props.client.id));
    }

    app.directive("click-outside", ClickOutsideDirective);

    // Sentry if dsn is set
    if (import.meta.env.SENTRY_VUE_DSN) {
      Sentry.init({
        app,
        dsn: import.meta.env.SENTRY_VUE_DSN,
        environment: import.meta.env.APP_ENV,
        debug: true,
      });
    }

    // global event for breakpoint changes
    // 1st: const with tailwind screenSizes
    const screenSizes = [
      {breakpoint: "2xl", width: 1536},
      {breakpoint: "xl", width: 1280},
      {breakpoint: "lg", width: 1024},
      {breakpoint: "md", width: 768},
      {breakpoint: "sm", width: 640},
    ];

    let changeBreakpointEvent = new CustomEvent("breakpoint", {
      detail: {
        breakpoint: "sm",
      },
    });

    // 2nd: always emit a first "resize" event on initial load
    for (let i = 0, length = screenSizes.length; i < length; ++i) {
      if (window.innerWidth >= screenSizes[i].width) {
        changeBreakpointEvent.detail.breakpoint = screenSizes[i].breakpoint;
        break; // for
      }
    }
    setTimeout(() => {
      window.dispatchEvent(changeBreakpointEvent);
    }, 2000);

    // 3rd: add event listener on resize (but with a solid debounce)
    //      and trigger the largest valid breakpoint
    window.addEventListener(
      "resize",
      debounce(() => {
        let breakpoint = null;

        for (let i = 0, length = screenSizes.length; i < length; ++i) {
          if (window.innerWidth >= screenSizes[i].width) {
            changeBreakpointEvent.detail.breakpoint = screenSizes[i].breakpoint;
            break; // for
          }
        }

        window.dispatchEvent(changeBreakpointEvent);
      }, 500),
    );

    document.addEventListener("mc-activity:track", (event) => {
      if (event.detail?.passedTime) {
        axios.post(route("api.activity"), {
          duration: event.detail.passedTime,
          user_id:
            privacy.getCurrentUser()?.id ??
            (typeof crypto.randomUUID === "function"
              ? crypto.randomUUID() // randomUUID() is only available in a secure context
              : Math.random().toString(36).substring(2, 15)), // fallback to a random string
        });
      }
    });

    startActivityTracking();

    const anchorTimeout = ref(null);

    function scrollToAndHighlightAnchor() {
      if (anchorTimeout.value !== null) {
        clearTimeout(anchorTimeout.value);
        anchorTimeout.value = null;
      }

      anchorTimeout.value = setTimeout(() => {
        const hash = window.location.hash;
        if (hash) {
          try {
            const target = document.querySelector(hash);
            if (target) {
              nextTick(() => {
                target.scrollIntoView();
                if (target.getBoundingClientRect().top < 80) {
                  const offset = 80; // offset of fixed header height
                  window.scrollBy(0, -offset);
                }
                // highlight with glow animation (add "!animate-none" to element to avoid it)
                target.classList.add("animate-glow");
                setTimeout(() => {
                  target.classList.remove("animate-glow");
                }, 2000);
                // removes #anchor from url and _replaces_ it in history, otherwise inertia actions (save/update)
                // on the page will result in an additional history entry and back would re-open the anchor again
                // instead of the previous page
                router.replace(window.location.href.split("#")[0], {preserveScroll: true});
              });
            }
          } catch {
            // nothing
            console.log("Ignoring invalid selector '" + hash + "'");
          }
        }
      }, 300);
    }

    app.mixin({
      props: {
        testId: {
          type: String,
          default: "",
        },
      },

      mounted() {
        scrollToAndHighlightAnchor();
      },
    });

    router.on("navigate", () => {
      scrollToAndHighlightAnchor();
    });

    router.on("error", (event) => {
      console.error(event.detail.errors);
    });

    app.mount(el);
    return app;
  },
});
