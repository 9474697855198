<template>
  <div class="flex items-center px-2 pb-4 space-x-2">
    <!-- Headline is editable -->
    <template v-if="isEditable">
      <div class="flex items-center space-x-1">
        <h5 class="text-2xl">Labor- / Vitalwerte</h5>
        <component-info-icon placement="top">
          <template #content>
            <p>
              Hinweis: Nur Labor- / Vitalwerte außerhalb der Norm werden automatisiert geprüft. (z. B. im Hinblick
              darauf, ob Arzneimittel in der Medikation für die Abweichung verantwortlich sind oder ob die Probleme /
              Symptome des Patienten mit den Labor- / Vitalwertabweichungen in Verbindung stehen könnten)
            </p>
          </template>
        </component-info-icon>
      </div>
    </template>

    <!-- Headline is not editable -->
    <template v-else>
      <h5 class="text-2xl">Labor- / Vitalwerte</h5>
      <component-button class="p4umc-primary" label="Bearbeiten" @click="changeIsEditable" />
    </template>
  </div>

  <component-alert
    v-if="unloadableLaborvaluesSolved?.length > 0 || unloadableLaborvaluesUnsolved?.length > 0"
    type="warning"
  >
    <h4 class="font-bold">
      In diesem Bereich befinden sich Labor- / Vitalwerte, die in der aktualisierten Datenbank ersetzt wurden.
    </h4>

    <div v-if="unloadableLaborvaluesUnsolved?.length > 0">
      <p class="mt-2">
        Bitte tauschen Sie folgende Labor- / Vitalwerte durch geeignete Neue aus, damit sie im Check erkannt und
        berücksichtigt werden:
      </p>

      <ul class="mt-2 space-y-1 list-disc pl-6">
        <li v-for="(item, index) in unloadableLaborvaluesUnsolved" :key="'unsolved-' + index">
          <em>{{ item.type }}</em>
        </li>
      </ul>
    </div>
  </component-alert>

  <div v-if="isGenderDiverse" class="px-4">
    <component-alert type="warning">
      <p>
        Eine Überprüfung der Labor-/Vitalwerte ist für das für den Patienten hinterlegten Geschlecht "divers" nicht
        möglich, es werden daher die Referenzbereiche für das Geschlecht "weiblich" verwendet.
      </p>
    </component-alert>
  </div>

  <section ref="refLaborBar">
    <!-- Main labor values -->
    <main-labor-values
      :labor-values="laborValues"
      :is-editable="isEditable"
      :is-loading="isLoading"
      @update="updateLaborValues"
    />

    <div v-if="isEditable || hasFilteredLaborValues" class="mt-4">
      <h6 class="text-xl mb-4 px-2">Weitere Labor-/Vitalwerte</h6>
    </div>

    <!-- Table -->
    <template v-if="hasFilteredLaborValues">
      <labor-table
        :record-labor-values="laborValues"
        :is-editable="isEditable"
        :is-loading="isLoading"
        @update="updateLaborValues"
      />
    </template>
  </section>

  <!-- Search -->
  <labor-search v-if="isEditable" :labor-values="laborValues" :disabled="isMassUpdateProcessing" />

  <!-- Dialogs -->
  <template v-if="isEditable">
    <labor-table-gfr-calculator ref="refLaborValueGfrDialog" />
  </template>
</template>

<script>
  import {computed, inject, provide, ref, watch} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import {cloneDeep, debounce} from "lodash";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  import {laborbardeviationValues, mainLaborvalueList} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  import LaborSearch from "@pages/Records/Components/Sections/LaborBar/Components/Search.vue";
  import LaborTable from "@pages/Records/Components/Sections/LaborBar/Components/Table.vue";
  import MainLaborValues from "@pages/Records/Components/Sections/LaborBar/Components/MainLaborValues.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import LaborTableGfrCalculator from "@pages/Records/Components/Sections/LaborBar/Components/GfrCalculator.vue";
  import {delayWhileEditing} from "@utils/Helpers/DelayWhileEditing.js";

  export default {
    name: "RecordComponentSectionLaborBar",

    components: {
      LaborTableGfrCalculator,
      ComponentInfoIcon,
      ComponentAlert,
      ComponentButton,
      LaborSearch,
      LaborTable,
      MainLaborValues,
    },

    props: {
      isEditable: {
        type: Boolean,
        require: true,
      },
      changeIsEditable: {
        type: Function,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      unloadableLaborvaluesSolved: {
        type: Array,
        required: true,
      },
      unloadableLaborvaluesUnsolved: {
        type: Array,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const setIsMassUpdateProcessing = inject("setIsMassUpdateProcessing");
      const getIsMassUpdateProcessing = inject("getIsMassUpdateProcessing");

      const refLaborBar = ref(null);
      const refLaborValueGfrDialog = ref(null);

      const laborValues = ref(cloneDeep(props.recordData.laborvalues));

      const isMassUpdateProcessing = computed(() => getIsMassUpdateProcessing("laborValue"));

      const isGenderDiverse = computed(() => page.props.patient.gender === "diverse");

      const hasFilteredLaborValues = computed(() => {
        return props.recordData.laborvalues.some((laborValue) => {
          return mainLaborvalueList.indexOf(laborValue.mc_laborvalue_key) === -1;
        });
      });

      watch(
        () => props.recordData.laborvalues,
        () => {
          laborValues.value = cloneDeep(props.recordData.laborvalues);
        },
        {deep: true},
      );

      const updateLaborValues = () => {
        setIsMassUpdateProcessing("laborValue", true);
        putLaborValues();
      };

      const putLaborValues = debounce(
        () => {
          delayWhileEditing(refLaborBar, () => {
            router.put(
              route("laborvalues.mass-update-or-create", {
                patient: page.props.patient.id,
                record: page.props.record.id,
              }),
              {
                ...laborValues.value,
              },
              {
                preserveScroll: true,
                only: ["record", "flash", "errors"],
                onFinish: () => {
                  setIsMassUpdateProcessing("laborValue", false);
                },
              },
            );
          });
        },
        500,
        {trailing: true},
      );

      const openDialog = (dialogName, data) => {
        switch (dialogName) {
          case "refLaborValueGfrDialog":
            refLaborValueGfrDialog.value.open(data);
            break;
        }
      };

      provide("laborValueOpenDialog", openDialog);

      return {
        /** enums */
        laborbardeviationValues,

        /** ref */
        refLaborBar,
        refLaborValueGfrDialog,

        /** const */
        laborValues,
        isMassUpdateProcessing,

        /** computed */
        isGenderDiverse,
        hasFilteredLaborValues,

        /** function */
        updateLaborValues,
      };
    },
  };
</script>
