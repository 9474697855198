<template>
  <header class="bg-white shadow-md sticky top-0 z-50" v-bind="$attrs">
    <nav class="flex h-16 items-center justify-between">
      <div class="flex items-center">
        <div class="flex-shrink-0 flex items-center">
          <inertia-link
            :href="$route('dashboard.index')"
            class="ml-2 focus:ring-inset focus:ring-2 focus:ring-red rounded-md"
            :data-test="testId + '-auth-header-medicheck-logo-inertia-link'"
          >
            <img class="sm:hidden" src="@images/mcp_icon_RGB_pos.svg" width="40" alt="MediCheck" />

            <img class="hidden sm:block" src="@images/mcp_logo_RGB_pos.svg" width="180" alt="MediCheck" />
          </inertia-link>
        </div>

        <div class="hidden md:block">
          <div class="hidden sm:ml-6 sm:flex sm:space-x-4 lg:space-x-8">
            <template v-for="(navItem, index) in navData.filter((i) => i.show ?? true)" :key="index">
              <template v-if="navItem.menuItems">
                <auth-header-dropdown :test-id="navItem.testId" alignment="left">
                  <template #headline>
                    <auth-header-nav-item :label="navItem.title" :icon="navItem.icon" :test-id="navItem.testId" />
                  </template>

                  <template #options>
                    <template v-for="(item, key) in navItem.menuItems.filter((i) => i.show ?? true)" :key="key">
                      <auth-header-dropdown-item
                        :label="item.label"
                        :description="item.description"
                        :icon="item.icon"
                        :href="item.href"
                        :is-external="item.isExternal"
                        :data="item.data"
                        :method="item.method"
                        :as="item.as"
                        :test-id="item.testId"
                        @click="item.clickEvent"
                      />
                    </template>
                  </template>
                </auth-header-dropdown>
              </template>

              <template v-else>
                <!-- no :icon on first level -->
                <auth-header-nav-item :label="navItem.title" :href="navItem.href" :test-id="navItem.testId" />
              </template>
            </template>
          </div>
        </div>
      </div>

      <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4 mr-2">
        <template v-if="!hasThirdParty('p4u') && hasThirdParty('nident')">
          <component-tooltip
            tooltip="Ihre PREMIUM MediCheck Upgrade Vorteile"
            position="bottom"
            custom-class-label="flex"
          >
            <a
              :href="upgradeUrl"
              target="_blank"
              class="rounded-full focus:outline-none focus:ring-2 flex space-x-1 items-center hover:no-underline group hover:bg-gray-200 p-px"
              :data-test="testId + '-auth-header-upgrade-account-link'"
            >
              <component-icon class="text-3xl text-mcred group-hover:text-mcred">stars</component-icon>

              <span class="font-semibold hidden group-hover:block pr-2 text-gray-700 text-sm">Upgrade Info</span>
            </a>
          </component-tooltip>
        </template>

        <template v-if="hasThirdParty('p4u')">
          <component-tooltip
            :tooltip="
              $page.props.notificationsCount === 0
                ? 'Sie haben keine Benachrichtigung'
                : 'Sie haben ' + $page.props.notificationsCount + ' Benachrichtigungen'
            "
            position="bottom"
            custom-class-label="flex"
            :test-id="testId + '-auth-header-notification'"
          >
            <button class="rounded-full focus:outline-none focus:ring-2 relative">
              <template v-if="$page.props.notificationsCount > 0">
                <span class="absolute inline-block bg-mcred text-white rounded-full text-xs size-4 -right-1 -top-1">
                  {{ $page.props.notificationsCount > 99 ? 99 : $page.props.notificationsCount }}
                </span>
              </template>

              <component-icon
                class="text-3xl text-gray-400 hover:text-gray-700"
                :test-id="testId + '-auth-header-notification'"
                @click="refAuthHeaderNotificationsSlideover.open()"
              >
                circle_notifications
              </component-icon>
            </button>
          </component-tooltip>
        </template>

        <auth-header-dropdown alignment="right">
          <template #headline>
            <component-tooltip tooltip="Persönliche Einstellungen" position="bottom" custom-class-label="flex">
              <button class="rounded-full focus:outline-none focus:ring-2 relative">
                <component-icon
                  class="text-3xl text-gray-400 hover:text-gray-700"
                  :test-id="testId + '-auth-header-notification'"
                >
                  {{ navDataUser.icon }}
                </component-icon>
              </button>
            </component-tooltip>
          </template>

          <template #options>
            <div class="flex space-x-2 items-top">
              <template v-if="privacy.getCurrentUser()?.name">
                <div class="flex space-x-1.5 items-center">
                  <span class="font-semibold text-left" :data-test="testId + '-auth-header-dropdown-username'">
                    {{ privacy.getCurrentUser()?.name }}
                  </span>
                </div>
              </template>
            </div>

            <template v-for="(item, index) in navDataUser.menuItems.filter((i) => i.show ?? true)" :key="index">
              <template v-if="item.show ?? true">
                <auth-header-dropdown-item
                  :label="item.label"
                  :description="item.description"
                  :icon="item.icon"
                  :href="item.href"
                  :is-external="item.isExternal"
                  :data="item.data"
                  :method="item.method"
                  :as="item.as"
                  :test-id="item.testId"
                  @click="item.clickEvent"
                />
              </template>
            </template>
          </template>
        </auth-header-dropdown>
      </div>

      <div class="mr-2 flex space-x-4 md:hidden">
        <template v-if="!hasThirdParty('p4u') && hasThirdParty('nident')">
          <component-tooltip
            v-if="!hasThirdParty('p4u') && hasThirdParty('nident')"
            tooltip="Ihre PREMIUM MediCheck Upgrade Vorteile"
            position="bottom"
            custom-class-label="flex"
          >
            <a
              :href="upgradeUrl"
              target="_blank"
              class="rounded-full focus:outline-none focus:ring-2 flex space-x-1 items-center hover:no-underline group hover:bg-gray-200 p-px"
              :data-test="testId + '-auth-header-upgrade-account-link'"
            >
              <component-icon class="text-3xl text-mcred group-hover:text-mcred">stars</component-icon>
              <span class="font-semibold hidden group-hover:block pr-2 text-gray-700 text-sm">Upgrade Info</span>
            </a>
          </component-tooltip>
        </template>

        <template v-if="hasThirdParty('p4u')">
          <component-tooltip
            :tooltip="
              $page.props.notificationsCount === 1
                ? 'Sie haben 1 Benachrichtigung'
                : 'Sie haben ' + $page.props.notificationsCount + ' Benachrichtigungen'
            "
            position="bottom"
            class="self-end"
          >
            <button class="rounded-full focus:outline-none focus:ring-2 relative">
              <template v-if="$page.props.notificationsCount > 0">
                <span class="absolute inline-block bg-mcred text-white rounded-full text-xs size-4 -right-1 -top-1">
                  {{ $page.props.notificationsCount > 99 ? 99 : $page.props.notificationsCount }}
                </span>
              </template>

              <component-icon
                class="text-3xl text-gray-400 hover:text-gray-700"
                :test-id="testId + '-auth-header-notification'"
                @click="refAuthHeaderNotificationsSlideover.open()"
              >
                circle_notifications
              </component-icon>
            </button>
          </component-tooltip>
        </template>

        <component-tooltip tooltip="Hauptmenü">
          <button
            type="button"
            class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-500 hover:bg-mcred-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-mcred-500"
            :data-test="testId + '-auth-header-open-mobile-menu-button'"
            @click="handleMobileMenu"
          >
            <span class="absolute -inset-0.5" />

            <span class="sr-only">Hauptmenü öffnen</span>

            <svg
              class="h-6 w-6"
              :class="{block: !showMobile, hidden: showMobile}"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>

            <svg
              class="h-6 w-6"
              :class="{block: showMobile, hidden: !showMobile}"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </component-tooltip>
      </div>
    </nav>

    <template v-if="showMobile">
      <nav class="md:hidden z-30" @click="closeMenu">
        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3 min-h-full">
          <template v-for="(navItem, index) in navData.filter((i) => i.show ?? true)" :key="index">
            <!-- handle clickable first level items as if they are dropdown items -->
            <template v-if="!navItem.menuItems">
              <auth-header-dropdown-item
                :label="navItem.title"
                :icon="navItem.icon"
                :href="navItem.href"
                :test-id="navItem.testId"
              />
            </template>

            <template v-if="navItem.menuItems">
              <template v-for="(item, key) in navItem.menuItems.filter((i) => i.show ?? true)" :key="key">
                <auth-header-dropdown-item
                  :label="item.label"
                  :description="item.description"
                  :icon="item.icon"
                  :href="item.href"
                  :is-external="item.isExternal"
                  :data="item.data"
                  :method="item.method"
                  :as="item.as"
                  :test-id="item.testId"
                  @click="item.clickEvent"
                />
              </template>
            </template>
          </template>
        </div>

        <div class="border-t border-gray-300 px-2 pb-3 pt-4 sm:px-3">
          <template v-if="privacy.getCurrentUser()?.name">
            <div class="ml-3 mb-3 font-semibold" :data-test="testId + '-auth-header-dropdown-username'">
              {{ privacy.getCurrentUser()?.name }}
            </div>
          </template>

          <div class="space-y-1">
            <template v-for="(item, index) in navDataUser.menuItems.filter((i) => i.show ?? true)" :key="index">
              <template v-if="item.show ?? true">
                <auth-header-dropdown-item
                  :label="item.label"
                  :description="item.description"
                  :icon="item.icon"
                  :href="item.href"
                  :is-external="item.isExternal"
                  :data="item.data"
                  :method="item.method"
                  :as="item.as"
                  :test-id="item.testId"
                  @click="item.clickEvent"
                />
              </template>
            </template>
          </div>
        </div>
      </nav>
    </template>

    <auth-header-notifications-slideover ref="refAuthHeaderNotificationsSlideover" :test-id="testId + '-auth-header'" />
  </header>

  <component-bmp-import-dialog ref="refBmpImportDialog" :test-id="testId + '-auth-header'" />
  <component-json-import-dialog ref="refJsonImportDialog" :test-id="testId + '-auth-header'" />
  <component-demodata-dialog ref="refDemodataDialog" :test-id="testId + '-auth-header'" />

  <component-dialog
    ref="refMergeAccountDialog"
    dialog-title="Accounts verknüpfen"
    dialog-width="medium"
    primary-label="Accounts verknüpfen"
    :test-id="testId + '-auth-header'"
    @primary-button-clicked="mergeAccounts()"
  >
    <template #content>
      <h6 class="font-semibold">Wann benötigen Sie diese Funktion?</h6>

      <p>Diese Seite richtet sich an Nutzer, für die eine der beiden folgenden Situationen zutrifft:</p>

      <ul class="list-disc mt-4 ml-8 space-y-2">
        <li>
          Sie haben bereits die Warenwirtschaftsversion des MediCheck und möchten künftig die Premium MediCheck
          Upgrade-Version nutzen. Beim ersten Login über Ihren pharma4u-Account in die Vollversion des MediCheck sollen
          Sie einen Verknüpfungslink aufrufen, um die bereits existierenden, über die Warenwirtschaft erstellten
          Analysen in den neuen Account zu übertragen.
        </li>
        <li>
          Sie nutzen bereits die Vollversion des Premium MediCheck (pharma4u Account) sowie parallel die in Ihrer
          Warenwirtschaft integrierte Version (MediCheck Warenwirtschaft), haben beim Onboarding der Warenwirtschaft
          jedoch Ihren bestehenden pharma4u-Account nicht verknüpft. Sie möchten nun die Analysen beider Accounts in
          eine gemeinsame Patientenübersicht zusammenführen.
        </li>
      </ul>

      <p class="mt-4">
        Wenn einer der beiden Fälle auf Sie zutrifft, dann können Sie über den folgenden Button „Accounts verknüpfen“
        den Verknüpfungsvorgang starten.
      </p>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, onMounted, ref} from "vue";
  import {Link as InertiaLink, router, usePage} from "@inertiajs/vue3";

  import Window from "@utils/Helpers/Window.js";

  import ComponentBmpImportDialog from "@components/Dialogs/BmpImportDialog/BmpImportDialog.vue";
  import ComponentDemodataDialog from "@components/Dialogs/DemodataDialog/DemodataDialog.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentJsonImportDialog from "@components/Dialogs/JsonImportDialog.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import AuthHeaderDropdown from "@components/Layout/Components/AuthHeader/Components/Dropdown/Dropdown.vue";
  import AuthHeaderDropdownItem from "@components/Layout/Components/AuthHeader/Components/Dropdown/DropdownItem.vue";
  import AuthHeaderNavItem from "@components/Layout/Components/AuthHeader/Components/NavItem.vue";
  import AuthHeaderNotificationsSlideover from "@components/Layout/Components/AuthHeader/Components/Notifications/NotificationsSlideover.vue";

  export default {
    name: "LayoutAuthHeader",

    components: {
      AuthHeaderDropdown,
      AuthHeaderDropdownItem,
      AuthHeaderNavItem,
      AuthHeaderNotificationsSlideover,
      ComponentBmpImportDialog,
      ComponentDemodataDialog,
      ComponentDialog,
      ComponentIcon,
      ComponentJsonImportDialog,
      ComponentTooltip,
      InertiaLink,
    },

    setup(props) {
      const page = usePage();

      const hasThirdParty = inject("$hasThirdParty");
      const privacy = inject("$privacy");

      const refAuthHeaderNotificationsSlideover = ref(null);
      const refBmpImportDialog = ref(null);
      const refDemodataDialog = ref(null);
      const refJsonImportDialog = ref(null);
      const refMergeAccountDialog = ref(null);

      const navData = ref([]);
      const navDataUser = ref({});
      const showMobile = ref(false);

      const upgradeUrl = computed(() => {
        return import.meta.env.MC_PHARMA4U_UPGRADE_URL;
      });

      onMounted(() => {
        window.addEventListener("breakpoint", () => {
          // any resize event should close the mobile menu
          if (showMobile.value) {
            showMobile.value = false;
          }
        });

        navData.value = [
          {
            title: "Neue Analyse",
            icon: "expand_more",
            show: page.props.can.create_records || page.props.can.view_patientinputs,
            testId: props.testId + "-auth-header-dropdown-new-analysis",
            menuItems: [
              {
                label: "Manuelle Erfassung",
                description: "Datenerfassung per Hand für neuen Patienten",
                icon: "edit",
                show: page.props.can.create_records,
                href: route("patients.create") + "?continue-with-record",
                testId: props.testId + "-auth-header-dropdown-create-patient",
              },
              {
                label: "Import aus Medikationsplan",
                description: "Scan oder Upload des BMP",
                icon: "barcode_reader", // "settings_overscan",
                href: null,
                clickEvent: () => refBmpImportDialog.value.open(),
                show: page.props.can.create_records,
                testId: props.testId + "-auth-header-dropdown-import-bmp",
              },
              {
                label: "Import aus JSON-Datei",
                icon: "description",
                description: "Zuvor exportierte Analyse importieren",
                href: null,
                clickEvent: () => refJsonImportDialog.value.open(),
                show: page.props.can.json_import,
                testId: props.testId + "-auth-header-dropdown-import-json",
              },
              {
                label: "Import aus Patientenfragebogen",
                description: "Import der Vorerfassung durch Patienten / Checkliste für Pflegefachkraft",
                icon: "note_alt",
                href: "patientinput-client.index",
                show: page.props.can.view_patientinputs,
                testId: props.testId + "-auth-header-dropdown-import-patient-input",
              },
            ],
          },
          {
            title: "Patienten",
            href: route("patients.index"),
            icon: "view_list",
            testId: props.testId + "-auth-header-dropdown-patient",
          },
          {
            title: "mehr",
            icon: "expand_more",
            testId: props.testId + "-auth-header-dropdown-more",
            menuItems: [
              {
                label: "Kontakte",
                description: "Ärzte, Pflegedienste, Heime",
                icon: "local_hospital",
                href: "contacts.index",
                testId: props.testId + "-auth-header-dropdown-contact",
              },
              {
                label: "Beispielfälle",
                description: "Beispielfälle zum Testen",
                icon: "grade",
                href: null,
                clickEvent: () => refDemodataDialog.value.open(),
                show: page.props.can.create_records,
                testId: props.testId + "-auth-header-dropdown-demo-data",
              },
              {
                label: "Arbeitshilfen",
                description: "Hilfe und Support",
                icon: "help",
                href: "documents.index",
                testId: props.testId + "-auth-header-dropdown-documentation",
              },
              {
                label: "Hilfe pharma4u",
                description: "Hilfe und Support bei pharma4u",
                icon: "support",
                href: page.props.links.p4uHelp,
                isExternal: true,
                show: hasThirdParty("p4u"),
                testId: props.testId + "-auth-header-dropdown-p4u-help",
              },
            ],
          },
        ];

        navDataUser.value = {
          icon: "account_circle",
          testId: props.testId + "-auth-header-dropdown-user",
          menuItems: [
            {
              label: "Profil",
              description: "Profil bei pharma4u anpassen",
              icon: "person",
              href: page.props.links.p4uProfile,
              isExternal: true,
              show: hasThirdParty("p4u"),
              testId: props.testId + "-auth-header-dropdown-profile",
            },
            {
              label: "Datenschutzpasswort",
              description: "Passwort für Verschlüsselung ändern",
              icon: "lock",
              href: "privacyPassword.show",
              data: {user: privacy.getCurrentUser().id},
              show: !hasThirdParty("nident"),
              testId: props.testId + "-auth-header-dropdown-privacy-password",
            },
            {
              label: "Einstellungen",
              icon: "settings",
              href: "settings.show",
              data: {user: privacy.getCurrentUser().id},
              testId: props.testId + "-auth-header-dropdown-settings",
            },
            {
              label: "Accounts verknüpfen",
              icon: "link",
              clickEvent: () => refMergeAccountDialog.value.open(),
              data: {user: privacy.getCurrentUser().id},
              testId: props.testId + "-auth-header-dropdown-merge-account",
            },
            {
              label: "Logout",
              icon: "power_settings_new",
              clickEvent: () => logout(),
              canBeActive: true,
              as: "button",
              testId: props.testId + "-auth-header-dropdown-logout",
            },
          ],
        };
      });

      const handleMobileMenu = () => {
        showMobile.value = !showMobile.value;
      };

      const logout = () => {
        router.post(
          route("auth.logout"),
          {},
          {
            onSuccess: () => {
              privacy.purgeLocalStorage();
            },
          },
        );
      };

      const closeMenu = () => {
        if (showMobile.value) showMobile.value = false;
      };

      function mergeAccounts() {
        Window.open(route("merge-account.create"), "_blank").catch(() => {
          console.error("Open Window failed!");
        });
      }

      return {
        /** inject */
        hasThirdParty,
        privacy,

        /** ref */
        refAuthHeaderNotificationsSlideover,
        refBmpImportDialog,
        refDemodataDialog,
        refJsonImportDialog,
        refMergeAccountDialog,

        /** const */
        navData,
        navDataUser,
        showMobile,

        /** computed */
        upgradeUrl,

        /** function */
        closeMenu,
        handleMobileMenu,
        mergeAccounts,
      };
    },
  };
</script>
