<template>
  <component-dialog
    ref="refDialog"
    :dialog-title="form.wasSuccessful || hasAnyPosDocument ? '' : 'Easy-com Kommunikation und Dokumentation'"
    dialog-width="medium"
    data-test="recordCaseClose-dialog"
  >
    <template #content>
      <template v-if="processing || form.processing">
        <h2 class="flex space-x-4 w-full justify-center">
          <span class="text-xl">PDF-Dokumente werden übertragen...</span>
          <component-spinner class="size-8" />
        </h2>

        <hr class="w-full h-[1px] p-0 mt-4 bg-gray-500" />
      </template>
      <template v-else-if="!processing && form.wasSuccessful">
        <h2 class="flex space-x-2 w-full justify-center text-mcgreen">
          <component-icon class="text-xl mt-1">check</component-icon>
          <span class="text-xl">Dokumente erfolgreich gespeichert</span>
        </h2>

        <hr class="w-full h-[1px] p-0 mt-4 bg-gray-500" />

        <p class="mt-4">Die vorhanden PDF-Dokumente wurden in der Warenwirtschaft gespeichert.</p>

        <p class="mt-4">
          Wenn Sie nachträglich Änderungen vornehmen möchten, ist ein erneutes Speichern der aktualisierten
          PDF-Dokumente in der Warenwirtschaft über „Dokumente gespeichert“ möglich.
        </p>

        <p class="mt-4">Möchten Sie den Status der Variante ebenfalls aktualisieren?</p>

        <h3 class="mt-2 font-bold">Status der Variante</h3>
        <component-record-status :record="record" class="mt-2" inner-class="bottom-8 min-w-24 max-w-32" />
      </template>

      <template v-else-if="hasRelevantPosDocuments">
        <h2 class="flex space-x-2 w-full justify-center text-mcgreen">
          <component-icon class="text-xl mt-1">check</component-icon>
          <span class="text-xl">Dokumente erfolgreich gespeichert</span>
        </h2>
        <p class="mt-2 text-center">Folgende PDF-Dokumente wurden bereits in der Warenwirtschaft gespeichert:</p>
        <ul class="mt-2 list-disc w-32 mx-auto">
          <li v-if="record.has_pos_documents?.doctor_pdf">Arzt-PDF</li>
          <li v-if="record.has_pos_documents?.patient_pdf">Patient-PDF</li>
          <li v-if="record.has_pos_documents?.full_pdf">Archiv-PDF</li>
        </ul>

        <component-alert
          v-if="!record.has_pos_documents?.doctor_pdf && !record.has_pos_documents?.patient_pdf"
          class="mt-4"
        >
          <h4 class="font-bold">
            Bisher liegt nur das Gesamtdokument (Archiv-PDF) mit allen Ergebnissen vor. Dieses dient allein der internen
            Dokumentation!
          </h4>

          <p class="mt-2">
            Für die Kommunikation mit Arzt oder Patient treffen Sie bitte
            <strong class="font-semibold">eine Auswahl</strong>
            der relevantesten Checkergebnisse mittels
            <strong class="font-semibold">„Kommunikation an Arzt“</strong>
            oder
            <strong class="font-semibold">„Kommunikation an Patient“</strong>
            oder nutzen Sie das
            <strong class="font-semibold">Zusammenfassungsfeld.</strong>
          </p>
        </component-alert>

        <hr class="w-full h-[1px] p-0 mt-4 bg-gray-500" />

        <p class="mt-4">Möchten Sie ggf. geänderte PDF-Dokumente erneut speichern?</p>

        <p class="mt-4">
          Hinweis: Die bisher in der Warenwirtschaft gespeicherten PDF-Dokumente werden durch die aktuellen ersetzt.
        </p>
      </template>
      <template v-else>
        <h2 class="text-base">Mit „Speichern“ werden folgende Dokumente in der Warenwirtschaft abgelegt:</h2>

        <ul class="mt-4 space-y-4">
          <li class="flex space-x-2">
            <div>
              <component-icon v-if="storeDoctorPdf" class="text-mcgreen-500 text-2xl">check</component-icon>
              <component-icon v-else class="text-2xl text-gray-500">close</component-icon>
            </div>
            <div :class="{'text-gray-500': !storeDoctorPdf}">
              <h3 class="text-base font-bold">Arztkommunikation</h3>
              <div class="text-sm">
                Ein Arzt-PDF wird erzeugt, wenn Sie bei mindestens einem Checkergebnis die Auswahl „Info an Arzt“
                getroffen oder eine Zusammenfassung an den Arzt eingetragen haben.
              </div>
            </div>
          </li>

          <li class="flex space-x-2">
            <div>
              <component-icon v-if="storePatientPdf" class="text-mcgreen text-2xl">check</component-icon>
              <component-icon v-else class="text-2xl text-gray-500">close</component-icon>
            </div>
            <div :class="{'text-gray-500': !storePatientPdf}">
              <h3 class="text-base font-bold">Patientenkommunikation</h3>
              <div class="text-sm">
                Ein Patienten-PDF wird erzeugt, wenn Sie bei mindestens einem Checkergebnis die Auswahl „Info an
                Patient“ getroffen oder eine Zusammenfassung an den Patienten eingetragen haben.
              </div>
            </div>
          </li>

          <li class="flex space-x-2">
            <div>
              <component-icon class="text-mcgreen text-2xl">check</component-icon>
            </div>
            <div>
              <h3 class="text-base font-bold">Alle Analyseergebnisse</h3>
              <div class="text-sm">
                Archiv-PDF mit allen Checkergebnissen für Ihre eigene Ablage; bitte nicht an Arzt / Patient.
              </div>
            </div>
          </li>
        </ul>

        <component-alert v-if="!storeDoctorPdf && !storePatientPdf" class="mt-4" type="warning">
          <h4 class="font-bold">
            Bisher liegt nur das Gesamtdokument (Archiv-PDF) mit allen Ergebnissen vor. Dieses dient allein der internen
            Dokumentation!
          </h4>

          <p class="mt-2">
            Für die Kommunikation mit Arzt oder Patient treffen Sie bitte
            <strong class="font-semibold">eine Auswahl</strong>
            der relevantesten Checkergebnisse mittels
            <strong class="font-semibold">„Kommunikation an Arzt“</strong>
            oder
            <strong class="font-semibold">„Kommunikation an Patient“</strong>
            oder nutzen Sie das
            <strong class="font-semibold">Zusammenfassungsfeld.</strong>
          </p>
        </component-alert>
      </template>
    </template>
    <template #actions>
      <template v-if="form.wasSuccessful">
        <component-button class="p4umc-primary" label="Schließen" @click="close()" />
      </template>
      <template v-else>
        <component-button
          v-if="hasRelevantPosDocuments"
          class="p4umc-primary"
          label="Erneut speichern"
          @click="forceUpdateExistingDocuments = true"
        />
        <component-button v-else class="p4umc-primary" label="Speichern" :disabled="processing" @click="save()" />

        <component-button label="Abbrechen" @click="close()" />
      </template>
    </template>
  </component-dialog>
</template>

<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {computed, inject, nextTick, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentIcon from "../Icons/Icon.vue";
  import ComponentAlert from "../Alerts/Alert.vue";
  import ComponentButton from "../Buttons/Button.vue";
  import ComponentSpinner from "../Spinner.vue";
  import readDom from "@utils/Helpers/ReadDom.js";
  import ComponentRecordStatus from "@pages/Records/Components/RecordStatus.vue";

  export default {
    name: "ComponentRecordCaseCloseDialog",
    components: {
      ComponentRecordStatus,
      ComponentSpinner,
      ComponentButton,
      ComponentAlert,
      ComponentIcon,
      ComponentDialog,
    },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      disableEasyComEnd: {
        type: Boolean,
        default: false,
      },
      easyComAtNextSteps: {
        type: Boolean,
        default: false,
      },
    },
    expose: ["open", "close"],
    setup(props) {
      const privacy = inject("$privacy");

      const refDialog = ref(null);
      const processing = ref(false);
      const forceUpdateExistingDocuments = ref(false);

      const sumCommunicationDoc = inject("sumCommunicationDoc");
      const sumCommunicationPatient = inject("sumCommunicationPatient");

      const simulateResultConfig = inject("simulateResultConfig");
      const restoreResultConfig = inject("restoreResultConfig");

      const hasAnyPosDocument = computed(() => {
        return (
          props.record.has_pos_documents?.doctor_pdf ||
          props.record.has_pos_documents?.patient_pdf ||
          props.record.has_pos_documents?.full_pdf
        );
      });

      const hasRelevantPosDocuments = computed(() => {
        if (forceUpdateExistingDocuments.value === true) {
          return false;
        }

        return (
          props.record.has_pos_documents?.full_pdf === true ||
          props.record.has_pos_documents?.doctor_pdf === true ||
          props.record.has_pos_documents?.patient_pdf === true
        );
      });

      const form = useForm({
        doctor_html: null,
        patient_html: null,
        archive_html: null,
        bmp_data: {
          client: null,
          patient: null,
          record: null,
        },
      });

      function open() {
        refDialog.value.open();

        // initialize bmp data as early as possible
        form.bmp_data.client = privacy.getClient();
        privacy.decryptPatient(props.patient).then((result) => {
          form.bmp_data.patient = result;
        });
        privacy.decryptRecord(props.record).then((result) => {
          form.bmp_data.record = result;
        });
      }

      function close() {
        // reset state; form.reset() does not work 😭
        form.doctor_html = null;
        form.patient_html = null;
        form.archive_html = null;
        form.bmp_data = {
          client: null,
          patient: null,
          record: null,
        };
        form.wasSuccessful = false;
        processing.value = false;
        forceUpdateExistingDocuments.value = false;

        refDialog.value.close();
      }

      const storeDoctorPdf = computed(() => {
        return props.record.doctorsummary || sumCommunicationDoc.value > 0;
      });

      const storePatientPdf = computed(() => {
        return props.record.patientsummary || sumCommunicationPatient.value > 0;
      });

      function save() {
        // handle processing indicator manually
        processing.value = true;

        // doctor
        if (storeDoctorPdf.value) {
          simulateResultConfig("showCommunicationDoc", true);
          simulateResultConfig("showCommunicationPatient", false);
          nextTick(() => {
            form.doctor_html = readDom("doc");
          });
        }

        // patient
        setTimeout(() => {
          if (storePatientPdf.value) {
            simulateResultConfig("showCommunicationDoc", false);
            simulateResultConfig("showCommunicationPatient", true);
            nextTick(() => {
              form.patient_html = readDom("patient");
            });
          }
        }, 200);

        // archive (always)
        setTimeout(() => {
          simulateResultConfig("showCommunicationDoc", false);
          simulateResultConfig("showCommunicationPatient", false);
          nextTick(() => {
            form.archive_html = readDom("archive");
          });
        }, 400);

        setTimeout(() => {
          form.post(route("records.store-to-transfer", {patient: props.record.patient_id, record: props.record.id}), {
            preserveScroll: true,
            onFinish: () => {
              processing.value = false;
              restoreResultConfig();
            },
          });
        }, 600);
      }

      return {
        refDialog,
        hasAnyPosDocument,
        hasRelevantPosDocuments,
        form,
        processing,
        open,
        close,
        save,
        sumCommunicationDoc,
        sumCommunicationPatient,
        storeDoctorPdf,
        storePatientPdf,
        forceUpdateExistingDocuments,
      };
    },
  };
</script>
