<template>
  <div class="flex my-5 text-left">
    <component-icon
      class="text-mcred text-2xl cursor-pointer mt-1.5"
      :class="{'!text-gray-400 !pointer-events-none': isDisabled}"
      @click="setFocus"
    >
      add
    </component-icon>

    <component-autocomplete
      ref="refSearch"
      class="ml-1 w-96"
      placeholder="Labor- oder Vitalwert"
      :fetch-method="fetchLaborvalues"
      key-name="name"
      :min-search-term-length="1"
      :validation="error"
      :disabled="isDisabled"
      @selected="updateRowWithSelectedItem"
    >
      <template #autocomplete-item="{item}">
        <div class="min-h-10 w-full">
          <span class="inline-block align-middle">
            {{ item.name }}
          </span>
          <div v-if="item.exists" class="text-xs text-mcred-500">
            Laborwert wurde bereits erfasst als {{ item.exists }}
          </div>
        </div>
      </template>

      <template #autocomplete-recommendation>
        <div v-if="recommendedValueTypes.length" class="p-3">
          <span class="text-gray-500">Häufig verwendete Werte:</span>
          <ul class="flex flex-wrap">
            <li
              v-for="(recommendation, index) in recommendedValueTypes"
              :key="index"
              class="mr-2 after:content-[','] last:after:content-[]"
            >
              <a href="#" class="text-mcred" @click.prevent="updateRowWithSelectedItem(recommendation)">
                {{ recommendation.name }}
              </a>
            </li>
          </ul>
        </div>
      </template>

      <template #autocomplete-empty>
        <div class="flex items-stretch w-96 h-16">
          <div class="w-full text-center self-center">
            <span>Zu Ihrer Eingabe konnte nichts gefunden werden.</span>
          </div>
        </div>
      </template>
    </component-autocomplete>
  </div>
</template>

<script>
  import {computed, onMounted, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import ComponentAutocomplete from "@components/Autocomplete/Autocomplete.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import {recommendedLaborKeys} from "@pages/Records/Components/Sections/LaborBar/enums.js";

  export default {
    name: "LaborSearch",

    components: {
      ComponentAutocomplete,
      ComponentIcon,
    },

    props: {
      laborValues: {
        type: Object,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const refSearch = ref(null);
      const error = ref("");

      const recommendedValueTypes = ref([]);

      const laborvalueForm = useForm({
        type: null,
        deviation: "normal",
        value: null,
        mc_laborvalue_id: null,
        mc_laborvalue_key: null,
        mc_unit_id: null,
        unit: null,
      });

      const isDisabled = computed(() => laborvalueForm.processing || props.disabled);

      onMounted(() => setRecommendedLaborvalues());

      watch(
        () => props.laborValues,
        () => {
          setRecommendedLaborvalues();
        },
        {deep: true},
      );

      const setRecommendedLaborvalues = () => {
        coreRouter.searchForLaborvaluesByKey(recommendedLaborKeys.join(","), {
          onSuccess: (res) => {
            if (res.data?.length > 0) {
              let /** @type {Array} */ data = res.data;

              data = data.filter((item) => item.default);

              data = data.filter(
                (item) => !props.laborValues.some((laborvalue) => laborvalue.mc_laborvalue_key === item.key),
              );

              recommendedValueTypes.value = data.slice(0, 4);
            }
          },
          onError: () => {
            recommendedValueTypes.value = [];
          },
        });
      };

      const fetchLaborvalues = (searchTerm) => {
        return new Promise((resolve) => {
          coreRouter.get("/api/v1/am/laborvalues/?search=name:" + searchTerm, {
            onSuccess: (response) => {
              filterOptionList(response.data).then((filteredList) => {
                resolve(filteredList);
              });
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const filterOptionList = (list) => {
        return new Promise((resolve) => {
          const defaultValues = [182, 183, 3, 1, 177, 137, 4];

          const filteredList = list
            .filter((item) => {
              const isDefaultValue = defaultValues.includes(item.key);
              return !isDefaultValue;
            })
            .map((item) => {
              const existsInRecord = page.props.record.laborvalues.find(
                (recordItem) => recordItem.mc_laborvalue_key === item.key,
              );

              return {...item, exists: existsInRecord?.type || false};
            });

          resolve(filteredList);
        });
      };

      const updateRowWithSelectedItem = (selectItem) => {
        if (!selectItem.id) {
          error.value = "Unbekannte Auswahl!";
          return;
        }

        error.value = "";
        refSearch.value.reset();

        laborvalueForm.type = selectItem.name;
        laborvalueForm.mc_laborvalue_id = selectItem.id;
        laborvalueForm.mc_laborvalue_key = selectItem.key;

        laborvalueForm.post(
          route("laborvalues.store", {patient: page.props.patient.id, record: page.props.record.id}),
          {
            preserveScroll: true,
            onFinish: () => {
              laborvalueForm.reset();
            },
          },
        );
      };

      const setFocus = () => {
        refSearch.value.focus();
      };

      return {
        /** ref */
        refSearch,

        /** ref */
        laborvalueForm,
        error,
        recommendedValueTypes,

        /** computed */
        isDisabled,

        /** function */
        fetchLaborvalues,
        updateRowWithSelectedItem,
        setFocus,
      };
    },
  };
</script>

<style scoped></style>
