<template>
  <div class="grid grid-cols-3 md:grid-cols-12 gap-4">
    <div class="col-span-4 flex flex-col space-y-4">
      <h1>Default</h1>

      <component-input v-model="inputValue" label="Input" placeholder="Placeholder" helper-text="Helper Text" />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        hide-clear
        label="Input [hide-clear]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input v-model="inputValue" label="Input with Icon" placeholder="Placeholder" helper-text="Helper Text">
        <template #icon><component-icon class="text-xl text-gray-600">search</component-icon></template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-select v-model="selectValue" label="Select" helper-text="Helper Text">
        <option>Option 1</option>
        <option>Option 2</option>
      </component-select>

      <hr class="border-green-50 border-2" />

      <component-full-date-input v-model="dateValue" helper-text="Helper Text" label="Full Date Input" />

      <hr class="border-green-50 border-2" />

      <div class="h-20">
        <component-dosage-input name="morning" />
      </div>

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        type="password"
        maxlength="3"
        label="Input[password]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        hide-clear
        label="Input[suffix]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      >
        <template #suffix>suffix</template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-range label="Range" :datalist="['a', 'b', 'c']" />
    </div>

    <div class="col-span-4 flex flex-col space-y-4">
      <h1>Disabled</h1>

      <component-input
        v-model="inputValue"
        disabled
        label="Input"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        disabled
        hide-clear
        label="Input [hide-clear]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        disabled
        label="Input with Icon"
        placeholder="Placeholder"
        helper-text="Helper Text"
      >
        <template #icon><component-icon class="text-xl text-gray-600">search</component-icon></template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-select v-model="selectValue" disabled label="Select" helper-text="Helper Text">
        <option>Option 1</option>
        <option>Option 2</option>
      </component-select>

      <hr class="border-green-50 border-2" />

      <component-full-date-input v-model="dateValue" helper-text="Helper Text" disabled label="Full Date Input" />

      <hr class="border-green-50 border-2" />

      <div class="h-20">
        <component-dosage-input disabled name="morning" />
      </div>

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        disabled
        type="password"
        maxlength="3"
        label="Input[password]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        disabled
        hide-clear
        label="Input[suffix]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      >
        <template #suffix>suffix</template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-range disabled label="Range" :datalist="['a', 'b', 'c']" />
    </div>

    <div class="col-span-4 flex flex-col space-y-4">
      <h1>Validation</h1>

      <component-input
        v-model="inputValue"
        validation="Validation"
        label="Input"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        validation="Validation"
        hide-clear
        label="Input [hide-clear]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        validation="Validation"
        label="Input with Icon"
        placeholder="Placeholder"
        helper-text="Helper Text"
      >
        <template #icon><component-icon class="text-xl text-gray-600">search</component-icon></template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-select v-model="selectValue" validation="Validation" label="Select" helper-text="Helper Text">
        <option>Option 1</option>
        <option>Option 2</option>
      </component-select>

      <hr class="border-green-50 border-2" />

      <component-full-date-input
        v-model="dateValue"
        helper-text="Helper Text"
        validation="Validation called Error here"
        label="Full Date Input"
      />

      <hr class="border-green-50 border-2" />

      <div class="h-20">
        <component-dosage-input value="a" name="morning" />
      </div>

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        validation="Validation"
        type="password"
        maxlength="3"
        label="Input[password]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      />

      <hr class="border-green-50 border-2" />

      <component-input
        v-model="inputValue"
        validation="Validation"
        hide-clear
        label="Input[suffix]"
        placeholder="Placeholder"
        helper-text="Helper Text"
      >
        <template #suffix>suffix</template>
      </component-input>

      <hr class="border-green-50 border-2" />

      <component-range validation="Validation" label="Range" :datalist="['a', 'b', 'c']" />
    </div>
  </div>
</template>

<script>
  import {ref, computed, watch, inject, defineAsyncComponent} from "vue";
  import {usePage, useForm, router} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import PatientsPatientHead from "@pages/Patients/Components/PatientHead.vue";
  import PatientsPatientRow from "@pages/Patients/Components/PatientRow.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTabButton from "@components/Buttons/TabButton.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentPatientNoteDialog from "@components/Dialogs/PatientNoteDialog.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentPatientTodosDialog from "@components/Dialogs/PatientTodosDialog.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentFullDateInput from "@components/Inputs/FullDateInput.vue";
  import ComponentRange from "@components/Inputs/Range.vue";

  const PatientsExternalPatientHead = defineAsyncComponent(
    () => import("@pages/Patients/Components/ExternalPatientHead.vue"),
  );
  const PatientsExternalPatientRow = defineAsyncComponent(
    () => import("@pages/Patients/Components/ExternalPatientRow.vue"),
  );
  const PatientsDeletedPatientHead = defineAsyncComponent(
    () => import("@pages/Patients/Components/DeletedPatientHead.vue"),
  );
  const PatientsDeletedPatientRow = defineAsyncComponent(
    () => import("@pages/Patients/Components/DeletedPatientRow.vue"),
  );

  export default {
    name: "PagesPatientIndex",

    components: {
      ComponentRange,
      ComponentFullDateInput,
      ComponentDosageInput,
      ComponentSelect,
      ComponentConfirmationDialog,
      ComponentPatientTodosDialog,
      ComponentEmptyState,
      ComponentLinkButton,
      ComponentPatientNoteDialog,
      ComponentBreadcrumb,
      ComponentIcon,
      ComponentInput,
      ComponentPagination,
      PatientsPatientHead,
      PatientsPatientRow,
      PatientsExternalPatientHead,
      PatientsExternalPatientRow,
      PatientsDeletedPatientHead,
      PatientsDeletedPatientRow,
      ComponentFilterFlags,
      ComponentSpinner,
      ComponentTabButton,
    },

    props: {},

    setup(props) {
      const inputValue = ref(null);
      const selectValue = ref(null);
      const dateValue = ref(null);

      return {inputValue, selectValue, dateValue};
    },
  };
</script>
