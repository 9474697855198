<template>
  <patientinput-component-step title="Mobilität">
    <p>
      <span v-if="form.masterdata.patient.gender === 'female'">Die Patientin</span>
      <span v-else>Der Patient</span>
      benutzt bzw. ist:
    </p>

    <component-checkbox
      v-for="(attribute, index) in mobility"
      :key="index"
      v-model="form.circumstances.attributes[attribute.key]"
      color="primary"
      large
    >
      {{ attribute.titleCarer }}
    </component-checkbox>
  </patientinput-component-step>
</template>

<script>
  import {mobility} from "@pages/Patientinput/Enums.js";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "PatientinputComponentStepCircumstances",

    components: {
      PatientinputComponentStep,
      ComponentCheckbox,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    setup() {
      return {mobility};
    },
  };
</script>
