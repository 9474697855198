<template>
  <div class="flex my-5 text-left">
    <component-icon
      class="text-mcred text-2xl cursor-pointer mt-1.5"
      :class="{'!text-gray-400 !pointer-events-none': disabled}"
      @click="setFocus"
    >
      add
    </component-icon>

    <component-autocomplete
      ref="refPacSearch"
      class="ml-1 w-96"
      placeholder="Handelsname / PZN / *Wirkstoff (eingeben oder scannen)"
      :fetch-method="searchPac"
      :filter-method="filterPac"
      key-name="Name"
      :min-search-term-length="minSearchTermLength"
      :validation="error"
      :disabled="disabled"
      @selected="updateRowWithSelectedItem"
    >
      <template #autocomplete-item="{item}">
        <div class="min-h-10">
          <div class="flex justify-between">
            <div>{{ item.Name }} / {{ item.Packung }}</div>
            <component-expandable-badge v-if="!item.analysable">**nicht analysierbar</component-expandable-badge>
          </div>
          <p class="text-sm text-gray-500 leading-tight">
            <template v-if="item.arws.length">
              {{
                item.arws
                  .map(function (arw) {
                    return (arw.Wirkstoff + " " + arw.Staerke).trim();
                  })
                  .filter(function (arw) {
                    return arw.length > 0;
                  })
                  .join(", ")
              }}
              /
            </template>
            <template v-if="item.Key_DAR">
              {{ item.Key_DAR }}
            </template>
          </p>
          <p class="text-sm text-gray-500 leading-tight">
            {{ item.Anbieter }} / {{ item.Vertriebsstatus }} / PZN: {{ item.PZN }}
          </p>
          <div
            v-if="medicationList.some((medication) => medication?.selected_package_pzn === item.PZN)"
            class="text-xs text-mcred-500"
          >
            Artikel wurde bereits erfasst
          </div>
        </div>
      </template>

      <template #autocomplete-empty="{term}">
        <div class="flex items-stretch w-96 h-16">
          <div class="w-full text-center self-center">
            <span v-if="isValidSearchTerm(term)">Zu Ihrer Eingabe konnte nichts gefunden werden.</span>
            <span v-else>Arzneimittel eingeben oder PZN mit Handscanner einlesen</span>
          </div>
        </div>
      </template>

      <template #autocomplete-prepend="{items}">
        <div class="p-3 text-sm text-gray-500 flex items-center space-x-3">
          <span>Filter:</span>
          <label class="select-none flex items-center space-x-2">
            <input v-model="showAnalysable" class="text-red-600" type="checkbox" />
            <span>analysierbar ({{ items.filter((item) => item.analysable).length }})</span>
          </label>
          <label class="select-none flex items-center space-x-2">
            <input v-model="showNonAnalysable" class="text-red-600" type="checkbox" />
            <span>nicht analysierbar ({{ items.filter((item) => !item.analysable).length }})</span>
          </label>
        </div>
      </template>
    </component-autocomplete>
  </div>
</template>

<script>
  import {computed, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentAutocomplete from "@components/Autocomplete/Autocomplete.vue";
  import ComponentExpandableBadge from "@components/Badges/ExpandableBadge.vue";

  export default {
    name: "MedicationComponentPacSearch",

    components: {ComponentExpandableBadge, ComponentAutocomplete, ComponentIcon},

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      const page = usePage();

      const refPacSearch = ref(null);

      const error = ref("");
      const minSearchTermLength = ref(3);
      const showAnalysable = ref(true);
      const showNonAnalysable = ref(true);

      const medicationList = computed(() => page.props.record.medications);

      watch(
        () => showAnalysable.value,
        (newValue) => {
          // avoid both filter options to be false
          if (newValue === false && showNonAnalysable.value === false) {
            showNonAnalysable.value = true;
          }
        },
      );
      watch(
        () => showNonAnalysable.value,
        (newValue) => {
          // avoid both filter options to be false
          if (newValue === false && showAnalysable.value === false) {
            showAnalysable.value = true;
          }
        },
      );

      const searchPac = (searchTerm) => {
        // some scanners prepend a '#', which we have to remove
        if (searchTerm.charAt(0) === "#") {
          searchTerm = searchTerm.slice(1);
        }

        // A scanned "PZN" value starts with a '-', so if
        // we receive a searchTerm like '-12345678', we
        // strip off this '-' and search for the PZN.
        let match = searchTerm.match(/^-(\d{7,8})/i);
        if (match && match[1]) {
          // we just use the digits as PZN and do not
          // care if it is 7 or 8 digits long
          searchTerm = match[1];
        }

        return new Promise((resolve) => {
          coreRouter.searchPac(searchTerm, {
            onSuccess: (response) => {
              resolve(response.data);
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const filterPac = (results) => {
        return results.filter((item) => {
          return (showAnalysable.value && item.analysable) || (showNonAnalysable.value && !item.analysable);
        });
      };

      const updateRowWithSelectedItem = (selectedFam) => {
        if (!selectedFam.PZN) {
          error.value = "Unbekannte Auswahl!";
          return;
        }

        error.value = "";
        refPacSearch.value.reset();

        if (selectedFam.Name.length === 0) {
          return;
        }

        const medication = useForm({
          abdata_key_fam: null,
          fam: null,
          tradingstatus: null,
          usage: null,
          manufacturer: null,
          row: null,
          indications: null,
          selected_package_pzn: null,
          selected_package: null,
          form: null,
          substances: {},
          dosage: {
            unitList: {},
            selected_unit: null,
            selected_unit_key_doe: null,
            morning: null,
            noon: null,
            evening: null,
            night: null,
            dosageInfo: null,
          },
        });

        medication.abdata_key_fam = selectedFam.Key_FAM || null;
        medication.fam = selectedFam.Name;
        medication.tradingstatus = selectedFam.Vertriebsstatus;
        medication.usage = selectedFam.usage;
        medication.manufacturer = selectedFam.Anbieter;
        medication.form = selectedFam.Key_DAR;
        medication.selected_package_pzn = selectedFam.PZN;
        medication.selected_package = selectedFam.Packung;
        medication.substances = selectedFam.arws.map(function (arw) {
          return {
            abdata_key_sto: null,
            name: arw.Wirkstoff,
            strength: arw.Staerke,
          };
        });

        medication.dosage.unitList = (selectedFam.does || []).map(function (doe) {
          return {keyDoe: doe.Key_DOE, unit: doe.Ausdruck};
        });

        medication.dosage.selected_unit =
          medication.dosage.unitList.length === 1 ? medication.dosage.unitList[0].unit : null;

        medication.dosage.selected_unit_key_doe =
          medication.dosage.unitList.length === 1 ? medication.dosage.unitList[0].keyDoe : null;

        medication.post(route("medications.store", {patient: page.props.patient.id, record: page.props.record.id}), {
          preserveScroll: true,
        });
      };

      const isValidSearchTerm = (searchTerm) => {
        return (
          typeof searchTerm !== "undefined" && searchTerm !== null && searchTerm.length >= minSearchTermLength.value
        );
      };

      const setFocus = () => {
        refPacSearch.value.focus();
      };

      return {
        /** ref */
        refPacSearch,

        /** const */
        error,
        minSearchTermLength,
        showAnalysable,
        showNonAnalysable,

        /** computed */
        medicationList,

        /** function */
        setFocus,
        searchPac,
        filterPac,
        updateRowWithSelectedItem,
        isValidSearchTerm,
      };
    },
  };
</script>
