<template>
  <div>
    <a
      :id="urlRedirect.identifier"
      target="_blank"
      :href="urlRedirect.target_url"
      :data-test="testId + '-url-redirect'"
    >
      {{ urlRedirect.link_text ?? urlRedirect.target_url }}
    </a>

    <template v-if="urlRedirect.file_type && urlRedirect.file_type !== ''">, {{ urlRedirect.file_type }}</template>

    <template v-if="urlRedirect.status && urlRedirect.status !== ''">, {{ urlRedirect.status }}</template>

    <div
      v-if="urlRedirect.description && urlRedirect.description !== ''"
      class="max-w-3xl"
      :data-test="testId + '-url-redirect-description'"
    >
      {{ urlRedirect.description }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "DocumentComponentUrlRedirect",

    props: {
      urlRedirect: {
        type: Object,
        required: true,
      },
      targetUrl: {
        type: String,
        default: "",
      },
      linkText: {
        type: String,
        default: "",
      },
      fileType: {
        type: String,
        default: "",
      },
      status: {
        type: String,
        default: "",
      },
    },
  };
</script>
