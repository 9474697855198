<template>
  <div class="flex items-center px-2 pb-4 space-x-2">
    <!-- Headline is editable -->
    <template v-if="isEditable">
      <div class="flex items-center space-x-1">
        <h5 class="text-2xl">Probleme / Symptome</h5>
        <component-info-icon placement="top">
          <template #content>
            <h5 class="font-semibold text-center">Typische (häufige) Probleme / Symptome (z. B.)</h5>

            <p class="mt-1 p-1">
              - Schmerzen (wie stark)?
              <br />
              - Magenbeschwerden, Sodbrennen?
              <br />
              - Trockener Mund?
              <br />
              - Abgeschlagenheit, Müdigkeit?
              <br />
              - Konzentrationsschwäche, kognitive Störungen, Vigilanz?
              <br />
              - Atemnot, Atemgeräusche?
              <br />
              - Schlafstörung?
              <br />
              - Schwindel, Gangunsicherheit, Stürze?
              <br />
              - Blutungen (Nasenbluten, Zahnfleischbluten, schwarzer Stuhl,...)?
              <br />
              - Obstipation, Diarrhoe, Inkontinenz?
              <br />
              - Appetitlosigkeit, Übelkeit?
              <br />
              - Hautausschläge, Juckreiz?
              <br />
              - Depression, Stimmungsänderung?
              <br />
              - Verwirrtheit, Halluzination?
              <br />
              - Angst, Unruhe?
              <br />
              - Ohrgeräusche?
              <br />
              - Tremor, Zittern?
            </p>
          </template>
        </component-info-icon>
      </div>
    </template>

    <!-- Headline is not editable -->
    <template v-else>
      <h5 class="text-2xl">Probleme / Symptome</h5>
      <component-button class="p4umc-primary" label="Bearbeiten" @click="changeIsEditable" />
    </template>
  </div>

  <!-- Alert for not loadable symptoms -->
  <component-alert
    v-if="
      (unloadableSymptomsUnsolved && unloadableSymptomsUnsolved.length > 0) ||
      (unloadableSymptomsSolved && unloadableSymptomsSolved.length > 0)
    "
    type="warning"
  >
    <h4 class="font-bold">
      In diesem Bereich befinden sich Symptome, die in der aktualisierten Datenbank ersetzt (oder geschlechtsspezifisch
      zugeordnet) wurden.
    </h4>

    <p class="mt-2">
      Bitte tauschen Sie folgende Symptome durch geeignete Neue aus, damit sie im Check erkannt und berücksichtigt
      werden:
    </p>
    <ul class="mt-2 ml-8 list-disc">
      <template v-if="unloadableSymptomsUnsolved?.length > 0">
        <li v-for="(item, index) in unloadableSymptomsUnsolved" :key="'unsolved-' + index">
          <em>{{ item.symptom }}</em>
        </li>
      </template>
      <template v-if="unloadableSymptomsSolved?.length > 0">
        <!-- prettier-ignore -->
        <li v-for="(item, index) in unloadableSymptomsSolved" :key="'solved-' + index">
          <em>{{ item.unloadable.old.symptom }}</em>
          (kann ggf. ersetzt werden durch <em>{{ item.unloadable.new.symptom }}</em>)
        </li>
      </template>
    </ul>
  </component-alert>

  <!-- Table -->
  <template v-if="recordData.symptoms.length > 0">
    <symptom-table :record-data="recordData" :is-editable="isEditable" :is-loading="isLoading" />
  </template>

  <!-- MinC Search -->
  <div v-if="isEditable" class="flex mt-5">
    <component-icon
      class="text-mcred text-2xl cursor-pointer mt-1.5"
      :class="{'!text-gray-400 !pointer-events-none': isMassUpdateProcessing}"
      @click="focusSearch"
    >
      add
    </component-icon>

    <record-component-min-c-search
      ref="refSearch"
      placeholder="Problem / Symptom eingeben, z. B. Übelkeit"
      class="ml-1 w-96"
      filter="symptoms"
      :reset-on-select="true"
      :filter-list="true"
      :disabled="isMassUpdateProcessing"
      @input="addSymptom"
    />
  </div>

  <!-- Textarea -->
  <div class="w-full grid grid-cols-3 lg:grid-cols-9 lg:grid-rows-1 pb-6 mt-5">
    <div class="col-span-3 lg:col-span-8">
      <div>
        <component-textarea
          ref="patientinfo"
          v-model="infoText"
          label="Subjektive Eindrücke, weitere objektive Daten, Wünsche und Therapieziele"
          :helper-text="helperText"
          :encrypted="true"
          :disabled="!isEditable"
          @blur="setPatientInfo"
        />
      </div>
    </div>
  </div>

  <!-- Dialogs -->
  <symptom-context-dialog ref="refSymptomContextDialog" />
</template>

<script>
  import {computed, inject, provide, ref} from "vue";
  import {router, usePage} from "@inertiajs/vue3";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  import RecordComponentMinCSearch from "@pages/Records/Components/MinCSearch.vue";
  import SymptomTable from "@pages/Records/Components/Sections/SymptomBar/Components/Table.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import SymptomContextDialog from "@pages/Records/Components/Sections/SymptomBar/Components/ContextDialog.vue";

  export default {
    name: "RecordComponentSectionSymptomBar",

    components: {
      SymptomContextDialog,
      ComponentInfoIcon,
      ComponentAlert,
      ComponentButton,
      ComponentIcon,
      ComponentTextarea,
      RecordComponentMinCSearch,
      SymptomTable,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      changeIsEditable: {
        type: Function,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      unloadableSymptomsUnsolved: {
        type: Object,
        required: true,
      },
      unloadableSymptomsSolved: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup(props) {
      const page = usePage();

      const getIsMassUpdateProcessing = inject("getIsMassUpdateProcessing");

      const refSearch = ref(null);
      const refSymptomContextDialog = ref(null);

      const infoText = ref(props.recordData.info);
      const helperText = 'Hinweis: <span class="underline"> Keine personenbezogenen</span> Daten eingeben.';

      const isMassUpdateProcessing = computed(() => getIsMassUpdateProcessing("symptom"));

      const setPatientInfo = () => {
        router.patch(
          route("records.update", {
            patient: page.props.patient.id,
            record: props.recordData.id,
          }),
          {
            info: infoText.value,
          },
          {
            preserveScroll: true,
          },
        );
      };

      const addSymptom = (symptom) => {
        router.post(
          route("symptoms.store", {
            patient: page.props.patient.id,
            record: props.recordData.id,
          }),
          {
            abdata_key_miv: symptom.Key_MIV,
            symptom: symptom.Name,
          },
          {preserveScroll: true},
        );
      };

      const focusSearch = () => {
        if (refSearch.value) {
          refSearch.value.focus();
        }
      };

      const openDialog = (dialogName, data) => {
        switch (dialogName) {
          case "refSymptomContextDialog":
            refSymptomContextDialog.value.open(data);
            break;
        }
      };

      provide("symptomOpenDialog", openDialog);

      return {
        /** ref */
        refSearch,
        refSymptomContextDialog,

        /** const */
        infoText,
        helperText,

        /** computed */
        isMassUpdateProcessing,

        /** function */
        setPatientInfo,
        addSymptom,
        focusSearch,
        openDialog,
      };
    },
  };
</script>
