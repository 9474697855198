<template>
  <div class="sticky top-24 z-40 print:hidden">
    <div
      class="absolute shadow-lg border-x-1 border-gray-100 bg-white max-w-sm z-20 -right-16 ml-auto w-fit -mr-4 px-2 py-4"
    >
      <div :class="{closedCard: !open}">
        <div class="flex items-center space-x-2">
          <component-icon-button
            v-if="!open"
            class="p4umc-flat bg-transparent hover:bg-transparent"
            icon="chevron_left"
            data-test="resultFilter-iconButton-openFilter"
            @click="toggleFilter()"
          />
          <component-icon-button
            v-else
            class="p4umc-flat bg-transparent hover:bg-transparent"
            icon="chevron_right"
            data-test="resultFilter-iconButton-closeFilter"
            @click="toggleFilter()"
          />
          <span v-show="open" class="text-xl font-semibold">Ergebnisse filtern</span>
        </div>

        <div v-if="hasThirdParty('p4u')" class="mb-6 mt-2 pl-3 flex justify-between">
          <ul v-show="open" class="w-64">
            <li class="items-start">
              <component-select
                v-model="resultConfig.analysistype"
                class="w-full max-w-sm"
                data-test="resultFilter-select-analysistype"
              >
                <option value="1">Typ 1 (nur Medikation)</option>
                <option value="2a">Typ 2a (Medikation u. Patientengespräch)</option>
                <option value="2b">Typ 2b (Medikation u. Indikationen / Laborwerte)</option>
                <option value="3">Typ 3 (alles)</option>
              </component-select>
            </li>
          </ul>
          <component-info-icon v-show="open" class="pt-2">
            <template #content>
              <p class="max-w-sm">
                Hier können Sie den Typ der Analyse wählen, der auf Basis der eingegebenen Parameter die
                Datenbankrecherche (Analyse) beeinflusst.
                <br />
                <br />
                Typ 1: nur Medikation (inkl. Dosierung)
                <br />
                Typ 2a: Medikation inkl. Dosierung + Patienten-Information (z. B. „Brown-Bag Gespräch“)
                <br />
                Typ 2b: Medikation inkl. Dosierung und klinische Daten (Laborwerte / Erkrankungen)
                <br />
                Typ 3: alles
              </p>
            </template>
          </component-info-icon>
        </div>

        <div class="divide-y-2">
          <ul class="ml-2 mt-4 space-y-3">
            <li>
              <component-tooltip tooltip="Risiken: sehr hoch" placement="left" :force-hidden="open">
                <component-checkbox
                  v-model="resultConfig.veryhighrisk"
                  class="w-full"
                  color="red"
                  :disabled="resultConfig.showCommunicationDoc || resultConfig.showCommunicationPatient"
                  data-test="resultFilter-select-veryhighrisk"
                >
                  <span v-show="open">
                    Risiken: sehr hoch
                    <span class="text-red-600">(rot)</span>
                  </span>
                </component-checkbox>
              </component-tooltip>
            </li>

            <li>
              <component-tooltip tooltip="Risiken: mittel - hoch" placement="left" :force-hidden="open">
                <component-checkbox
                  v-model="resultConfig.highrisk"
                  class="w-full"
                  color="orange"
                  :disabled="resultConfig.showCommunicationDoc || resultConfig.showCommunicationPatient"
                  data-test="resultFilter-checkbox-highrisk"
                >
                  <span v-show="open">
                    Risiken: mittel &ndash; hoch
                    <span class="text-orange-500">(orange)</span>
                  </span>
                </component-checkbox>
              </component-tooltip>
            </li>

            <li>
              <component-tooltip tooltip="Risiken: gering - mittel" placement="left" :force-hidden="open">
                <component-checkbox
                  v-model="resultConfig.midrisk"
                  class="w-full"
                  color="yellow"
                  :disabled="resultConfig.showCommunicationDoc || resultConfig.showCommunicationPatient"
                  data-test="resultFilter-checkbox-midhrisk"
                >
                  <span v-show="open">
                    Risiken: gering &ndash; mittel
                    <span class="text-yellow-500">(gelb)</span>
                  </span>
                </component-checkbox>
              </component-tooltip>
            </li>

            <li>
              <component-tooltip tooltip="Risiken: sonstige" placement="left" :force-hidden="open">
                <component-checkbox
                  v-model="resultConfig.lowrisk"
                  class="w-full"
                  color="gray"
                  :disabled="resultConfig.showCommunicationDoc || resultConfig.showCommunicationPatient"
                  data-test="resultFilter-checkbox-lowrisk"
                >
                  <span v-show="open">
                    Risiken: sonstige
                    <span class="text-gray-500">(grau)</span>
                  </span>
                </component-checkbox>
              </component-tooltip>
            </li>

            <li>
              <component-tooltip tooltip="Adhärenz und Prävention" placement="left" :force-hidden="open">
                <component-checkbox
                  v-model="resultConfig.showNoncomplianceAndPrevention"
                  class="w-full"
                  :disabled="resultConfig.showCommunicationDoc || resultConfig.showCommunicationPatient"
                  data-test="resultFilter-checkbox-showNoncomplianceAndPrevention"
                >
                  <span v-show="open">Adhärenz und Prävention</span>
                </component-checkbox>
              </component-tooltip>
            </li>

            <li v-show="!open">
              <component-tooltip tooltip="weitere Filter" placement="left">
                <component-icon class="text-xl" :clickable="true" @click="toggleFilter()">more_horiz</component-icon>
              </component-tooltip>
            </li>
          </ul>

          <ul v-show="open" class="ml-2 mt-4 pt-4 space-y-3">
            <li>
              <component-checkbox
                v-model="resultConfig.showCommunicationDoc"
                class="w-full"
                :disabled="sumCommunicationDoc === 0"
                data-test="resultFilter-checkbox-showCommunicationDoc"
              >
                <span>an Arzt (ausgewählt: {{ sumCommunicationDoc }})</span>
              </component-checkbox>
            </li>

            <li>
              <component-checkbox
                v-model="resultConfig.showCommunicationPatient"
                class="w-full"
                :disabled="sumCommunicationPatient === 0"
                data-test="resultFilter-checkbox-showCommunicationPatient"
              >
                <span>an Patient (ausgewählt: {{ sumCommunicationPatient }})</span>
              </component-checkbox>
            </li>
          </ul>

          <ul v-show="open" class="ml-2 mt-4 pt-4 space-y-3">
            <li>
              <component-tooltip
                :tooltip="
                  resultConfig.defaultSetting ? 'Einstellung entspricht dem Standard' : 'Auf Standard zurücksetzen'
                "
                placement="left"
              >
                <component-checkbox
                  v-model="resultConfig.defaultSetting"
                  class="w-full"
                  :disabled="resultConfig.defaultSetting"
                  data-test="resultFilter-checkbox-defaultSetting"
                  @change="(value) => resetDefaults(value)"
                >
                  Standard
                </component-checkbox>
              </component-tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {inject, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PagesRecordResultListFilter",
    components: {
      ComponentIcon,
      ComponentCheckbox,
      ComponentTooltip,
      ComponentInfoIcon,
      ComponentSelect,
      ComponentIconButton,
    },

    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");

      const open = ref(false);

      const resultConfig = inject("resultConfig");
      const sumCommunicationDoc = inject("sumCommunicationDoc");
      const sumCommunicationPatient = inject("sumCommunicationPatient");
      const resetDefaultResultConfig = inject("resetDefaultResultConfig");

      function toggleFilter() {
        open.value = !open.value;
      }

      function resetDefaults(value) {
        if (value === true) {
          resetDefaultResultConfig();
        }
      }

      return {
        resultConfig,
        hasThirdParty,
        toggleFilter,
        open,
        sumCommunicationDoc,
        sumCommunicationPatient,
        resetDefaults,
      };
    },
  };
</script>
